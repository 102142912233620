:root {
  --allports: #006db2;
  --black: #000000;
  --black-2: #000000bf;
  --cerulean: #009ade1a;
  --downy: #74c9c0;
  --granny-apple: #d6eae5;
  --gray: #919191;
  --gray-nurse: #e8e8e8;
  --magic-potion: #001343;
  --neptune: #72bcb5;
  --observatory: #008375;
  --opal: #a5cbc1;
  --quick-silver: #a5a5a5;
  --quill-gray: #d6d6d6;
  --sherpa-blue: #004e59;
  --silver: #bcbcbc;
  --silver-chalice: #afafaf;
  --silver-chalice-2: #acacac;
  --sonic-silver: #717171;
  --star-dust: #9d9d9d;
  --star-dust-2: #a0a0a0;
  --tropical-rain-forest: #00796027;
  --violet-eggplant: #901db4;
  --white: #ffffff;
  --x11-2: #f5ea61;
  --x13-2: #f4a81d;
  --x15-2: #ff6b00;
  --x17-2: #ffb4ab;
  --x19-2: #c39b82;
  --x21-2: #ff647d;
  --x23-2: #db0032;
  --x25-2: #fca0c9;
  --x27-2: #df1683;
  --x29-2: #e95ebe;
  --x31-2: #a05cbf;
  --x33-2: #c2a6e1;
  --x35-2: #655dc6;
  --x37-2: #8cb7c9;
  --x39-2: #3d7cca;
  --x41-2: #0047ba;
  --x43-2: #009ade;
  --x45-2: #0099a8;
  --x47-2: #007960;
  --x49-2: #00ae42;
  --x51-2: #93d500;
  --x53-2: #fcd199;

  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 24px;
  --font-size-xxl: 30px;
  --font-size-xxxl: 36px;
  --font-size-xxxxl: 48px;

  --font-family-arial-regular: "Arial-Regular", Helvetica;
  --font-family-garamondpremier_pro-semibold: "GaramondPremier Pro-Semibold", Helvetica;
  --font-family-proxima_nova: "Proxima Nova", Helvetica;
  --font-family-proximanova-black: "ProximaNova-Black", Helvetica;
  --font-family-proximanova-bold: "ProximaNova-Bold", Helvetica;
  --font-family-proximanova-italic: "ProximaNova-Italic", Helvetica;
  --font-family-proximanova-light: "ProximaNova-Light", Helvetica;
  --font-family-proximanova-medium: "ProximaNova-Medium", Helvetica;
  --font-family-proximanova-regular: "ProximaNova-Regular", Helvetica;
  --font-family-proximanova-semibold: "ProximaNova-Semibold", Helvetica;
}
.proximanova-medium-observatory-18px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}
.proximanova-medium-sherpa-blue-20px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-medium);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-medium-steel-blue-20px {
  color: var(--x39-2);
  font-family: var(--font-family-proximanova-medium);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-black-14px {
  color: var(--black);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-medium-silver-chalice-24px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-black-black-16px {
  color: var(--black);
  font-family: var(--font-family-proximanova-black);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 900;
}
.proximanova-medium-silver-chalice-20px {
  color: var(--silver-chalice-2);
  font-family: var(--font-family-proximanova-medium);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-white-15px {
  color: var(--white);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-sherpa-blue-48px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-black-sherpa-blue-48px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxxxl);
}
.proximanova-black-sherpa-blue-48px-2 {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-black);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 900;
}
.proximanova-regular-normal-star-dust-14px {
  color: var(--star-dust-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-medium-white-24px {
  color: var(--white);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-white-12px {
  color: var(--white);
  font-family: var(--font-family-proximanova-bold);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.garamondpremierpro-semi-bold-sherpa-blue-60px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-garamondpremier_pro-semibold);
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
}
.proximanova-bold-silver-chalice-16px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-silver-chalice-16px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-sherpa-blue-24px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-observatory-30px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-observatory-30px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-silver-chalice-14px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-sherpa-blue-36px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-silver-chalice-19px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-bold);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-silver-chalice-22px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-silver-200px {
  color: var(--silver);
  font-family: var(--font-family-proximanova-bold);
  font-size: 200px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-regular-normal-quicksand-14px {
  color: var(--x19-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-carnation-pink-14px {
  color: var(--x25-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.arial-regular-normal-white-10px {
  color: var(--white);
  font-family: var(--font-family-arial-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-observatory-29px {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: 29px;
}
.proximanova-medium-observatory-29px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-observatory-29px-2 {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-bold);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-white-48px {
  color: var(--white);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-magic-potion-30px {
  color: var(--magic-potion);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-regular-normal-sonic-silver-36px {
  color: var(--sonic-silver);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}
.proximanova-medium-allports-16px {
  color: var(--allports);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-sherpa-blue-36px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-blue-violet-21px {
  color: var(--x35-2);
  font-family: var(--font-family-proximanova-medium);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-medium-silver-chalice-36px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-observatory-30px-2 {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxl);
}
.proximanova-normal-observatory-30px {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxl);
}
.proximanova-regular-normal-observatory-18px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-observatory-32px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.garamondpremierpro-semi-bold-magic-potion-60px {
  color: var(--magic-potion);
  font-family: var(--font-family-garamondpremier_pro-semibold);
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
}
.proximanova-medium-violet-eggplant-24px {
  color: var(--violet-eggplant);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-magic-potion-19px {
  color: var(--magic-potion);
  font-family: var(--font-family-proximanova-bold);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-white-65px {
  color: var(--white);
  font-family: var(--font-family-proximanova-bold);
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-silver-chalice-18px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}
.proximanova-medium-black-16px {
  color: var(--black);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-brink-pink-14px {
  color: var(--x21-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-observatory-31px {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: 31px;
}
.proximanova-medium-observatory-31px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-observatory-31px-2 {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-bold);
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-normal-observatory-32px {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: 32px;
}
.proximanova-medium-observatory-32px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-observatory-19px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-regular);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-normal-sherpa-blue-36px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxxl);
}
.proximanova-light-white-36px {
  color: var(--white);
  font-family: var(--font-family-proximanova-light);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 300;
}
.proximanova-normal-star-dust-16px {
  color: var(--star-dust-2);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-m);
}
.proximanova-medium-silver-chalice-30px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxl);
}
.proximanova-regular-normal-portica-15px {
  color: var(--x11-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-wisteria-15px {
  color: var(--x33-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-bright-yellow-15px {
  color: var(--x13-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-blue-violet-15px {
  color: var(--x35-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-blaze-orange-15px {
  color: var(--x15-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-polo-blue-15px {
  color: var(--x37-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-cornflower-lilac-15px {
  color: var(--x17-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-steel-blue-15px {
  color: var(--x39-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-quicksand-15px {
  color: var(--x19-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-absolute-zero-15px {
  color: var(--x41-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-brink-pink-15px {
  color: var(--x21-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-cerulean-15px {
  color: var(--x43-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-crimson-15px {
  color: var(--x23-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-bondi-blue-15px {
  color: var(--x45-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-carnation-pink-15px {
  color: var(--x25-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-tropical-rain-forest-15px {
  color: var(--x47-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-red-violet-15px {
  color: var(--x27-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-jade-15px {
  color: var(--x49-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-magenta-15px {
  color: var(--x29-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-sheen-green-15px {
  color: var(--x51-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-purple-plum-15px {
  color: var(--x31-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-peach-orange-15px {
  color: var(--x53-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-medium-silver-chalice-22px-2 {
  color: var(--silver-chalice);
  font-family: var(--font-family-proxima_nova);
  font-size: 22px;
}
.proximanova-bold-sherpa-blue-48px-2 {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxxxl);
}
.proximanova-regular-normal-sherpa-blue-48px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}
.border-1px-x39-2 {
  border: 1px solid var(--x39-2);
}
