@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  /* height: 100vh; */
  
  
}
.homeFrontPage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  padding: 0 0;
  max-width: 1200px;
}
@media (min-width: 1007px) and (max-width: 1025px) {
  .homeFrontPage {
    padding: 0px 0%;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .homeFrontPage {
    padding: 0px 5%;
  }
}
.home {
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  padding-top: 9%;
  margin-bottom: 100px;
}
@media (min-width: 1007px) and (max-width: 1025px) {
  .home {
    padding-top: 3%;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .home {
    flex-flow: column;
  }
}
@media (max-width: 640px) {
  .home {
    flex-flow: column;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 0px;
  }
}
.home__text {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 5;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .home__text {
    width: 100%;
  }
}
.home__title {
  color: #004E59;
  font-size: 43px;
  line-height: 1.2em;
  font-weight: 600;
  font-family: garamond-premier-pro, serif;
  margin-bottom: 10px;
  padding-left: 120px;
}
@media (min-width: 1007px) and (max-width: 1025px) {
  .home__title {
    font-size: 46px;
    padding-left: 60px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .home__title {
    font-size: 46px;
    padding-left: 0px;
  }
}
@media (max-width: 640px) {
  .home__title {
    font-size: 32px;
    padding-left: 0px;
  }
}
.home__subtitle {
  color: #999999;
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 120px;
}
@media (min-width: 1007px) and (max-width: 1025px) {
  .home__subtitle {
    padding-left: 60px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .home__subtitle {
    font-size: 20px;
    padding-left: 0px;
  }
}
@media (max-width: 640px) {
  .home__subtitle {
    font-size: 16px;
    padding-left: 0px;
  }
}
.home__button {
  color: #008375;
  background-color: transparent;
  border: transparent;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 24px;
  text-decoration: none;
  text-align: left;
  padding-left: 120px;
}
.home__button:hover {
  color: #901db4;
}
@media (min-width: 1007px) and (max-width: 1025px) {
  .home__button {
    padding-left: 60px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .home__button {
    font-size: 24px;
    padding-left: 0px;
  }
}
@media (max-width: 640px) {
  .home__button {
    font-size: 20px;
    padding-left: 0px;
  }
}
.home__image-container {
  margin-left: -1%;
  z-index: 1;
  align-self: center;
}
.home__image {
  width: 100%;
}
@media (min-width: 1007px) and (max-width: 1025px) {
  .home__image {
    margin-top: -20px;
    margin-left: -50px;
  }
}

.homeUnverified {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 1em;
  gap: 1em;
  max-width: 600px;
  margin: auto;
  text-align: left;
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  color: #004e59;
  margin-top: 10em;
  padding: 0% 10%;
}
.homeUnverified__body {
  width: 100%;
  color: gray;
}
.homeUnverified__body b {
  font-weight: 700;
}
.homeUnverified__bodyButton {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.homeUnverified__button {
  border: none;
  border-radius: 0.25em;
  color: white;
  background-color: #008375;
  padding: 0.5em 1em;
  margin: 0;
}
.homeUnverified__image {
  width: 100px;
  align-self: center;
}/*# sourceMappingURL=HomePage.css.map */
.homeBottom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 5em;
}
@media (max-width: 640px) {
  .homeBottom {
    margin-bottom: 0em;
  }
}
.homeBottom__pic {
  width: 1007px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .homeBottom__pic {
    width: 90%;
  }
}
@media (max-width: 640px) {
  .homeBottom__pic {
    width: 100%;
  }
}
.homeBottom__button {
  border: none;
  background-color: transparent;
  position: absolute;
  border-radius: 3px;
}
.homeBottom__button:hover {
  background-color: #92c9bc;
  opacity: 25%;
}
.homeBottom__button-button1 {
  top: 43.9%;
  right: calc(50% - 204px);
  height: 43px;
  width: 204px;
  transform: translate(-50%, -50%);
}
@media (min-width: 640px) and (max-width: 1007px) {
  .homeBottom__button-button1 {
    top: 43.89%;
    right: 31.8%;
    height: 2.1%;
    width: 18.2%;
    border-radius: 1px;
  }
}
@media (max-width: 640px) {
  .homeBottom__button-button1 {
    top: 36.45%;
    right: 29.8%;
    height: 1.8%;
    width: 20.25%;
  }
}
.homeBottom__button-button2 {
  top: 74.6%;
  right: calc(50% - 457px);
  height: 43px;
  width: 203px;
  transform: translate(-50%, -50%);
}
@media (min-width: 640px) and (max-width: 1007px) {
  .homeBottom__button-button2 {
    top: 74.55%;
    right: 9.1%;
    height: 2.1%;
    width: 18.2%;
    border-radius: 1px;
  }
}
@media (max-width: 640px) {
  .homeBottom__button-button2 {
    top: 61.9%;
    right: 4.5%;
    height: 1.75%;
    width: 20.3%;
  }
}
.homeBottom__button-button3 {
  top: 99%;
  right: calc(50% - 199px);
  height: 43px;
  width: 204px;
  transform: translate(-50%, -50%);
}
@media (min-width: 640px) and (max-width: 1007px) {
  .homeBottom__button-button3 {
    top: 98.95%;
    right: 32.35%;
    height: 2.11%;
    width: 18.1%;
    border-radius: 1px;
  }
}
@media (max-width: 640px) {
  .homeBottom__button-button3 {
    top: 97.8%;
    right: 29.75%;
    height: 1.8%;
    width: 20.25%;
  }
}
.homeBottom__greenBar {
  background-color: #004e59;
  border: none;
  position: absolute;
  z-index: -100;
  width: 100vw;
}
.homeBottom__greenBar-bar1 {
  top: 0%;
  height: 517px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .homeBottom__greenBar-bar1 {
    height: 25.1%;
  }
}
@media (max-width: 640px) {
  .homeBottom__greenBar-bar1 {
    height: 21%;
  }
}
.homeBottom__greenBar-bar2 {
  top: 59.65%;
  height: 398px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .homeBottom__greenBar-bar2 {
    height: 19.3%;
  }
}
@media (max-width: 640px) {
  .homeBottom__greenBar-bar2 {
    top: 49.5%;
    height: 16.1%;
  }
}/*# sourceMappingURL=HomeBottom.css.map */
.spinner_box {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.spinner {
  animation: spin infinite 2s linear;
  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
  font-size: 2em;
  color: #004E59;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadingPage__error {
  width: 60%;
  text-align: center;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: black;
}
@media (max-width: 640px) {
  .loadingPage__error {
    font-size: 0.75rem;
  }
}
.loadingPage__error-light {
  color: white;
}/*# sourceMappingURL=LoadingPage.css.map */
.purchased {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.purchased__main {
  width: 75%;
  max-width: 1000px;
  margin-top: 30px;
  margin-bottom: 150px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__main {
    width: 85%;
    margin-top: 50px;
  }
}
@media (max-width: 640px) {
  .purchased__main {
    width: 90%;
    margin-top: 0px;
  }
}
.purchased__header {
  color: #004e59;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 44px;
  text-align: left;
  align-self: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__header {
    font-size: 36px;
  }
}
@media (max-width: 640px) {
  .purchased__header {
    font-size: 24px;
  }
}
.purchased__subheader {
  color: #999999;
  font-family: proxima-nova, sans-serif;
  margin-top: 8px;
  text-align: left;
  align-self: flex-start;
  font-size: 17.5px;
  line-height: 1.4rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__subheader {
    font-size: 16px;
    line-height: 1.3rem;
  }
}
@media (max-width: 640px) {
  .purchased__subheader {
    font-size: 12px;
    line-height: 1rem;
  }
}
.purchased__subheader span {
  font-weight: 700;
}
.purchased__box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  width: 100%;
}
.purchased__overlay {
  position: absolute;
  width: 100%;
  height: 125px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: rgba(151, 188, 190, 0.9);
}
.purchased__overlayText {
  font-family: proxima-nova, sans-serif;
  font-family: garamond-premier-pro, serif;
  color: white;
  font-weight: 600;
  font-style: italic;
  font-size: 50px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__overlayText {
    font-size: 36px;
  }
}
@media (max-width: 640px) {
  .purchased__overlayText {
    font-size: 28px;
  }
}
.purchased__retake {
  font-family: garamond-premier-pro, serif;
  color: rgb(0, 78, 88);
  font-size: 0.4em;
  font-weight: 700;
  font-style: italic;
  background: none;
  border: none;
  text-decoration: underline;
  margin-top: 0.25em;
}
.purchased__retake:hover {
  color: #008375;
}
.purchased__navlink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #D6EAE5;
  transition: all 0.1s ease-in-out;
  margin-bottom: 2px;
  text-decoration: none;
}
.purchased__navlink:hover {
  z-index: 10;
  text-decoration: none;
  transform: scale(1.05) perspective(0px);
  box-shadow: 0 10px 10px 0px #7BB6A9;
}
.purchased__button {
  background-color: #D6EAE5;
  border-style: none;
  width: 100%;
  height: 125px;
  margin-top: 1px;
  margin-bottom: 1px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__button {
    padding-left: 20px;
  }
}
@media (max-width: 640px) {
  .purchased__button {
    padding-left: 10px;
  }
}
.purchased__image {
  margin-right: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__image {
    padding-right: 10px;
  }
}
@media (max-width: 640px) {
  .purchased__image {
    padding-right: 0px;
  }
}
.purchased__imageSelf {
  width: 110px;
  align-self: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__imageSelf {
    width: 100px;
  }
}
@media (max-width: 640px) {
  .purchased__imageSelf {
    width: 65px;
  }
}
.purchased__words {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
}
.purchased__title {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 5px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__title {
    font-size: 28px;
  }
}
@media (max-width: 640px) {
  .purchased__title {
    font-size: 16px;
  }
}
.purchased__title span {
  font-family: Depot New, sans-serif;
  height: 0px;
  display: inline-block;
  transform: scale(0.5, 1.1);
}
.purchased__times {
  display: flex;
  flex-flow: row wrap;
}
.purchased__buttonSubtitle {
  color: #008375;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 24px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__buttonSubtitle {
    font-size: 22px;
  }
}
@media (max-width: 640px) {
  .purchased__buttonSubtitle {
    font-size: 14px;
  }
}
.purchased__time {
  color: #008375;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 24px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__time {
    font-size: 22px;
  }
}
@media (max-width: 640px) {
  .purchased__time {
    font-size: 14px;
  }
}
.purchased__buttonSubSubtitle {
  color: #008375;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .purchased__buttonSubSubtitle {
    font-size: 14px;
  }
}
@media (max-width: 640px) {
  .purchased__buttonSubSubtitle {
    font-size: 12px;
  }
}
.purchased__inline {
  color: #999999;
  text-decoration: underline;
}

.purchased-assessments-popup {
  background-color: #008375;
  width: calc(230px + 30vmin);
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: calc(1px + 1.5vmin);
  padding-top: calc(0px + 0vmin);
  padding-bottom: calc(10px + 1.5vmin);
}

.purchased-assessments-popup-close {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-size: calc(14px + 1.5vmin);
  color: #ffffff;
  border-style: none;
  align-self: flex-end;
  margin-right: -5px;
}

.purchased-assessments-popup-primary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: calc(20px + 2vmin);
  font-weight: 700;
  text-align: center;
  padding-bottom: calc(5px + 1vmin);
}

.purchased-assessments-popup-sub {
  font-family: proxima-nova, sans-serif;
  color: #9cccc6;
  text-align: left;
  font-size: calc(14px + 1.5vmin);
  line-height: calc(15px + 1.5vmin);
  font-weight: 200;
  letter-spacing: calc(0.25px + 0.01vmin);
}

.retake-text_yes_container {
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 400;
}

.retake-text_yes {
  border: 1px solid rgb(140, 199, 193);
  border-radius: 0.2em;
  padding: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: #8cc7c1;
  font-weight: 500;
}

.retake-text_yes span {
  display: inline-block; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transform: scale(0.5, 1.5); /* W3C */
  font-weight: 700;
}

.retake-text_yes:hover {
  background-color: #ffffff;
  border: 1px solid rgb(255, 255, 255);
  color: #008375;
  text-decoration: none;
}

.retake-text_no_container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.75em;
}

.retake-text_no {
  color: white;
  text-decoration: underline;
  background: none;
  border: none;
}

.retake-text_no:hover {
  color: rgb(255, 255, 255);
  font-weight: 700;
}/*# sourceMappingURL=PurchasedAssessments.css.map */
.main-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .main-box {
    padding-top: 5em;
  }
}
@media (max-width: 640px) {
  .main-box {
    padding-top: 0em;
  }
}

.signedin-assessments-popup {
  background-color: #008375;
  width: calc(180px + 30vmin);
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: calc(10px + 1.5vmin);
  padding-top: calc(2px + 1.5vmin);
}

.signedin-assessments-popup-primary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: calc(16px + 2vmin);
  font-weight: 700;
  text-align: center;
  padding-bottom: calc(5px + 1vmin);
}

.signedin-assessments-popup-sub {
  font-family: proxima-nova, sans-serif;
  color: #74C9C0;
  text-align: center;
  font-size: calc(6px + 1.5vmin);
  font-weight: 200;
  letter-spacing: calc(0.25px + 0.01vmin);
}

.signedin-assessments-popup-close {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-size: calc(12px + 1.5vmin);
  color: #74C9C0;
  border-style: none;
  align-self: flex-end;
  margin-right: -5px;
}

.full-popup {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.7490196078);
}

.signedinAssessments {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 10px;
  min-width: 925px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedinAssessments {
    min-width: 600px;
  }
}
@media (max-width: 640px) {
  .signedinAssessments {
    flex-flow: column nowrap;
    min-width: auto;
  }
}
.signedinAssessments__image {
  max-width: 700px;
  margin-bottom: 10em;
  margin-left: -2em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedinAssessments__image {
    width: 500px;
    margin-bottom: 0em;
  }
}
@media (max-width: 640px) {
  .signedinAssessments__image {
    width: 350px;
    margin-bottom: 0em;
  }
}
.signedinAssessments__image2 {
  width: 300px;
  align-self: center;
  padding-bottom: 1em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedinAssessments__image2 {
    width: 250px;
  }
}
@media (max-width: 640px) {
  .signedinAssessments__image2 {
    width: 250px;
  }
}
.signedinAssessments__main {
  display: flex;
  flex-flow: column wrap;
  margin-left: 2em;
  margin-right: 0em;
}
@media (max-width: 640px) {
  .signedinAssessments__main {
    margin-left: 0em;
    margin-right: 0em;
  }
}
.signedinAssessments__options {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1em;
  position: relative;
}
.signedinAssessments__number {
  width: 1.5em;
  line-height: 1.5em;
  border-radius: 50%;
  text-align: center;
  font-size: 1rem;
  font-weight: 800;
  background-color: #008375;
  color: white;
  margin-right: 0.375em;
  margin-top: -0.125em;
  position: absolute;
}
.signedinAssessments__singlePay {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 200px;
}
.signedinAssessments__price {
  font-family: proxima-nova, sans-serif;
  color: #999999;
  font-size: 2rem;
}
.signedinAssessments__button {
  background-color: #008375;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: calc(12px + 1vmin);
  padding: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.signedinAssessments__primary {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
.signedinAssessments__text {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-size: calc(18px + 2vmin);
  line-height: calc(24px + 2vmin);
  margin-bottom: 5px;
}
.signedinAssessments__text-bold {
  font-weight: 800;
}
.signedinAssessments__purchase {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: calc(15px + 5vmin);
  padding-right: 10px;
}
.signedinAssessments__price {
  font-family: proxima-nova, sans-serif;
  color: #999999;
  font-size: calc(12px + 2vmin);
  padding-right: calc(3px + 1vmin);
}
.signedinAssessments__button {
  background-color: #008375;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: calc(12px + 1vmin);
  padding: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.signedinAssessments__subtext {
  margin-top: 200px;
  color: #999999;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedinAssessments__subtext {
    margin-top: 200px;
    font-size: 14px;
  }
}
@media (max-width: 640px) {
  .signedinAssessments__subtext {
    margin-top: 100px;
    font-size: 12px;
  }
}
.signedinAssessments__discount {
  background-color: transparent;
  border: none;
  color: #999999;
  text-decoration: underline;
}
.signedinAssessments__discount:hover {
  color: #009ade;
}
.signedinAssessments__imageGift {
  width: 200px;
  align-self: flex-end;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedinAssessments__imageGift {
    width: 150px;
  }
}
@media (max-width: 640px) {
  .signedinAssessments__imageGift {
    width: 140px;
  }
}
.signedinAssessments__table {
  width: 250px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedinAssessments__table {
    width: 200px;
  }
}
@media (max-width: 640px) {
  .signedinAssessments__table {
    width: 150px;
  }
}
.signedinAssessments__tr {
  border-bottom: 1px solid #e6e6e6;
}
.signedinAssessments__th {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #4d4d4d;
  padding-bottom: 0.25em;
  height: 0;
}
.signedinAssessments__td {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #b3b3b3;
  padding-bottom: 0.75em;
  padding-top: 0.75em;
  height: 0;
}
@media (max-width: 640px) {
  .signedinAssessments__td {
    font-size: 0.875rem;
  }
}
.signedinAssessments__list {
  color: #808080;
  opacity: 0;
  position: absolute;
  right: 0%;
  top: 100%;
}
.signedinAssessments__list-open {
  opacity: 1;
  background-color: #e6e6e6;
  overflow: auto;
  height: 150px;
  width: 50px;
  border: solid 1px black;
  border-radius: 5px;
}
.signedinAssessments__listOption {
  text-align: right;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  font-size: 1.125rem;
}
.signedinAssessments__listOption:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
@media (max-width: 640px) {
  .signedinAssessments__listOption {
    font-size: 1rem;
    padding-right: 0.75em;
  }
}
.signedinAssessments__dropdown {
  color: #808080;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  background-color: #e6e6e6;
  border: solid 1px black;
  border-radius: 5px;
  margin-right: 0.75em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  position: relative;
}
.signedinAssessments__dropdown:hover {
  cursor: pointer;
}
.signedinAssessments__down {
  margin-left: 0.5em;
}/*# sourceMappingURL=SignedInAssessments.css.map */
.about__container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.about__top {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  max-width: 600px;
  padding-left: 1em;
  padding-right: 1em;
}

.about__top h3 {
  font-size: 16px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  color: #008375;
  margin-top: 20px;
  margin-bottom: 30px;
}

.about__top h1 {
  font-size: 40px;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  color: #004D5A;
  margin-bottom: 15px;
  text-align: center;
}

.about__top p {
  font-size: 16px;
  line-height: calc(16px * 1.25);
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  color: #999999;
}
@media (max-width: 640px) {
  .about__top p {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.about__middle {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  padding-bottom: 40px;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
}

.about__middle h3 {
  font-size: 16px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  color: #008375;
  margin-top: 50px;
  margin-bottom: 30px;
}

.about__abouts {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  max-width: 600px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__abouts {
    grid-gap: 1em;
    gap: 1em;
  }
}
@media (max-width: 640px) {
  .about__abouts {
    grid-gap: 0.5em;
    gap: 0.5em;
    flex-flow: row wrap;
  }
}

.about__about {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
}

.about__about h1 {
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  color: #008375;
  margin-top: 15px;
  margin-bottom: 10px;
}
.about__pic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__pic-self {
  width: 250px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__pic-self {
    width: 200px;
  }
}
@media (max-width: 640px) {
  .about__pic-self {
    width: 200px;
  }
}

.about__info p {
  font-size: 0.875rem;
  line-height: calc(14px * 1.25);
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  color: #585858;
  text-align: center;
  margin-bottom: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__info p {
    width: 200px;
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .about__info p {
    width: 200px;
    font-size: 0.875rem;
  }
}

.about__bottom {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__bottom {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 640px) {
  .about__bottom {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.about__bottom h3 {
  font-size: 16px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  color: #008375;
  margin-top: 50px;
  margin-bottom: 1.5em;
}
@media (max-width: 640px) {
  .about__bottom h3 {
    margin-bottom: 2.5em;
  }
}

.about__bottom-content {
  color: white;
  width: 100%;
  vertical-align: middle;
  align-self: center;
}

@media (max-width: 640px) {
  .about__bottom-left {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.about__bottom-left h1 {
  font-size: 2.5rem;
  font-family: garamond;
  font-weight: 600;
  color: #004D5A;
  margin-bottom: 1em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__bottom-left h1 {
    font-size: 2.25rem;
  }
}
@media (max-width: 640px) {
  .about__bottom-left h1 {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 1em;
  }
}

.about__bottom-left h2 {
  font-size: 1.125rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  color: #004D5A;
  margin-bottom: 3px;
  margin-top: 5px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__bottom-left h2 {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .about__bottom-left h2 {
    font-size: 0.875rem;
    margin-left: 1em;
  }
}

.about__bottom-left p {
  font-size: 0.875rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  color: #585858;
  margin-bottom: 10px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__bottom-left p {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .about__bottom-left p {
    font-size: 0.75rem;
    margin-left: 1em;
  }
}

.about__bottom-left a {
  font-size: 0.875rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  color: #585858;
  margin-bottom: 10px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__bottom-left a {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .about__bottom-left a {
    font-size: 0.75rem;
    margin-left: 1em;
  }
}

.about__bottom-left h4 {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  color: #008375;
  margin-bottom: 5px;
  text-decoration: underline;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .about__bottom-left h4 {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .about__bottom-left h4 {
    font-size: 0.875rem;
    margin-left: 1em;
  }
}

.about__button {
  background-color: transparent;
  border: none;
  padding: none;
  margin: none;
}

.content__pic-self {
  float: right;
  width: 60%;
  max-width: 300px;
  margin-left: 2rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .content__pic-self {
    max-width: 250px;
    width: 70%;
  }
}
@media (max-width: 640px) {
  .content__pic-self {
    width: 80%;
    min-width: 200px;
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }
}

.about__collapsible {
  align-items: left;
  overflow: hidden;
}
.about__collapsible.collapsed {
  display: none;
}
.about__collapsible.expanded {
  display: block;
}

.about__form {
  display: flex;
  flex-flow: column nowrap;
  margin-right: 2em;
  margin-top: 1em;
}
@media (max-width: 640px) {
  .about__form {
    margin-left: 0em;
    width: 100%;
    max-width: 100%;
  }
}

.about__input {
  border-style: none;
  border-top: 1px solid black;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  max-width: 300px;
}
@media (max-width: 640px) {
  .about__input {
    max-width: 100%;
  }
}
.about__input-long {
  height: 300px;
  text-align: left;
  overflow: scroll;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 100%;
  border-bottom: 1px solid black;
}

.about__recaptcha {
  margin-top: 1em;
}
@media (max-width: 640px) {
  .about__recaptcha {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.about__submit {
  align-self: flex-end;
  display: block;
  margin-left: auto;
  margin-top: -2em;
  border: none;
  border-radius: 5px;
  background-color: #008375;
  color: white;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
}
.about__submit-disabled {
  color: white;
  background-color: #999999;
}
@media (max-width: 640px) {
  .about__submit {
    margin-top: 1em;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.about__popup {
  background-color: #008375;
  width: 450px;
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  padding-top: 0px;
  padding-bottom: 1em;
}
@media (max-width: 640px) {
  .about__popup {
    width: 325px;
  }
}

.about__popupClose {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-size: 1.875rem;
  color: #ffffff;
  border-style: none;
  align-self: flex-end;
  margin-right: -0.125em;
}
@media (max-width: 640px) {
  .about__popupClose {
    font-size: 1.375rem;
    margin-right: -0.2em;
  }
}

.about__popupPrimary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 640px) {
  .about__popupPrimary {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}/*# sourceMappingURL=AboutUs.css.map */
.incomplete {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 300px;
  margin-left: 5%;
  margin-right: 5%;
}
.incomplete__title {
  font-family: garamond-premier-pro, serif;
  color: #d6eae5;
  font-size: 2.25rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
.incomplete__subtitle {
  font-family: proxima-nova, sans-serif;
  text-align: center;
  color: #3d7cca;
  font-size: 1.25rem;
  line-height: 1.375rem;
  font-weight: 700;
}
.incomplete__link {
  color: #3d7cca;
  text-decoration: underline;
}
.incomplete__link:hover {
  color: white;
}

.results {
  background-image: linear-gradient(to bottom, #102960 0%, #041847 100%), linear-gradient(to bottom, #041847 25%, #041847 100%);
  background-size: 100% 325px, 100% auto;
  background-position: 0 0, 0 auto;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.results__page {
  width: 100%;
  height: 100%;
  padding-left: 7%;
  padding-right: 7%;
  align-self: center;
}

.recharts-responsive-container {
  display: flex !important;
  flex-flow: row nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  margin: 0 !important;
  align-self: center !important;
  width: 100% !important;
  height: 450px !important;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .recharts-responsive-container {
    height: 450 !important;
  }
}
@media (max-width: 640px) {
  .recharts-responsive-container {
    height: 420px !important;
    width: 280px !important;
    margin-left: -5em;
    margin-top: 2em;
  }
}

.recharts-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-left: -1em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .recharts-wrapper {
    margin-left: -3em;
  }
}
@media (max-width: 640px) {
  .recharts-wrapper {
    margin-left: -2.5em;
  }
}

@media (max-width: 640px) {
  .chart {
    padding-right: 0px;
    padding-left: 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
}
.chart__responsive {
  width: 200px;
  height: 500px;
}
@media (max-width: 640px) {
  .chart__responsive {
    height: 300px;
  }
}
.chart__rectangle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.chart__rectangle-trifecta {
  padding-bottom: 30px;
}
.chart__icon {
  width: auto;
  scale: 5;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .chart__icon {
    scale: 3;
  }
}
@media (max-width: 640px) {
  .chart__icon {
    scale: 2;
  }
}
.chart__icon-trifecta {
  scale: 2.5;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .chart__icon-trifecta {
    scale: 2.5;
  }
}
@media (max-width: 640px) {
  .chart__icon-trifecta {
    scale: 2;
  }
}
.chart__body {
  font-family: garamond-premier-pro, serif;
  font-size: 28px;
  font-weight: 600;
  color: white;
  margin-top: 50px;
  margin-bottom: 5px;
}
@media (max-width: 640px) {
  .chart__body {
    font-size: 1rem;
  }
}
.chart__body-trifecta {
  margin-top: 20px;
  font-size: 1rem;
}
@media (max-width: 640px) {
  .chart__body-trifecta {
    font-size: 0.75rem;
  }
}
.chart__subbody {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 640px) {
  .chart__subbody {
    font-size: 0.75rem;
    font-weight: 200;
  }
}
.chart__subbody-trifecta {
  font-size: 0.75rem;
}
@media (max-width: 640px) {
  .chart__subbody-trifecta {
    font-size: 0.625rem;
  }
}
.chart__text__xaxis {
  font-size: 20px;
}
.chart__tooltip {
  background-color: rgba(0, 78, 80, 0.9294117647);
  border-radius: 5px;
  padding: 12px;
  font-size: 1rem;
  line-height: 20px;
  font-family: proxima-nova, sans-serif;
  font-weight: 200;
  text-align: left;
  width: 250px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .chart__tooltip {
    font-size: 0.875rem;
    line-height: 0.9rem;
    width: 200px;
  }
}
@media (max-width: 640px) {
  .chart__tooltip {
    font-size: 10px;
    line-height: 12px;
    width: 100px;
  }
}
.chart__tooltip-h1 {
  font-weight: 700;
  padding-bottom: 3px;
}

.carousel {
  margin-top: 10vh;
  margin-bottom: 10vh;
  height: 550px;
  background-color: transparent;
  width: 1000px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .carousel {
    width: 600px;
  }
}
@media (max-width: 640px) {
  .carousel {
    width: 385px;
    margin-top: 2em;
  }
}
.carousel__item {
  background-color: transparent;
  height: 500px;
  color: white;
}
@media (max-width: 640px) {
  .carousel__box {
    width: auto;
  }
}
.carousel-control-prev {
  width: 5%;
}
@media (max-width: 640px) {
  .carousel-control-prev {
    margin-left: 2em;
  }
}
.carousel-control-next {
  width: 5%;
}
@media (max-width: 640px) {
  .carousel-control-next {
    margin-right: 2em;
  }
}

.bar-h1 {
  color: #6E4E9B;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  margin-left: 2.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .bar-h1 {
    margin-left: 2.5em;
  }
}
@media (max-width: 640px) {
  .bar-h1 {
    font-size: 0.875rem;
    margin-left: 1.5em;
  }
}
.bar-h1-wip {
  margin-left: 0em;
}
.bar-h1-ap {
  margin-left: 1.875em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .bar-h1-ap {
    margin-left: 2em;
  }
}
@media (max-width: 640px) {
  .bar-h1-ap {
    margin-left: 0.75em;
  }
}
.bar-h1-ap-centered {
  text-align: left;
  max-width: 35%;
  margin-bottom: 2em;
}

.bar-h2 {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-left: 1.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .bar-h2 {
    font-size: 1.5rem;
    margin-left: 2em;
  }
}
@media (max-width: 640px) {
  .bar-h2 {
    font-size: 1.25rem;
    margin-left: 1.125em;
  }
}
.bar-h2-wip {
  margin-left: 0em;
}
.bar-h2-ap {
  margin-left: 1.125em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .bar-h2-ap {
    margin-left: 1.625em;
  }
}
@media (max-width: 640px) {
  .bar-h2-ap {
    margin-left: 0.625em;
  }
}

.bar-h3 {
  color: rgb(133, 176, 255);
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 100;
}

.bar-h4 {
  color: white;
  font-family: proxima-nova, sans-serif;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .bar-h4 {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .bar-h4 {
    font-size: 0.75rem;
    width: 125%;
    margin-left: -10%;
  }
}

.custom-tooltip {
  background-color: rgba(0, 78, 80, 0.9294117647);
  border-radius: 5px;
  padding: 16px;
  font-size: 18px;
  font-family: proxima-nova, sans-serif;
  font-weight: 200;
  line-height: 18px;
  max-width: 25%;
  text-align: left;
}

.custom-tooltip__h1 {
  font-weight: 700;
  padding-bottom: 3px;
}

.telosChart {
  height: 100%;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  position: absolute;
  bottom: -60px;
}
@media (max-width: 640px) {
  .telosChart {
    margin-left: -1%;
    bottom: -90px;
  }
}
.telosChart__ipButton {
  position: absolute;
  background-color: rgba(0, 109, 178, 0.4);
  width: 420px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid rgb(255, 255, 255);
  transform: rotate(60deg);
  font-size: 2rem;
  font-family: proxima-nova, sans-serif;
  color: rgb(0, 109, 178);
  z-index: 10;
  left: 37.75%;
  top: 24.5%;
}
.telosChart__ipButton:hover {
  color: white;
  text-shadow: 0px 0px 10px white;
}
.telosChart__ipButton-disabled {
  color: gray;
  background-color: rgba(1, 1, 1, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.telosChart__ipButton-disabled:hover {
  color: gray;
  text-shadow: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__ipButton {
    left: 29.5%;
    top: 24.5%;
  }
}
@media (max-width: 640px) {
  .telosChart__ipButton {
    left: 30%;
    top: 22%;
    width: 275px;
    height: 100px;
    font-size: 1.25rem;
  }
}
.telosChart__apButton {
  position: absolute;
  background-color: rgba(0, 131, 117, 0.4);
  width: 420px;
  height: 152px;
  border-radius: 50%;
  border: 1px solid rgb(255, 255, 255);
  transform: rotate(-60deg);
  font-size: 2rem;
  font-family: proxima-nova, sans-serif;
  color: rgb(0, 131, 117);
  z-index: 20;
  left: 20.25%;
  top: 24.25%;
}
.telosChart__apButton:hover {
  color: white;
  text-shadow: 0px 0px 10px white;
}
.telosChart__apButton-disabled {
  color: gray;
  background-color: rgba(1, 1, 1, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.telosChart__apButton-disabled:hover {
  color: gray;
  text-shadow: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__apButton {
    left: 0.25%;
    top: 24.25%;
  }
}
@media (max-width: 640px) {
  .telosChart__apButton {
    left: 1.25%;
    top: 22%;
    width: 275px;
    height: 100px;
    font-size: 1.25rem;
  }
}
.telosChart__wipButton {
  position: absolute;
  background-color: rgba(144, 29, 180, 0.4);
  width: 420px;
  height: 152px;
  border-radius: 50%;
  border: 1px solid rgb(255, 255, 255);
  transform: rotate(0deg);
  font-size: 2rem;
  font-family: proxima-nova, sans-serif;
  color: rgb(144, 29, 180);
  z-index: 0;
  left: 29%;
  top: 54.5%;
}
.telosChart__wipButton:hover {
  color: white;
  text-shadow: 0px 0px 10px white;
}
.telosChart__wipButton-disabled {
  color: gray;
  background-color: rgba(1, 1, 1, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.telosChart__wipButton-disabled:hover {
  color: gray;
  text-shadow: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__wipButton {
    left: 15%;
    top: 54.5%;
  }
}
@media (max-width: 640px) {
  .telosChart__wipButton {
    left: 15.5%;
    top: 41.75%;
    width: 275px;
    height: 100px;
    font-size: 1.25rem;
  }
}
.telosChart__trifectaButton {
  position: absolute;
  background-color: transparent;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0);
  transform: rotate(0deg);
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
  color: rgb(230, 230, 230);
  z-index: 60;
  left: 45.75%;
  top: 41.5%;
}
.telosChart__trifectaButton:hover {
  color: white;
  text-shadow: 0px 0px 10px white;
}
.telosChart__trifectaButton-disabled {
  color: gray;
}
.telosChart__trifectaButton-disabled:hover {
  color: gray;
  text-shadow: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__trifectaButton {
    left: 43%;
    top: 40.75%;
  }
}
@media (max-width: 640px) {
  .telosChart__trifectaButton {
    left: 44.75%;
    top: 33.5%;
    width: 50px;
    height: 50px;
    font-size: 0.5rem;
  }
}
.telosChart__ipapButton {
  position: absolute;
  background-color: transparent;
  width: 80px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0);
  transform: rotate(0deg);
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
  color: rgb(230, 230, 230);
  z-index: 60;
  left: 46%;
  top: 4%;
}
.telosChart__ipapButton:hover {
  color: white;
  text-shadow: 0px 0px 10px white;
}
.telosChart__ipapButton-disabled {
  color: gray;
}
.telosChart__ipapButton-disabled:hover {
  color: gray;
  text-shadow: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__ipapButton {
    left: 43.25%;
    top: 3.5%;
  }
}
@media (max-width: 640px) {
  .telosChart__ipapButton {
    left: 43.25%;
    top: 7%;
    width: 60px;
    height: 120px;
    font-size: 0.5rem;
  }
}
.telosChart__ipwipButton {
  position: absolute;
  background-color: transparent;
  width: 80px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0);
  transform: rotate(-60deg);
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
  color: rgb(230, 230, 230);
  z-index: 60;
  left: 61%;
  top: 52.5%;
}
.telosChart__ipwipButton:hover {
  color: white;
  text-shadow: 0px 0px 10px white;
}
.telosChart__ipwipButton-disabled {
  color: gray;
}
.telosChart__ipwipButton-disabled:hover {
  color: gray;
  text-shadow: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__ipwipButton {
    left: 71%;
    top: 54.5%;
  }
}
@media (max-width: 640px) {
  .telosChart__ipwipButton {
    left: 67%;
    top: 37%;
    width: 60px;
    height: 120px;
    font-size: 0.5rem;
  }
}
.telosChart__ipwipText {
  display: inline-block;
  transform: rotate(60deg);
}
.telosChart__apwipButton {
  position: absolute;
  background-color: transparent;
  width: 80px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0);
  transform: rotate(60deg);
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
  color: rgb(230, 230, 230);
  z-index: 60;
  left: 30.5%;
  top: 52.5%;
}
.telosChart__apwipButton:hover {
  color: white;
  text-shadow: 0px 0px 10px white;
}
.telosChart__apwipButton-disabled {
  color: gray;
}
.telosChart__apwipButton-disabled:hover {
  color: gray;
  text-shadow: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__apwipButton {
    left: 15.5%;
    top: 54.5%;
  }
}
@media (max-width: 640px) {
  .telosChart__apwipButton {
    left: 18.5%;
    top: 37%;
    width: 60px;
    height: 120px;
    font-size: 0.5rem;
  }
}
.telosChart__apwipText {
  display: inline-block;
  transform: rotate(-60deg);
}
.telosChart__header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -12%;
  left: 0%;
  width: 100%;
  text-align: center;
}
.telosChart__h1 {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__h1 {
    font-size: 1.125rem;
    font-weight: 600;
  }
}
@media (max-width: 640px) {
  .telosChart__h1 {
    font-size: 1rem;
    font-weight: 500;
  }
}
.telosChart__h2 {
  font-size: 1rem;
  color: #6E4E9B;
  font-weight: 500;
  font-family: proxima-nova, sans-serif;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart__h2 {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .telosChart__h2 {
    font-size: 0.75rem;
  }
}

.wip-primary-item {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: proxima-nova, sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.wip-primary-item:hover {
  cursor: default;
}
@media (max-width: 640px) {
  .wip-primary-item {
    font-size: 1.25rem;
  }
}

.wip-secondary-item {
  font-size: 1rem;
  font-weight: 400;
  font-family: proxima-nova, sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.wip-secondary-item:hover {
  cursor: default;
}
@media (max-width: 640px) {
  .wip-secondary-item {
    font-size: 0.875rem;
  }
}

.wip-primary-image {
  width: 30px;
  margin-right: 15px;
}

.wip-secondary-image {
  width: 20px;
  margin-right: 25px;
}

.my-tooltip {
  background-color: transparent;
  width: 350px;
}

.my-tooltip > .tooltip-inner {
  color: white;
  background-color: rgb(0, 74, 88);
  max-width: 100%;
}

.my-tooltip .arrow::before {
  background-color: transparent;
  border: none;
}

.tooltip-h1 {
  font-size: 12px;
  font-weight: 1000;
  text-align: left;
  color: white;
  font-family: proxima-nova, sans-serif;
}

.tooltip-body {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: rgb(150, 183, 175);
  font-family: proxima-nova, sans-serif;
}

/********** Draw circles. *********/
.telos-chart__ip-circle {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin: 0px;
  -webkit-clip-path: ellipse(6em 4em);
          clip-path: ellipse(6em 4em);
  transform: rotate(60deg);
  position: absolute;
  z-index: 1;
  background-color: red;
  opacity: 0.25;
  left: 50%;
  top: 29%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telos-chart__ip-circle {
    left: 20%;
    top: 29%;
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .telos-chart__ip-circle {
    left: 22.5%;
    top: 25.5%;
    padding: 0.75em;
    font-size: 0.625rem;
  }
}

.telos-chart__ap-circle {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin: 0px;
  -webkit-clip-path: ellipse(6em 4em);
          clip-path: ellipse(6em 4em);
  transform: rotate(-60deg);
  position: absolute;
  left: 32%;
  top: 29%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telos-chart__ap-circle {
    left: 20%;
    top: 29%;
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .telos-chart__ap-circle {
    left: 22.5%;
    top: 25.5%;
    padding: 0.75em;
    font-size: 0.625rem;
  }
}

.telos-chart__wip-circle {
  padding: 0px;
  margin: 0px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 37%;
  top: 53.5%;
}
@media (min-width: 500px) {
  .telos-chart__wip-circle {
    left: 40.5%;
    top: 53.5%;
  }
}
@media (min-width: 768px) {
  .telos-chart__wip-circle {
    left: 39.5%;
    top: 77.5%;
  }
}
@media (min-width: 992px) {
  .telos-chart__wip-circle {
    left: 42%;
    top: 77.5%;
  }
}
@media (min-width: 1200px) {
  .telos-chart__wip-circle {
    left: 43.25%;
    top: 77.75%;
  }
}

.telos-chart__wip-ip-circle {
  padding: 0px;
  margin: 0px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 70%;
  top: 51.5%;
}
@media (min-width: 500px) {
  .telos-chart__wip-ip-circle {
    left: 67%;
    top: 51.5%;
  }
}
@media (min-width: 768px) {
  .telos-chart__wip-ip-circle {
    left: 67%;
    top: 75%;
  }
}
@media (min-width: 992px) {
  .telos-chart__wip-ip-circle {
    left: 63%;
    top: 75%;
  }
}
@media (min-width: 1200px) {
  .telos-chart__wip-ip-circle {
    left: 60%;
    top: 75%;
  }
}

.telos-chart__ap-ip-circle {
  padding: 0px;
  margin: 0px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 41%;
  top: 18.5%;
}
@media (min-width: 500px) {
  .telos-chart__ap-ip-circle {
    left: 43.5%;
    top: 19%;
  }
}
@media (min-width: 768px) {
  .telos-chart__ap-ip-circle {
    left: 42.5%;
    top: 23.5%;
  }
}
@media (min-width: 992px) {
  .telos-chart__ap-ip-circle {
    left: 44.5%;
    top: 23.5%;
  }
}
@media (min-width: 1200px) {
  .telos-chart__ap-ip-circle {
    left: 45.5%;
    top: 24%;
  }
}

.telos-chart__wip-ap-circle {
  padding: 0px;
  margin: 0px;
  position: absolute;
  left: calc(50% - 219.375px);
  top: calc(0% + 9% + 153.0612244898px + 153px);
  transform: rotate(0deg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 10%;
  top: 52%;
}
@media (min-width: 500px) {
  .telos-chart__wip-ap-circle {
    left: 21%;
    top: 52%;
  }
}
@media (min-width: 768px) {
  .telos-chart__wip-ap-circle {
    left: 19%;
    top: 75%;
  }
}
@media (min-width: 992px) {
  .telos-chart__wip-ap-circle {
    left: 27%;
    top: 75%;
  }
}
@media (min-width: 1200px) {
  .telos-chart__wip-ap-circle {
    left: 31%;
    top: 75%;
  }
}

.telos-chart__telos-circle {
  padding: 0px;
  margin: 0px;
  opacity: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 43%;
  top: 39%;
}
@media (min-width: 500px) {
  .telos-chart__telos-circle {
    left: 45%;
    top: 39%;
  }
}
@media (min-width: 768px) {
  .telos-chart__telos-circle {
    left: 46.5%;
    top: 56%;
  }
}
@media (min-width: 992px) {
  .telos-chart__telos-circle {
    left: 47.25%;
    top: 56.5%;
  }
}
@media (min-width: 1200px) {
  .telos-chart__telos-circle {
    left: 48%;
    top: 56.5%;
  }
}

.telos-chart__button-single {
  background-color: transparent;
  background-color: blue;
  opacity: 25%;
  border: 0px;
  width: 90px;
  height: 50px;
}
@media (min-width: 768px) {
  .telos-chart__button-single {
    width: 150px;
    height: 50px;
  }
}

.telos-chart__button-double {
  background-color: transparent;
  border: 0px;
  width: 60px;
  height: 50px;
}
@media (min-width: 768px) {
  .telos-chart__button-double {
    width: 100px;
    height: 50px;
  }
}

.telos-chart__button-center {
  background-color: transparent;
  border: 0px;
  width: 50px;
  height: 50px;
}

.modal-title {
  font-family: proxima-nova;
}

.modal-body {
  font-family: proxima-nova;
}

.modal {
  font-size: 12px;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;
}

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.bar-text {
  font-size: 1rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .bar-text {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .bar-text {
    font-size: 0.875rem;
  }
}

.bar-text__xaxis {
  font-size: 1rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .bar-text__xaxis {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .bar-text__xaxis {
    font-size: 0.875rem;
  }
}

.jobs-table th {
  border: 1px !important;
  line-height: calc(8px + 1vw);
  font-size: calc(7.2px + 1vw);
}

.jobs-table td {
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  line-height: 0.9em;
  padding-top: 0.625em;
  padding-right: 0.75em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .jobs-table td {
    font-size: 0.75rem;
    line-height: 1em;
    padding-top: 0.8em;
    padding-right: 0.75em;
  }
}
@media (max-width: 640px) {
  .jobs-table td {
    font-size: 0.625rem;
    line-height: 1em;
    padding-top: 0.4em;
    padding-right: 0.75em;
  }
}

.jobs-table__buttons {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .jobs-table__buttons {
    font-size: 0.875rem;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
@media (max-width: 640px) {
  .jobs-table__buttons {
    width: 275px;
    margin-top: 40px;
  }
}

.jobs-table__button {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  color: white;
  border-radius: 3px;
}

@media (max-width: 640px) {
  .jobs-table__occupations {
    padding-bottom: 0px;
    padding-right: 0px;
  }
}

.jobs-table__body {
  font-size: 10rem;
}

.job-profiles {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-profiles__button {
  background-color: #3D7CCA;
  color: white;
  font-family: proxima-nova;
  font-size: 20px;
  border: none;
  padding: 10px;
  border-radius: 3px;
}
.job-profiles__button:hover {
  color: white;
  background-color: #901DB4;
  text-decoration: none;
  cursor: pointer;
}

.telos-chart__background-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.telos-chart__background-image {
  width: 425px;
  margin-top: 10px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telos-chart__background-image {
    width: 425px;
    margin-top: 10px;
  }
}
@media (max-width: 640px) {
  .telos-chart__background-image {
    width: 275px;
    margin-top: 40px;
  }
}

.telosChartModal {
  width: 800px;
  max-width: none;
  background-color: none;
  border: none;
  border: none;
  outline: none;
  margin: auto;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChartModal {
    width: 390px;
  }
}
@media (max-width: 640px) {
  .telosChartModal {
    width: 275px;
  }
}
.telosChartModal__content {
  background-color: rgba(4, 23, 67, 0.9058823529);
  border: 1px solid #3473BD;
  margin-top: 5%;
  margin-bottom: 5%;
}
.telosChartModal__content-ipap {
  background: linear-gradient(#5C8FC7, #0169A8);
  border: 20px solid #004B5D;
  border-radius: 2em;
}
.telosChartModal__content-apwip {
  background: linear-gradient(#026C6A, #063B4F);
  border: 20px solid #3A1770;
  border-radius: 2em;
}
.telosChartModal__content-wipip {
  background: linear-gradient(#A343C1, #6B21AF);
  border: 20px solid #01437E;
  border-radius: 2em;
}
.telosChartModal__content-trifecta {
  background-color: #3A1770;
  border: 20px solid #3A1770;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChartModal__content-trifecta {
    border-width: 10px;
  }
}
@media (max-width: 640px) {
  .telosChartModal__content-trifecta {
    border-width: 7px;
  }
}
.telosChartModal__backdrop {
  background-color: rgba(0, 0, 0, 0.1);
}
.telosChartModal__header {
  border: none;
  padding-bottom: 0px;
  position: relative;
  color: white;
}
.telosChartModal__header .btn-close {
  color: white;
  opacity: 1;
  position: absolute;
  right: 3%;
  top: 10%;
  margin-top: 0.5em;
}
.telosChartModal__header > .close {
  color: white;
  position: absolute;
  right: 3%;
  margin: 0;
  padding: 0;
}
.telosChartModal__header-trifecta > .close {
  color: #001343;
}
.telosChartModal__title {
  color: white;
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChartModal__title {
    font-size: 24px;
    line-height: 24px;
  }
}
@media (max-width: 640px) {
  .telosChartModal__title {
    font-size: 18px;
    line-height: 18px;
  }
}
.telosChartModal__title-overlap {
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}
.telosChartModal__title-center {
  width: 100%;
  text-align: center;
  color: #001343;
}
.telosChartModal__body {
  color: white;
}
.telosChartModal__body h1 {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 1px;
}
.telosChartModal__body span {
  font-weight: 300;
}
.telosChartModal__body-overlap {
  text-align: center;
}
.telosChartModal__text {
  margin-bottom: 10px;
  font-size: 16px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChartModal__text {
    font-size: 12px;
  }
}
@media (max-width: 640px) {
  .telosChartModal__text {
    font-size: 10px;
  }
}
.telosChartModal__border {
  border: 20px solid #01437E;
  border-radius: 5px;
  background-clip: padding-box;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChartModal__border {
    border-width: 10px;
  }
}
@media (max-width: 640px) {
  .telosChartModal__border {
    border-width: 7px;
  }
}
.telosChartModal__border-blue {
  border-color: #01437E;
  background-color: #01437E;
}
.telosChartModal__border-green {
  border-color: #126486;
  background-color: white;
}
.telosChartModal__link {
  color: white;
  text-decoration: none;
  line-height: 1.25em;
}
.telosChartModal__link:hover {
  color: #c2c2c2;
  text-decoration: none;
}
.telosChartModal__link-ip {
  color: #6997CA;
}
.telosChartModal__link-ip:hover {
  color: #8caacc;
  text-decoration: none;
}
.telosChartModal__link-ap {
  color: #5CA79F;
}
.telosChartModal__link-ap:hover {
  color: #8da7a3;
  text-decoration: none;
}
.telosChartModal__link-wip {
  color: #BC77D2;
}
.telosChartModal__link-wip:hover {
  color: #cbafd4;
  text-decoration: none;
}
.telosChartModal__link-trifecta {
  color: black;
}
.telosChartModal__link-trifecta:hover {
  color: #949494;
  text-decoration: none;
}/*# sourceMappingURL=Results.css.map */
.carousel {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  color: white;
  width: 100%;
  max-width: 1100px;
  height: auto;
  justify-content: center;
  margin: 0em 2em 0em;
}
@media (max-width: 640px) {
  .carousel {
    margin: 0em 0.5em 0em;
  }
}

.carousel__control {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 60%;
}
.carousel__control-prev {
  left: -475px;
  transform: translateX(50vw);
  z-index: 500;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .carousel__control-prev {
    left: -46%;
  }
}
@media (max-width: 640px) {
  .carousel__control-prev {
    left: -48%;
  }
}
.carousel__control-next {
  right: -475px;
  transform: translateX(-50vw);
}
@media (min-width: 640px) and (max-width: 1007px) {
  .carousel__control-next {
    right: -46%;
  }
}
@media (max-width: 640px) {
  .carousel__control-next {
    right: -48%;
  }
}

.carousel__icon {
  width: 1.75rem;
  height: 1.75rem;
  filter: invert(36%) sepia(78%) saturate(3035%) hue-rotate(183deg) brightness(96%) contrast(91%);
}
.carousel__icon:hover {
  filter: invert(99%) sepia(8%) saturate(216%) hue-rotate(260deg) brightness(116%) contrast(100%);
}

.carousel__item p {
  color: white;
}/*# sourceMappingURL=Carousel.css.map */
.carouselItem {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 3rem 0em 3rem;
  padding-bottom: 10em;
  width: 75%;
}
.carouselItem h1 {
  font-family: garamond-premier-pro, serif;
  font-weight: 500;
  font-size: 2.5rem;
  color: white;
  padding: 0 0 0.375em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .carouselItem h1 {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  .carouselItem h1 {
    font-size: 1.5rem;
  }
}
.carouselItem h2 {
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: #3d7cca;
  line-height: 1.25em;
  padding: 0 0 1em;
}
@media (max-width: 640px) {
  .carouselItem h2 {
    font-size: 0.875rem;
  }
}

.carouselItemInfo__row {
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  margin: 0 0 0.25em;
}
@media (max-width: 640px) {
  .carouselItemInfo__row {
    padding: 0.125em 0em 0.125em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 640px) {
  .carouselItemInfo__row-bottomRow {
    padding-bottom: 1.5em;
  }
}
.carouselItemInfo__imageContainer {
  flex: 0 0 auto; /* Allow the image container to take its natural width. */
  margin-right: 1em; /* Add some spacing between the image and the text. */
  width: 50px;
}
@media (max-width: 640px) {
  .carouselItemInfo__imageContainer {
    width: 50px;
  }
}
.carouselItemInfo__image {
  max-width: 100%; /* Ensure the image doesn't exceed its container. */
  height: auto; /* Maintain the aspect ratio. */
}
.carouselItemInfo__col {
  flex: 1 1; /* Allow the content column to take remaining space. */
}
.carouselItemInfo__col-h1 {
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: #3d7cca;
  font-weight: 700;
  padding: 0.5em 0 0.125em;
}
@media (max-width: 640px) {
  .carouselItemInfo__col-h1 {
    font-size: 1rem;
    padding: 0.125em 0 0.125em;
  }
}
.carouselItemInfo__col-p {
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
  color: #aad2f0;
  line-height: 1.25em;
}
@media (max-width: 640px) {
  .carouselItemInfo__col-p {
    font-size: 0.875rem;
    width: 100%;
  }
}
.carouselItemInfo__h1-italics {
  font-weight: 300;
  font-style: italic;
}/*# sourceMappingURL=CarouselPages.css.map */
.ipChart2 {
  margin-top: 4em;
  width: auto;
  height: auto;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipChart2 {
    width: auto;
    height: auto;
  }
}
@media (max-width: 640px) {
  .ipChart2 {
    width: 105%;
    width: auto;
    height: auto;
  }
}
.ipChart2 h1 {
  color: #6E4E9B;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  margin-left: 11px;
}
@media (max-width: 640px) {
  .ipChart2 h1 {
    font-size: 0.875rem;
  }
}
.ipChart2 h2 {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin-left: 12px;
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}
@media (max-width: 640px) {
  .ipChart2 h2 {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .ipChart2__container {
    width: 285px;
  }
}

.ipChart2__barValue {
  fill: white;
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
}
@media (max-width: 640px) {
  .ipChart2__barValue {
    font-size: 0.625rem;
    z-index: 1000;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .ipChart2__svg {
    width: 100%;
    max-width: 525px;
  }
}
@media (max-width: 640px) {
  .ipChart2__svg {
    width: 100%;
    max-width: 275px;
  }
}

.ipChart2__xlabel {
  fill: #54678a;
}
.ipChart2__xlabel-first {
  font-weight: 500;
  font-size: 1.5rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipChart2__xlabel-first {
    font-size: 1.25rem;
  }
}
@media (max-width: 640px) {
  .ipChart2__xlabel-first {
    font-size: 1rem;
  }
}
.ipChart2__xlabel-firstMobile {
  fill: transparent;
  width: 0px;
  height: 0px;
}
@media (max-width: 640px) {
  .ipChart2__xlabel-firstMobile {
    font-size: 1rem;
    width: auto;
    height: auto;
    fill: #54678a;
  }
}
.ipChart2__xlabel-second {
  font-size: 0.8rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipChart2__xlabel-second {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .ipChart2__xlabel-second {
    fill: transparent;
  }
}

.ipChart2__axis {
  stroke: #54678a;
  stroke-width: 2px;
}

.ipChart2__ylabel {
  fill: #54678a;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
}

.ipChart2__ticks {
  stroke: #54678a;
  stroke-width: 2px;
}

.ipChart2__foreignObject {
  background-color: rgba(0, 78, 80, 0.9294117647);
  padding: 1em;
  border-radius: 5px;
  pointer-events: none;
}
@media (max-width: 640px) {
  .ipChart2__foreignObject {
    padding: 0.625em;
  }
}

.ipChart2__tooltip-h1 {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipChart2__tooltip-h1 {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .ipChart2__tooltip-h1 {
    font-size: 0.75rem;
  }
}

.ipChart2__tooltip-body {
  color: rgb(150, 183, 175);
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.125em;
}
@media (max-width: 640px) {
  .ipChart2__tooltip-body {
    font-size: 0.75rem;
    line-height: 1em;
  }
}/*# sourceMappingURL=IPChart2.css.map */
.wipList {
  width: 100%;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 4em;
}
@media (max-width: 640px) {
  .wipList {
    width: 275px;
  }
}
.wipList__tooltip {
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 78, 80, 0.9294117647);
  padding: 1em;
  border-radius: 5px;
  width: 550px;
  pointer-events: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipList__tooltip {
    width: 400px;
  }
}
@media (max-width: 640px) {
  .wipList__tooltip {
    padding: 0.625em;
    width: 250px;
  }
}
.wipList__tooltip-h1 {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipList__tooltip-h1 {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .wipList__tooltip-h1 {
    font-size: 0.75rem;
  }
}
.wipList__tooltip-body {
  color: rgb(150, 183, 175);
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.125em;
}
@media (max-width: 640px) {
  .wipList__tooltip-body {
    font-size: 0.75rem;
    line-height: 1em;
  }
}/*# sourceMappingURL=WIPList2.css.map */
.apChart2 {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.apChart__blurbBox {
  margin-top: 2em;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: proxima-nova, sans-serif;
  font-size: 0.75rem;
  color: #3d7cca;
  line-height: 1.375em;
  padding-bottom: 3em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apChart__blurbBox {
    max-width: 600px;
  }
}
@media (max-width: 640px) {
  .apChart__blurbBox {
    max-width: 300px;
    font-size: 0.75rem;
    line-height: 1.375em;
    margin-top: 0em;
  }
}/*# sourceMappingURL=APChart.css.map */
.trifectaGraph {
  margin-top: 4em;
  width: 800px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .trifectaGraph {
    min-width: 500px;
  }
}
@media (max-width: 640px) {
  .trifectaGraph {
    min-width: 250px;
  }
}

.telosChart2 {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-height: 600px;
  position: relative;
  margin-top: 3em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart2 {
    margin-top: -3em;
  }
}
@media (max-width: 640px) {
  .telosChart2 {
    margin-top: 0em;
  }
}
.telosChart2__h1 {
  text-align: center;
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: 1rem;
  width: 90%;
  margin-bottom: 0.25em;
}
.telosChart2__h2 {
  text-align: center;
  font-family: proxima-nova, sans-serif;
  color: #7B51A1;
  font-size: 0.875rem;
  max-width: 450px;
  width: 90%;
}
.telosChart2__svg {
  width: 100%;
  height: 100%;
  padding-top: 5px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart2__svg {
    padding-top: 0px;
    width: 600px;
  }
}
@media (max-width: 640px) {
  .telosChart2__svg {
    margin-top: calc(-5em + 12vw);
  }
}
.telosChart2__ellipse {
  opacity: 0.4;
}
.telosChart2__ellipse-ap {
  fill: #008375;
}
.telosChart2__ellipse-ip {
  fill: #006DB2;
}
.telosChart2__ellipse-wip {
  fill: #901DB4;
}
.telosChart2__ellipse-overlap {
  fill: transparent;
}
.telosChart2__ellipse-trifecta {
  fill: #006DB2;
  opacity: 25%;
}
.telosChart2__ellipseText {
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
}
.telosChart2__ellipseText-ap {
  fill: rgb(0, 131, 117);
}
.telosChart2__ellipseText-ip {
  fill: rgb(0, 109, 178);
}
.telosChart2__ellipseText-wip {
  fill: rgb(144, 29, 180);
}
.telosChart2__ellipseText-on {
  fill: white;
}
.telosChart2__ellipseOverlapText {
  font-size: 0.3rem;
  font-family: proxima-nova, sans-serif;
  fill: white;
}
.telosChart2__ellipseOverlapText-on {
  fill: white;
}
.telosChart2__ellipseOverlapPath-ipap {
  fill: #1799b8;
  stroke-width: 2;
  stroke: white;
}
.telosChart2__ellipseOverlapPath-apwip {
  fill: #597aba;
  stroke-width: 2;
  stroke: white;
}
.telosChart2__ellipseOverlapPath-ipwip {
  fill: #8073d9;
  stroke-width: 2;
  stroke: white;
}
.telosChart2__ellipseOverlapPath-trifecta {
  fill: url(#myGradient);
  stroke-width: 2;
  stroke: white;
}
.telosChart2__ellipseButton :hover {
  cursor: pointer;
}
.telosChart2__infoButton {
  width: 20px;
  height: auto;
  background-color: transparent;
  border: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .telosChart2__infoButton {
    width: 20px;
  }
}
@media (max-width: 640px) {
  .telosChart2__infoButton {
    width: calc(12px + 0.75vw);
  }
}
.telosChart2__infoImage {
  width: 100%;
  height: auto;
}
.telosChart2__bottomTextBox {
  position: absolute;
  top: 30em;
}
@media (max-width: 640px) {
  .telosChart2__bottomTextBox {
    margin-left: 10%;
    margin-right: 10%;
    top: 80%;
  }
}
.telosChart2__bottomText {
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
  color: #6997CA;
}/*# sourceMappingURL=TelosChart2.css.map */
.singlePopup {
  position: absolute;
  z-index: 1000;
  width: 800px;
  height: auto;
  top: -5em;
  left: 50%;
  transform: translate(-50%, 0%); /* Center the box */
  display: flex;
  flex-flow: column nowrap;
  background-color: rgba(4, 23, 67, 0.9058823529);
  border: 1px solid #3473BD;
  border-radius: 5px;
  padding: 1.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .singlePopup {
    width: 90%;
    top: -1em;
  }
}
@media (max-width: 640px) {
  .singlePopup {
    width: 82%;
    top: -3em;
  }
}
.singlePopup__topRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1.5em;
}
.singlePopup__header {
  color: white;
  font-size: 3rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .singlePopup__header {
    font-size: 36px;
    line-height: 36px;
  }
}
@media (max-width: 640px) {
  .singlePopup__header {
    font-size: 36px;
    line-height: 36px;
  }
}
.singlePopup__closeButton {
  background-color: transparent;
  border: none;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 200;
}
.singlePopup__closeButton:hover {
  color: rgb(201, 201, 201);
}
.singlePopup__content-open {
  padding-right: 1em;
}
.singlePopup__content-open::-webkit-scrollbar {
  width: 0.75em;
}
.singlePopup__content-open::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(200, 200, 200, 0.1);
  border-radius: 10px;
}
.singlePopup__content-open::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200);
  outline: 1px solid slategrey;
  border-radius: 10px;
}
.singlePopup__text {
  font-family: proxima-nova, sans-serif;
  margin-bottom: 1em;
}
.singlePopup__text h1 {
  font-size: 1rem;
  margin-bottom: 0.35em;
}
.singlePopup__text span {
  font-size: 1rem;
}
.singlePopup__link {
  color: white;
  text-decoration: none;
  line-height: 1.125em;
  display: block;
  padding: 0.35em 0em 0.35em;
}
.singlePopup__link:hover {
  color: #c2c2c2;
  text-decoration: none;
}
.singlePopup__link-ip {
  color: #6997CA;
}
.singlePopup__link-ip:hover {
  color: #8caacc;
  text-decoration: none;
}
.singlePopup__link-ap {
  color: #5CA79F;
}
.singlePopup__link-ap:hover {
  color: #8da7a3;
  text-decoration: none;
}
.singlePopup__link-wip {
  color: #BC77D2;
}
.singlePopup__link-wip:hover {
  color: #cbafd4;
  text-decoration: none;
}
.singlePopup__link-trifecta {
  color: black;
}
.singlePopup__link-trifecta:hover {
  color: #949494;
  text-decoration: none;
}

.doublePopup {
  position: absolute;
  z-index: 1000;
  width: 800px;
  height: auto;
  top: -5em;
  left: 50%;
  transform: translate(-50%, 0%); /* Center the box */
  display: flex;
  flex-flow: column nowrap;
  padding: 1.25em;
  border-radius: 0.25em;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.744);
}
.doublePopup-ipap {
  background: linear-gradient(#5C8FC7, #0169A8);
  border: 16px solid #004B5D;
}
.doublePopup-ipwip {
  background: linear-gradient(#A343C1, #6B21AF);
  border: 16px solid #01437E;
}
.doublePopup-apwip {
  background: linear-gradient(#026C6A, #063B4F);
  border: 16px solid #3A1770;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .doublePopup {
    width: 90%;
    top: -1em;
  }
}
@media (max-width: 640px) {
  .doublePopup {
    width: 80%;
    top: -3em;
    padding: 0.75em 0.75em 0.75em;
  }
}
.doublePopup__topRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0em;
}
.doublePopup__header {
  color: white;
  font-size: 3rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  text-align: center;
  padding-top: 0.75em;
  padding-left: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .doublePopup__header {
    font-size: 36px;
    line-height: 36px;
  }
}
@media (max-width: 640px) {
  .doublePopup__header {
    font-size: 36px;
    line-height: 36px;
  }
}
.doublePopup__closeButton {
  background-color: transparent;
  border: none;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 200;
}
.doublePopup__closeButton:hover {
  color: rgb(201, 201, 201);
}
.doublePopup__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.doublePopup__content-open {
  padding-right: 1em;
}
.doublePopup__content-open::-webkit-scrollbar {
  width: 0.75em;
}
.doublePopup__content-open::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(200, 200, 200, 0.1);
  border-radius: 10px;
}
.doublePopup__content-open::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200);
  outline: 1px solid slategrey;
  border-radius: 10px;
}
.doublePopup__verticalLine {
  height: 25px;
  align-self: center;
  margin: 0.4em 0em 0.625em;
}
.doublePopup__verticalLine-apwip {
  border-left: 2px solid #92c9bc;
}
.doublePopup__verticalLine-ipap {
  border-left: 2px solid #77a3da;
}
.doublePopup__verticalLine-ipwip {
  border-left: 2px solid #cdaaca;
}
@media (max-width: 640px) {
  .doublePopup__verticalLine {
    margin: 0.25em 0em 0.4em;
  }
}
.doublePopup__text {
  font-family: proxima-nova, sans-serif;
  margin-bottom: 1em;
  margin-left: 1.75em;
}
.doublePopup__text h1 {
  font-size: 1.25rem;
  font-weight: 800;
}
.doublePopup__text span {
  font-size: 1rem;
}
.doublePopup__link {
  color: white;
  text-decoration: underline;
  line-height: 1.125em;
  display: block;
  padding: 0.35em 0em 0.35em;
  text-underline-offset: 0.125em;
}
.doublePopup__link:hover {
  color: #c2c2c2;
  text-decoration: none;
}
.doublePopup__link-ipap:visited {
  color: #77a3da;
}
.doublePopup__link-apwip:visited {
  color: #92c9bc;
}
.doublePopup__link-ipwip:visited {
  color: #cdaaca;
}

.trifectaPopup {
  position: absolute;
  z-index: 1000;
  width: 800px;
  height: auto;
  top: -7.4em;
  left: 50%;
  transform: translate(-50%, 0%); /* Center the box */
  display: flex;
  flex-flow: column nowrap;
  padding: 4em 4em 4em;
  border: 60px solid;
  border-image-source: url(/static/media/TrifectaResultsSquareBorder.1ea557fe.png);
  border-image-slice: 21% 19% 21% 19% fill;
  border-image-repeat: stretch;
  border-image-width: 100px 100px;
  border-image-outset: 0px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .trifectaPopup {
    width: 600px;
    top: -2em;
    padding: 4em 2em 4em;
    border-image-slice: 21% 19% 21% 19% fill;
    border-image-outset: 0px;
  }
}
@media (max-width: 640px) {
  .trifectaPopup {
    width: 97%;
    height: auto;
    top: -4em;
    padding: 0em 0em 0em;
    border-image-source: url(/static/media/TrifectaResultsSquareBorder.1ea557fe.png);
    border-image-slice: 21% 19% 21% 19% fill;
    border-image-width: 50px 50px;
    border-image-outset: 0px;
  }
}
.trifectaPopup__topRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 0em;
  position: relative;
}
.trifectaPopup__header {
  color: #163c66;
  font-size: 3rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .trifectaPopup__header {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 640px) {
  .trifectaPopup__header {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}
.trifectaPopup__closeButton {
  background-color: transparent;
  border: none;
  color: #999999;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  position: absolute;
  right: -2.5%;
  top: -22%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .trifectaPopup__closeButton {
    right: 3.5%;
    top: -45%;
  }
}
@media (max-width: 640px) {
  .trifectaPopup__closeButton {
    right: -1%;
    top: -21%;
  }
}
.trifectaPopup__closeButton:hover {
  color: rgb(201, 201, 201);
}
.trifectaPopup__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0em 3em 1em;
}
@media (max-width: 640px) {
  .trifectaPopup__content {
    padding: 0em 1em 1em;
  }
}
.trifectaPopup__content-open::-webkit-scrollbar {
  width: 0.75em;
}
.trifectaPopup__content-open::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(200, 200, 200, 0.1);
  border-radius: 10px;
}
.trifectaPopup__content-open::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200);
  outline: 1px solid slategrey;
  border-radius: 10px;
}
.trifectaPopup__verticalLine {
  border-left: 2px solid #901DB4;
  height: 25px;
  align-self: center;
  margin: 0.25em 0em 0.625em;
}
@media (max-width: 640px) {
  .trifectaPopup__verticalLine {
    margin: 0.25em 0em 0.4em;
  }
}
.trifectaPopup__text {
  font-family: proxima-nova, sans-serif;
  margin-bottom: 1em;
}
@media (max-width: 640px) {
  .trifectaPopup__text {
    margin-bottom: 1.5em;
  }
}
.trifectaPopup__text h1 {
  font-size: 1rem;
  font-weight: 800;
  color: #163c66;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .trifectaPopup__text h1 {
    font-size: 1rem;
    margin-bottom: 0.125em;
  }
}
@media (max-width: 640px) {
  .trifectaPopup__text h1 {
    font-size: 1.125rem;
    margin-bottom: 0.2em;
  }
}
.trifectaPopup__text span {
  font-size: 1rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .trifectaPopup__text span {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .trifectaPopup__text span {
    font-size: 0.75rem;
  }
}
.trifectaPopup__link {
  color: #163c66;
  text-decoration: underline;
  line-height: 1.25em;
  display: block;
  padding: 0.35em 0em 0.35em;
  text-underline-offset: 0.125em;
}
.trifectaPopup__link:hover {
  color: #c2c2c2;
  text-decoration: none;
}
.trifectaPopup__link:visited {
  color: #a7afc2;
}

.introPopup {
  position: absolute;
  z-index: 1000;
  width: 600px;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0%); /* Center the box */
  display: flex;
  flex-flow: column nowrap;
  background-color: rgba(4, 23, 67, 0.9058823529);
  border: 1px solid #3473BD;
  border-radius: 5px;
  padding: 1.25em;
  padding-left: 4em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .introPopup {
    width: 500px;
    top: 0%;
  }
}
@media (max-width: 640px) {
  .introPopup {
    width: 82%;
    padding-left: 3em;
  }
}
.introPopup__topRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1.5em;
}
.introPopup__header {
  color: white;
  font-size: 2.75rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  padding-right: 1em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .introPopup__header {
    font-size: 2rem;
    line-height: 1em;
  }
}
@media (max-width: 640px) {
  .introPopup__header {
    font-size: 1.375rem;
    line-height: 1em;
    padding-right: 0.5em;
  }
}
.introPopup__header2 {
  color: white;
  font-size: 2.75rem;
  font-weight: 300;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .introPopup__header2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 640px) {
  .introPopup__header2 {
    font-size: 1.125rem;
    padding-right: 0.5em;
  }
}
.introPopup__closeButton {
  background-color: transparent;
  border: none;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 200;
}
.introPopup__closeButton:hover {
  color: rgb(201, 201, 201);
}
.introPopup__content-open {
  padding-right: 2em;
}
@media (max-width: 640px) {
  .introPopup__content-open {
    padding-right: 0.5em;
  }
}
.introPopup__content-open::-webkit-scrollbar {
  width: 0.75em;
}
.introPopup__content-open::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(200, 200, 200, 0.1);
  border-radius: 10px;
}
.introPopup__content-open::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200);
  outline: 1px solid slategrey;
  border-radius: 10px;
}
.introPopup__bodyHeader {
  position: relative;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  line-height: 1.125em;
  color: white;
  padding-bottom: 1.5em;
}
@media (max-width: 640px) {
  .introPopup__bodyHeader {
    font-size: 0.875rem;
  }
}
.introPopup__bodyHeader b {
  font-weight: 700;
}
.introPopup__bodyHeader-blue {
  color: #3d7cca;
}
.introPopup__trianglePic {
  width: 25px;
  height: auto;
  position: absolute;
  top: -3%;
  left: -35px;
}
.introPopup__teardropPic {
  width: 25px;
  height: auto;
  position: absolute;
  top: 3%;
  left: -35px;
}/*# sourceMappingURL=Popups.css.map */
/* Overlay.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 23, 67, 0.9058823529);
  opacity: 0.75;
  z-index: 999; /* Make sure it's on top of other elements */
}/*# sourceMappingURL=Overlay.css.map */
.jobZones {
  width: 850px;
  padding-bottom: 10em;
  padding-top: 2em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .jobZones {
    width: 90%;
  }
}
@media (max-width: 640px) {
  .jobZones {
    width: 78%;
    font-family: proxima-nova, sans-serif;
  }
}
.jobZones__topBox {
  margin-bottom: 1em;
  padding: 1em 3em 1em;
  background-color: #645DC7;
  border: none;
  border-radius: 5px;
}
@media (max-width: 640px) {
  .jobZones__topBox {
    padding: 1em 1em 1em;
  }
}
.jobZones__topBox h1 {
  font-weight: 700;
  font-family: proxima-nova, sans-serif;
}
@media (max-width: 640px) {
  .jobZones__topBox h1 {
    font-size: 0.875rem;
  }
}
.jobZones__topBox li {
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
  margin: 0.5em 1.5em 0.5em;
  list-style-type: disc;
}
@media (max-width: 640px) {
  .jobZones__topBox li {
    font-size: 0.75rem;
  }
}
.jobZones__topBox span {
  position: relative;
  left: -0.375em;
}
.jobZones__topBox p {
  font-family: proxima-nova, sans-serif;
}
@media (max-width: 640px) {
  .jobZones__topBox p {
    font-size: 0.875rem;
  }
}
.jobZones__main {
  margin-top: 10px;
  padding-left: 10px;
}
.jobZones__mainBox {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}
@media (max-width: 640px) {
  .jobZones__mainBox {
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.jobZones__mainIcons {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.jobZones__circularTextSpan {
  position: absolute;
  font-size: 0.75rem;
  font-weight: 700;
  color: #AAD2F0;
  font-family: proxima-nova, sans-serif;
}
.jobZones__circularText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: proxima-nova, sans-serlf;
  font-size: 30px;
  background-color: #635DC6;
  border: 3px solid #AAD2F0;
  border-radius: 100%;
  padding: 25px;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}
@media (max-width: 640px) {
  .jobZones__circularText {
    padding: 20px;
    font-size: 1.25rem;
  }
}
.jobZones__midLeftLine {
  width: 25px;
  margin-left: 5px;
  margin-right: 5px;
}
.jobZones__midLeftText {
  font-family: proxima-nova, sans-serif;
  padding-top: 20px;
}
@media (max-width: 640px) {
  .jobZones__midLeftText {
    padding-top: 0em;
    font-size: 1rem;
  }
}
.jobZones__midLeftText h1 {
  color: #635DC6;
  font-weight: 700;
  font-size: 1rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .jobZones__midLeftText h1 {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .jobZones__midLeftText h1 {
    font-size: 1.125rem;
    margin-left: 1em;
    margin-bottom: -0.125em;
  }
}
.jobZones__midLeftText h1 i {
  font-style: italic;
}
.jobZones__midLeftText p {
  color: #B5BDCC;
  margin-top: 3px;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.jobZones__midLeftText p b {
  font-weight: 600;
  color: white;
}
.jobZones__belowText {
  font-family: proxima-nova, sans-serif;
  padding-top: 20px;
  margin-left: -0.8em;
}
@media (max-width: 640px) {
  .jobZones__belowText {
    padding-top: 0.75em;
    padding-bottom: 1em;
    font-size: 1rem;
  }
}
.jobZones__belowText h1 {
  color: #635DC6;
  font-weight: 700;
  font-size: 1rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .jobZones__belowText h1 {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .jobZones__belowText h1 {
    font-size: 1rem;
    margin-left: 1em;
  }
}
.jobZones__belowText h1 i {
  font-style: italic;
}
.jobZones__belowText p {
  color: #B5BDCC;
  margin-top: 3px;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-bottom: 0.5em;
}
.jobZones__belowText p b {
  font-weight: 600;
  color: white;
}/*# sourceMappingURL=JobZones.css.map */
.occupationGrid {
  max-width: 850px;
  margin-top: 0em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .occupationGrid {
    width: 525px;
  }
}
@media (max-width: 640px) {
  .occupationGrid {
    width: 270px;
  }
}
.occupationGrid__container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding-top: 2em;
  padding-bottom: 2em;
}
.occupationGrid__topRow {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.occupationGrid__topRow h1 {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.25em;
}
.occupationGrid__topRow h2 {
  color: #7B51A1;
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
}
@media (max-width: 640px) {
  .occupationGrid__topRow h2 {
    text-align: left;
  }
}
.occupationGrid__extras {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
}
@media (max-width: 640px) {
  .occupationGrid__extras {
    font-size: 0.75rem;
  }
}
.occupationGrid__extraRow {
  display: flex;
  flex-flow: row nowrap;
  margin: 5px 0px 5px;
}
.occupationGrid__extraElement {
  background-color: #003c78;
  margin-right: 5px;
  align-self: flex-start;
  padding: 3px 6px 3px;
}
.occupationGrid__extraElement-pic {
  padding: 0px 0px 0px;
  background-color: transparent;
}
.occupationGrid__extraText {
  color: #7398D4;
  padding: 3px 6px 3px;
}
.occupationGrid__pic {
  width: 20px;
}
.occupationGrid__pic-bottom {
  margin-right: 0.5em;
}
.occupationGrid__endMatter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 2em;
  font-size: 0.875rem;
  font-family: proxima-nova, sans-serif;
  color: #77a3da;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .occupationGrid__endMatter {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .occupationGrid__endMatter {
    font-size: 0.625rem;
  }
}/*# sourceMappingURL=OccupationGrid.css.map */
.gridTableHead__thead {
  border-bottom: 2px solid white;
}
.gridTableHead__tr {
  border-bottom: 2px solid white;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.gridTableHead__th {
  border-radius: 4px;
  background-color: #003c78;
  padding: 5px 2px 4px;
  text-align: center;
  vertical-align: middle;
  font-family: proxima-nova, sans-serif;
  font-size: 0.825rem;
  min-width: 75px;
  max-width: 75px;
  position: relative;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTableHead__th {
    min-width: 78px;
    max-width: 78px;
  }
}
@media (max-width: 640px) {
  .gridTableHead__th {
    font-size: 0.75rem;
    min-width: 22px;
    min-width: 40px;
    max-width: 40px;
  }
}
.gridTableHead__th:hover {
  cursor: pointer;
  background-color: #3d7cca;
}
.gridTableHead__th-jobName {
  min-width: 120px;
  max-width: 120px;
}
.gridTableHead__th-careerName {
  min-width: 120px;
  max-width: 120px;
}
.gridTableHead__th-mobileJobZone {
  min-width: 20px;
  max-width: 20px;
}
.gridTableHead__th-mobileFit {
  min-width: 20px;
  max-width: 20px;
}
.gridTableHead__th-mobileTrifectaScore {
  min-width: 20px;
  max-width: 20px;
}
.gridTableHead__th-mobileOutlook {
  min-width: 18px;
  max-width: 18px;
}
.gridTableHead__th-mobileJobName {
  min-width: 70px;
  max-width: 70px;
}
.gridTableHead__th-mobileCareerName {
  min-width: 70px;
  max-width: 70px;
}
.gridTableHead__tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.gridTableHead__th:hover .gridTableHead__tooltip {
  visibility: visible;
  opacity: 1;
}
.gridTableHead__headerSeparation {
  height: 2px;
  background-color: white;
  border: none;
}/*# sourceMappingURL=GridTableHead.css.map */
.gridTableBody__tbody {
  font-family: proxima-nova, sans-serif;
  font-size: 0.75rem;
}
.gridTableBody__td {
  vertical-align: middle;
  padding: 5px 0px 5px;
  min-width: 75px;
  max-width: 75px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTableBody__td {
    min-width: 78px;
    max-width: 78px;
  }
}
@media (max-width: 640px) {
  .gridTableBody__td {
    min-width: 40px;
    max-width: 40px;
    font-size: 0.625rem;
  }
}
.gridTableBody__td-centered {
  text-align: center;
  vertical-align: middle;
}
.gridTableBody__td-fit {
  font-weight: 600;
}
.gridTableBody__td-single {
  color: #546789;
}
.gridTableBody__td-careerName {
  min-width: 120px;
  max-width: 120px;
}
.gridTableBody__td-mobileJobZone {
  min-width: 20px;
  max-width: 20px;
}
.gridTableBody__td-mobileFit {
  min-width: 20px;
  max-width: 20px;
}
.gridTableBody__td-mobileTrifectaScore {
  min-width: 20px;
  max-width: 20px;
}
.gridTableBody__td-mobileOutlook {
  min-width: 18px;
  max-width: 18px;
}
.gridTableBody__td-mobileCareerName {
  min-width: 70px;
  max-width: 70px;
}
.gridTableBody__headerSeparation {
  height: 1px;
  background-color: #546789;
  border: none;
}
.gridTableBody__button {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: white;
  text-align: left;
  line-height: 1.25em;
}
.gridTableBody__button:hover {
  color: #009ade;
}
.gridTableBody__button-jobName {
  min-width: 120px;
  max-width: 120px;
}
.gridTableBody__button-mobileJobName {
  min-width: 70px;
  max-width: 70px;
  line-height: 1.25em;
}
.gridTableBody__pic {
  width: 20px;
}
@media (max-width: 640px) {
  .gridTableBody__pic {
    width: 14px;
  }
}
.gridTableBody__pic-1-star {
  width: 10px;
  height: auto;
}
@media (max-width: 640px) {
  .gridTableBody__pic-1-star {
    width: 7px;
  }
}/*# sourceMappingURL=GridTableBody.css.map */
.job-zones {
  margin-top: 35px;
}

.job-zones__h1 {
  font-size: 40px;
  font-weight: 600;
  margin-left: 75px;
  margin-right: 75px;
  color: #004E5A;
  font-family: garamond-premier-pro, serif;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.job-zones__outer-box {
  background-color: #635DC6;
  margin-top: 15px;
  padding-bottom: 35px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
  margin-left: 125px;
}
@media (min-width: 768px) {
  .triangle {
    margin-left: 250px;
  }
}
.triangle-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.job-zones__box {
  padding-top: 5px;
  color: white;
  font-family: proxima-nova, sans-serif;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.job-zones__box h1 {
  font-size: 16px;
  font-weight: 700;
  padding-top: 10px;
}
@media (min-width: 768px) {
  .job-zones__box h1 {
    font-size: 24px;
  }
}

.job-zones__box p {
  padding-top: 10px;
  font-size: 14px;
}
@media (min-width: 768px) {
  .job-zones__box p {
    font-size: 22px;
  }
}

.bullets {
  list-style-type: disc;
  margin-left: 35px;
}

.bullets li {
  padding-top: 10px;
  font-size: 12px;
}
@media (min-width: 768px) {
  .bullets li {
    font-size: 18px;
  }
}

.job-zones__mid {
  margin-top: 20px;
  display: flex;
  flex-flow: row nowrap;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .job-zones__mid {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 640px) {
  .job-zones__mid {
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}

.job-zones__mid-right {
  width: 500px;
}

.job-zones__mid-right-pic {
  float: right;
  width: 55%;
  margin-right: -3em;
  align-self: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .job-zones__mid-right-pic {
    width: 50%;
    margin-right: 0em;
  }
}
@media (max-width: 640px) {
  .job-zones__mid-right-pic {
    float: none;
    width: 300px;
  }
}

.job-zones__bottom {
  padding-left: 75px;
  padding-right: 75px;
  font-size: 40px;
  font-weight: 600;
  color: #004E5A;
  font-family: garamond-premier-pro, serif;
  text-align: center;
  margin-top: 1.875em;
  margin-bottom: 1.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .job-zones__bottom {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
@media (max-width: 640px) {
  .job-zones__bottom {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.job-zones__mid-left {
  margin-top: 10px;
  padding-left: 10px;
}

.job-zones__mid-left-box {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

.job-zones__mid-left-icons {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.circular-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: proxima-nova, sans-serlf;
  font-size: 30px;
  background-color: #635DC6;
  border: 3px solid #AAD2F0;
  border-radius: 100%;
  padding: 25px;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.circular-text-span {
  position: absolute;
  font-size: 10px;
  color: #AAD2F0;
  font-family: proxima-nova, sans-serif;
}

.job-zones__mid-left-line {
  width: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

.job-zones__mid-left-text {
  font-family: proxima-nova, sans-serif;
  padding-top: 20px;
}

.job-zones__mid-left-text h1 {
  color: #635DC6;
  font-weight: 700;
  font-size: 1rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .job-zones__mid-left-text h1 {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .job-zones__mid-left-text h1 {
    font-size: 0.75rem;
  }
}

.job-zones__mid-left-text h1 i {
  font-style: italic;
}

.job-zones__mid-left-text p {
  color: #808080;
  margin-top: 3px;
  font-weight: 300;
  font-size: 0.75rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .job-zones__mid-left-text p {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .job-zones__mid-left-text p {
    font-size: 0.75rem;
  }
}

.job-zones__mid-left-text p b {
  font-weight: 600;
}

.top-jobs {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.top-jobs__outer-box {
  background-color: #635DC6;
  margin-top: 15px;
  padding-bottom: 35px;
  width: 100%;
}
.top-jobs__boundingBox {
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}
.top-jobs__boxBox {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  line-height: 1.25em;
}
.top-jobs__boxBox b {
  font-weight: 700;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__boxBox {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .top-jobs__boxBox {
    font-size: 0.875rem;
    flex-flow: column nowrap;
    align-items: center;
  }
}
.top-jobs__box-left {
  width: 50%;
}
@media (max-width: 640px) {
  .top-jobs__box-left {
    width: 100%;
  }
}
.top-jobs__box-right {
  width: 50%;
}
@media (max-width: 640px) {
  .top-jobs__box-right {
    width: 100%;
  }
}

.top-jobs h1 {
  font-size: 40px;
  font-weight: 600;
  margin-left: 75px;
  margin-right: 75px;
  color: #004E5A;
  font-family: garamond-premier-pro, serif;
  text-align: center;
}

.top-jobs__vertical-line {
  width: 40px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  transform: rotate(90deg);
}

.outer-1 {
  background: radial-gradient(circle at center, #8A5C98 50%, #BF7FD2 100%);
  width: 300px; /* You can define it by % also */
  height: 300px; /* You can define it by % also*/
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 0px 15px gray;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .outer-1 {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 640px) {
  .outer-1 {
    font-size: 0.75rem;
  }
}
.outer-1-0 {
  background: radial-gradient(circle at center, #8A5C98 50%, #BF7FD2 100%);
}
.outer-1-1 {
  background: radial-gradient(circle at center, #335E9B 50%, #3C6FB6 100%);
}
.outer-1-2 {
  background: radial-gradient(circle at center, #8A5C98 50%, #BF7FD2 100%);
}

.inner-1 {
  background: radial-gradient(circle at center, #335E9B 50%, #3C6FB6 100%);
  top: 2.5%;
  left: 2.5%; /* of the container */
  width: 95%; /* of the outer-1 */
  height: 95%; /* of the outer-1 */
  position: absolute;
  border-radius: 50%;
}
.inner-1-0 {
  background: radial-gradient(circle at center, #0A8691 50%, #118A93 100%);
  top: 3.5%;
  left: 3.5%;
  width: 93%;
  height: 93%;
}
.inner-1-1 {
  background: radial-gradient(circle at center, #0A8691 50%, #118A93 100%);
  top: 3.5%;
  left: 3.5%;
  width: 93%;
  height: 93%;
}
.inner-1-2 {
  background: radial-gradient(circle at center, #335E9B 50%, #3C6FB6 100%);
  top: 3.5%;
  left: 3.5%;
  width: 93%;
  height: 93%;
}

.inner-2 {
  background: radial-gradient(circle at center, #0A8691 50%, #118A93 100%);
  top: 5%;
  left: 5%; /* of the container */
  width: 90%; /* of the inner-1 */
  height: 90%; /* of the inner-1 */
  position: absolute;
  border-radius: 50%;
}

.inner-3 {
  background-color: white;
  top: 7.5%;
  left: 7.5%; /* of the container */
  width: 85%; /* of the inner-1 */
  height: 85%; /* of the inner-1 */
  position: absolute;
  border-radius: 50%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.top-jobs__trifecta-list {
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: #397CC9;
  font-weight: 600;
  text-align: center;
  width: 70%;
  height: 175px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: absolute;
  top: 16%;
  padding-left: 0.125em;
  padding-right: 0.125em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__trifecta-list {
    font-size: 0.875rem;
    top: 15%;
    height: 120px;
  }
}
@media (max-width: 640px) {
  .top-jobs__trifecta-list {
    font-size: 0.75rem;
  }
}

.top-jobs__trifecta-list-item {
  padding-top: 0.375em;
  padding-bottom: 0.375em;
}

.top-jobs__trifecta-list-item__button {
  background-color: transparent;
  border: none;
}
.top-jobs__trifecta-list-item__button:hover {
  color: rgb(160, 160, 160);
}

.top-jobs__h2 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  color: #901DB4;
}

.top-jobs__many-lines {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  width: 695px;
  height: 100px;
  position: relative;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__many-lines {
    width: 420px;
  }
}
@media (max-width: 640px) {
  .top-jobs__many-lines {
    width: 0px;
    height: 0px;
  }
}

.top-jobs__vertical-line-bottom {
  position: absolute;
  left: 0%;
  top: 10%;
  width: 695px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__vertical-line-bottom {
    width: 420px;
  }
}
@media (max-width: 640px) {
  .top-jobs__vertical-line-bottom {
    width: 0px;
    height: 0px;
  }
}
.top-jobs__vertical-line-bottom-left {
  width: 40px;
  transform: rotate(90deg);
  position: absolute;
  left: -3%;
  top: 30%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__vertical-line-bottom-left {
    left: -4.5%;
  }
}
@media (max-width: 640px) {
  .top-jobs__vertical-line-bottom-left {
    width: 0px;
    height: 0px;
  }
}
.top-jobs__vertical-line-bottom-center {
  width: 40px;
  transform: rotate(90deg);
  position: absolute;
  left: 47.5%;
  top: 30%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__vertical-line-bottom-center {
    left: 45.5%;
  }
}
@media (max-width: 640px) {
  .top-jobs__vertical-line-bottom-center {
    width: 0px;
    height: 0px;
  }
}
.top-jobs__vertical-line-bottom-right {
  width: 40px;
  transform: rotate(90deg);
  position: absolute;
  left: 97%;
  top: 30%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__vertical-line-bottom-right {
    left: 95.5%;
  }
}
@media (max-width: 640px) {
  .top-jobs__vertical-line-bottom-right {
    width: 0px;
    height: 0px;
  }
}

.top-jobs__overlaps {
  display: flex;
  flex-flow: row;
  width: 1000px;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__overlaps {
    width: 650px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 640px) {
  .top-jobs__overlaps {
    flex-flow: column nowrap;
    width: 300px;
  }
}

.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}

.modal-backdrop {
  opacity: 0.5 !important;
}

.top-jobs__modal {
  width: 1000px;
  max-width: none;
  background-color: transparent;
  border: none;
  outline: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .top-jobs__modal {
    width: 625px;
  }
}
@media (max-width: 640px) {
  .top-jobs__modal {
    width: 310px;
  }
}

.top-jobs__modal-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
}

.close {
  color: #fff;
  opacity: 1;
  font-weight: 300;
}

.top-jobs__modal-header {
  background-color: #1A2B56;
  border: 1px solid #1A2B56;
  border-radius: 0px;
  color: white;
  padding-bottom: 0px;
}

.top-jobs__modal-title {
  font-family: proxima-nova, sans-serif;
  padding: 5px;
  padding-bottom: 0px;
}

.top-jobs__modal-title h2 {
  font-size: 16px;
  color: #BC77D2;
}

.top-jobs__modal-title h1 {
  font-size: 24px;
  color: white;
}

.top-jobs__modal-title h3 {
  font-size: calc(12px + 2px);
  font-weight: 400;
  color: white;
  padding-bottom: 15px;
}

.top-jobs__modal-body {
  background-color: #1A2B56;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: proxima-nova, sans-serif;
}
.top-jobs__modal-hor-line {
  margin: 0px;
  padding: 0px;
  border: none;
}

.top-jobs__modal-main-body {
  background-color: white;
  margin-top: 0px;
  padding-top: 0px;
  border: none;
  padding-left: 0.5em;
  display: flex;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
@media (max-width: 640px) {
  .top-jobs__modal-main-body {
    display: flex;
    flex-flow: column nowrap;
  }
}

.top-jobs__body-column {
  display: flex;
  flex-flow: column;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 100%;
}
@media (max-width: 640px) {
  .top-jobs__body-column {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0.25em;
    padding-right: 0.25em;
    margin-bottom: 0em;
  }
}

.top-jobs__section-title {
  color: black;
  font-size: 16px;
  font-weight: 800;
  margin-top: 20px;
}
@media (max-width: 640px) {
  .top-jobs__section-title {
    margin-top: 20px;
  }
}

.top-jobs__bullets {
  font-size: 12px;
  font-weight: 500;
  list-style-type: disc;
  margin-left: 17px;
}

.top-jobs__bullet-item {
  margin-top: 10px;
}

.top-jobs__bullet-item b {
  font-weight: 700;
}

.top-jobs__bullet-item span {
  color: #808080;
}

.top-jobs__body-line {
  width: 1px;
  height: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px;
  background-color: black;
  align-self: center;
}
@media (max-width: 640px) {
  .top-jobs__body-line {
    height: 0;
    margin: 0;
    width: 0;
    padding: 0;
  }
}

.top-jobs__riasec-section {
  margin-top: 30px;
  font-size: 16px;
  height: 100%;
}

.top-jobs__riasec-item {
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: left;
  color: #001343;
  font-weight: 800;
}

.top-jobs__riasec-item-gray {
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: left;
  color: #999999;
  font-weight: 600;
}

.top-jobs__link {
  color: #901DB4;
  display: flex;
  flex-flow: row;
  justify-content: right;
}
@media (max-width: 640px) {
  .top-jobs__link {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
  }
}

.top-jobs__smallText {
  color: #b3b3b3;
  text-align: left;
  font-size: 0.625rem;
  width: 100%;
}
@media (max-width: 640px) {
  .top-jobs__smallText {
    margin-top: 0.125em;
  }
}

@media (max-width: 640px) {
  .top-jobs__arrowText {
    margin-left: 0.875em;
  }
}

.fa-angle-up {
  font-size: 2rem;
  color: #3d7cca;
  position: absolute;
  top: 0%;
  left: 46.5%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .fa-angle-up {
    top: -3.5%;
    left: 44%;
  }
}
.fa-angle-up:hover {
  color: #163c66;
}

.fa-angle-down {
  font-size: 2rem;
  color: #3d7cca;
  position: absolute;
  top: 87%;
  left: 46.5%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .fa-angle-down {
    top: 85%;
    left: 44%;
  }
}
.fa-angle-down:hover {
  color: #163c66;
}

.topJobs__listButton {
  background-color: transparent;
  border: none;
}
.top-jobs__alternate {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 2em;
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}/*# sourceMappingURL=TopJobs.css.map */
.gridTablePagination {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 20px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTablePagination {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .gridTablePagination {
    font-size: 0.75rem;
  }
}
.gridTablePagination__button {
  background-color: transparent;
  border: none;
  color: white;
  margin: 0px 10px 0px;
  text-decoration: underline;
  font-family: proxima-nova, sans-serif;
}
.gridTablePagination__button:hover {
  color: #009ade;
}
.gridTablePagination__button-disabled {
  color: #546789;
  text-decoration: none;
}
.gridTablePagination__button-disabled:hover {
  color: #546789;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTablePagination__button {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .gridTablePagination__button {
    font-size: 0.75rem;
    margin: 0px 2px 0px;
  }
}
.gridTablePagination__span {
  font-family: proxima-nova, sans-serif;
  color: white;
  padding: 0.125em 0.375em;
  border: none;
  border-radius: 3px;
}
.gridTablePagination__span:hover {
  cursor: pointer;
  color: #009ade;
  text-decoration: underline;
}
.gridTablePagination__span-active {
  background-color: #546789;
}
.gridTablePagination__span-disabled:hover {
  cursor: auto;
  color: white;
  text-decoration: none;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTablePagination__span {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .gridTablePagination__span {
    font-size: 0.75rem;
  }
}
.gridTablePagination__text {
  font-family: proxima-nova, sans-serif;
  align-self: center;
  margin-left: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTablePagination__text {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .gridTablePagination__text {
    font-size: 0.75rem;
  }
}
.gridTablePagination__input {
  width: 40px;
  margin-left: 0.5em;
  font-size: 0.875rem;
  padding: 0em 0.125em 0em;
  font-family: proxima-nova, sans-serif;
  height: 100%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTablePagination__input {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .gridTablePagination__input {
    font-size: 0.75rem;
  }
}
.gridTablePagination__goButton {
  width: 30px;
  margin-left: 0.5em;
  font-size: 0.875rem;
  font-family: proxima-nova, sans-serif;
  border: none;
  background-color: #e6e6e6;
  color: black;
  height: 100%;
}
.gridTablePagination__goButton:hover {
  background-color: #cccccc;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTablePagination__goButton {
    font-size: 0.75rem;
  }
}
@media (max-width: 640px) {
  .gridTablePagination__goButton {
    font-size: 0.75rem;
  }
}
.gridTablePagination__arrow {
  fill: white;
  width: 22px;
  height: auto;
  margin-top: 0em;
}
.gridTablePagination__arrow:hover {
  fill: #009ade;
}
.gridTablePagination__arrow-disabled {
  fill: #546789;
  text-decoration: none;
}
.gridTablePagination__arrow-disabled:hover {
  fill: #546789;
}/*# sourceMappingURL=GridTablePagination.css.map */
.gridTable {
  border-collapse: separate;
  border-spacing: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.gridTable__container {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.gridTable__bodyUnoccupied {
  width: 99%;
  height: 400px;
  background-color: #163c66;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .gridTable__bodyUnoccupied {
    width: 103.5%;
  }
}/*# sourceMappingURL=GridTable.css.map */
.ikigai {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 3em;
}
.ikigai__header {
  color: #001343;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  font-size: 2.75rem;
  margin-bottom: 0.3em;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
}
.ikigai__header-left {
  text-align: left;
  margin-left: 0%;
  margin-right: 0%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ikigai__header {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  .ikigai__header {
    font-size: 2rem;
  }
}
.ikigai__outerBox {
  background-color: #663190;
  margin-top: 1em;
  padding-bottom: 2.5em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
}
.ikigai__triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
  margin-top: -1px;
  margin-bottom: 20px;
}
.ikigai__body {
  font-family: proxima-nova, sans-serif;
  font-size: 1.15rem;
  line-height: 1.35rem;
  font-weight: 400;
  max-width: 1200px;
}
.ikigai__body-white {
  color: white;
  padding-left: 1em;
  padding-right: 1em;
}
.ikigai__body-gray {
  color: #4d4d4d;
  font-weight: 400;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ikigai__body {
    font-size: 0.875rem;
    line-height: 1.1rem;
  }
}
@media (max-width: 640px) {
  .ikigai__body {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
}
.ikigai__main {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 5.5em;
  padding-right: 5.5em;
  padding-bottom: 2em;
  padding-top: 2em;
  max-width: 1200px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ikigai__main {
    padding-right: 5em;
  }
}
@media (max-width: 640px) {
  .ikigai__main {
    flex-flow: column nowrap;
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
    padding-left: 5%;
    padding-right: 5%;
  }
}
.ikigai__pic {
  width: 500px;
  height: 450px;
  margin-right: 5%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ikigai__pic {
    max-width: 100%;
    height: 300px;
  }
}
@media (max-width: 640px) {
  .ikigai__pic {
    width: 275px;
    height: 275px;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.ikigai__explanation {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.maslow {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: #001343;
  padding-top: 3em;
  padding-bottom: 3em;
  padding-left: 1em;
  padding-right: 1em;
}
.maslow__content {
  width: 100%;
  max-width: 1200px;
}
.maslow__header {
  color: white;
  font-family: garamond-premier-pro, serif;
  font-weight: 800;
  font-size: 2.75rem;
  margin-bottom: 0.25em;
  width: 100%;
  text-align: left;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .maslow__header {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  .maslow__header {
    font-size: 1.75rem;
  }
}
.maslow__body {
  font-family: proxima-nova, sans-serif;
  font-size: 1.15rem;
  line-height: 1.35rem;
  font-weight: 400;
}
.maslow__body-white {
  color: white;
}
.maslow__body-gray {
  color: #4d4d4d;
  font-weight: 500;
}
.maslow__body-blue {
  color: #77a3da;
  margin-left: 2em;
}
.maslow__body-indent {
  margin-left: 3em;
}
.maslow__body-bold {
  font-weight: 600;
  margin-top: 0.6em;
  margin-left: 2em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .maslow__body {
    font-size: 0.875rem;
    line-height: 1.1rem;
  }
}
@media (max-width: 640px) {
  .maslow__body {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
}
.maslow__box {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-top: 1.75em;
  margin-bottom: 2.125em;
}
.maslow__needs {
  color: white;
  width: 100%;
  vertical-align: middle;
  align-self: center;
}
.maslow__pic {
  float: right;
  width: 50%;
  max-width: 750px;
  min-width: 550px;
  shape-outside: url(/static/media/science-maslow-full.569145a1.png);
  shape-margin: 2rem;
  margin-left: 2rem;
  align-self: flex-end;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .maslow__pic {
    max-width: 460px;
    min-width: 325px;
    width: 70%;
    padding-bottom: 1rem;
  }
}
@media (max-width: 640px) {
  .maslow__pic {
    width: 90%;
    min-width: 225px;
    float: none;
    display: flow-root;
    margin-bottom: 2.5em;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .maslow {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}
@media (max-width: 640px) {
  .maslow {
    padding-left: 2rem;
  }
}

.holland {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 3em;
  padding-left: 1em;
  padding-right: 1em;
}
.holland__content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.holland__header {
  color: #001343;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  font-size: 2.75rem;
  margin-bottom: 0.5em;
  width: 100%;
  text-align: left;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .holland__header {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  .holland__header {
    font-size: 1.75rem;
  }
}
.holland__columns {
  columns: 2 200px;
  -moz-column-gap: 1.5em;
       grid-column-gap: 1.5em;
       column-gap: 1.5em;
  margin-bottom: 2em;
}
.holland__body {
  font-family: proxima-nova, sans-serif;
  font-size: 1.15rem;
  line-height: 1.35rem;
  font-weight: 400;
  margin-bottom: 1em;
}
.holland__body-white {
  color: white;
}
.holland__body-gray {
  color: #4d4d4d;
  font-weight: 400;
}
.holland__body-blue {
  color: #77a3da;
}
.holland__body-indent {
  margin-left: 0.625em;
}
.holland__body-bold {
  font-weight: 600;
  margin-top: 0.6em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .holland__body {
    font-size: 0.875rem;
    line-height: 1.1rem;
  }
}
@media (max-width: 640px) {
  .holland__body {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
}
.holland__pic {
  width: 100%;
  min-width: 550px;
  margin-bottom: 2.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .holland__pic {
    min-width: 325px;
    width: 100%;
  }
}
@media (max-width: 640px) {
  .holland__pic {
    width: 100%;
    min-width: 300px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .holland {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}
@media (max-width: 640px) {
  .holland {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}

.onet {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: #001343;
  padding-top: 3em;
  padding-left: 1em;
  padding-right: 1em;
}
.onet__content {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 3em;
}
.onet__header {
  color: white;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  font-size: 2.75rem;
  margin-bottom: 0.5em;
  width: 100%;
  text-align: left;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .onet__header {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  .onet__header {
    font-size: 1.75rem;
  }
}
.onet__box {
  color: white;
  width: 100%;
  vertical-align: middle;
  align-self: center;
}
.onet__body {
  font-family: proxima-nova, sans-serif;
  font-size: 1.15rem;
  line-height: 1.35rem;
  font-weight: 400;
  margin-bottom: 1em;
}
.onet__body-white {
  color: white;
}
.onet__body-gray {
  color: #4d4d4d;
  font-weight: 500;
}
.onet__body-blue {
  color: #77a3da;
}
.onet__body-indent {
  margin-left: 0.625em;
}
.onet__body-bold {
  font-weight: 600;
  margin-top: 0.6em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .onet__body {
    font-size: 0.875rem;
    line-height: 1.1rem;
  }
}
@media (max-width: 640px) {
  .onet__body {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
}
.onet__pic {
  float: right;
  display: flow-root;
  width: 250px;
  shape-outside: url(/static/media/science-onet.fc6bc1d7.png);
  shape-margin: 1.5rem;
  margin-left: 1.5rem;
  margin-top: 9em;
  margin-right: 1em;
  display: flow-root;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .onet__pic {
    width: 200px;
    margin-top: 7.5em;
  }
}
@media (max-width: 640px) {
  .onet__pic {
    width: 150px;
    margin-top: 16em;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .onet {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}
@media (max-width: 640px) {
  .onet {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}

.statistics {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 3em;
  padding-left: 1em;
  padding-right: 1em;
}
.statistics__pic {
  width: 100%;
  max-width: 1200px;
}
.statistics__pic-normal {
  width: 100%;
  max-width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .statistics__pic-normal {
    width: 90%;
    margin-top: 2rem;
  }
}
@media (max-width: 640px) {
  .statistics__pic-normal {
    width: 90%;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
.statistics__pic-float {
  float: left;
  width: 80%;
  max-width: 665px;
  min-width: 325px;
  margin-left: -5.75em;
  margin-right: 1em;
  margin-bottom: 2em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .statistics__pic-float {
    width: 65%;
    margin-left: -4em;
  }
}
@media (max-width: 640px) {
  .statistics__pic-float {
    width: 100%;
    margin-left: 0em;
  }
}
.statistics__header {
  color: #001343;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  font-size: 2.75rem;
  width: 100%;
  text-align: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .statistics__header {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  .statistics__header {
    font-size: 1.75rem;
  }
}
.statistics__content {
  width: 80%;
  max-width: 1200px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .statistics__content {
    width: 90%;
  }
}
@media (max-width: 640px) {
  .statistics__content {
    width: 90%;
  }
}
.statistics__section {
  columns: 2 200px;
  margin-top: 2em;
  margin-bottom: 2em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .statistics__section {
    margin-top: 1rem;
  }
}
@media (max-width: 640px) {
  .statistics__section {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
  }
}
.statistics__body {
  font-family: proxima-nova, sans-serif;
  font-size: 1.15rem;
  line-height: 1.35rem;
  font-weight: 400;
  color: #808080;
  margin-bottom: 1em;
}
.statistics__body-white {
  color: white;
}
.statistics__body-gray {
  color: #4d4d4d;
  font-weight: 500;
}
.statistics__body-blue {
  color: #77a3da;
}
.statistics__body-indent {
  margin-left: 0.625em;
}
.statistics__body-bold {
  font-weight: 600;
  margin-bottom: 0em;
}
.statistics__body-botMargin {
  margin-bottom: 0.75em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .statistics__body {
    font-size: 0.875rem;
    line-height: 1.1rem;
  }
}
@media (max-width: 640px) {
  .statistics__body {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
}
.statistics__riasec {
  columns: 2;
  margin-bottom: 1em;
  margin-left: 1.25em;
}
@media (max-width: 640px) {
  .statistics__riasec {
    margin-bottom: 0em;
  }
}
.statistics__item {
  margin-bottom: 0.75em;
}
.statistics__hr {
  border: none;
  border-top: 2px dotted black;
  background-color: white;
  color: white;
  opacity: 0.25;
  margin-bottom: 3em;
}
@media (max-width: 640px) {
  .statistics__hr {
    margin-bottom: 2em;
  }
}
@media (max-width: 640px) {
  .statistics__bottom {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
}/*# sourceMappingURL=Science.css.map */
.signin {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 25px;
  padding-top: 100px;
}
.signin__header {
  font-size: calc(32px + 2vmin);
  font-family: proxima-nova, sans-serif;
  color: #004E59;
  font-weight: 700;
  text-align: center;
  line-height: calc(40px + 2vmin);
  padding-bottom: 10px;
}
.signin__subheader {
  font-size: calc(12px + 2vmin);
  font-family: proxima-nova, sans-serif;
  color: #b3b3b3;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: calc(15px + 2.5vmin);
}
.signin__link {
  color: #919191;
  text-decoration: underline;
}
.signin__link:hover {
  color: #707070;
}
.signin__form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.signin__input {
  background-color: #E8E8E8;
  border-style: none;
  font-size: calc(12px + 1.5vmin);
  font-family: proxima-nova, sans-serif;
  color: #616161;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  align-self: center;
}
.signin__input::placeholder {
  color: #a0a0a0;
}
.signin__input-password {
  margin-top: 1em;
}
.signin__error {
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  text-align: center;
  color: red;
  position: absolute;
  left: 2%;
  right: 2%;
}
.signin__errorMessage {
  position: absolute;
  left: 2%;
  right: 2%;
  top: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signin__errorMessage {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .signin__errorMessage {
    font-size: 0.7rem;
  }
}
.signin__options {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1.5em;
}
@media (max-width: 640px) {
  .signin__options {
    font-size: 12px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signin__options {
    font-size: 16px;
  }
}
.signin__selections {
  font-family: proxima-nova, sans-serif;
  color: #b3b3b3;
  flex-grow: 2;
}
.signin__option {
  margin-bottom: 0.5em;
}
.signin__icon {
  display: flex;
  flex: 1 1;
  background-color: #004e59;
  align-self: stretch;
}
.signin__forgot {
  background-color: transparent;
  border: none;
  color: #AFAFAF;
  text-decoration: underline;
  text-align: left;
}
.signin__forgot:hover {
  color: black;
}
.signin__button-box {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.signin__button {
  background-color: #008375;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 4px;
  width: auto;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.signin__arrow {
  font-size: 2.5rem;
}
.signin__exclamation {
  height: 1em;
  margin-right: 0.125em;
}/*# sourceMappingURL=SignIn.css.map */
.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  padding-top: calc(20px + 4vmin);
}
.signup__header {
  font-size: calc(32px + 2vmin);
  font-family: proxima-nova, sans-serif;
  color: #004E59;
  font-weight: 700;
  text-align: center;
  line-height: calc(40px + 2vmin);
  padding-bottom: calc(10px + 2vmin);
}
.signup__mini-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}
.signup__input {
  background-color: transparent;
  border-style: none;
  border-bottom: 1px solid #808080;
  font-size: calc(8px + 1.5vmin);
  font-family: proxima-nova, sans-serif;
  color: #616161;
  padding: calc(5px + 0.5vmin);
  margin-bottom: calc(5px + 1.75vmin);
  margin-left: calc(5px + 0.5vmin);
  margin-right: calc(5px + 0.5vmin);
  width: auto;
  flex-grow: 1;
}
.signup__input::placeholder {
  color: #b3b3b3;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signup__input:focus {
    outline: none;
  }
}
@media (max-width: 640px) {
  .signup__input:focus {
    outline: none;
  }
}
.signup__date {
  background-color: transparent;
  border-style: none;
  border-bottom: 1px solid #AFAFAF;
  font-size: calc(8px + 1.5vmin);
  font-family: proxima-nova, sans-serif;
  color: #AFAFAF;
  padding: calc(5px + 0.5vmin);
  margin-bottom: calc(5px + 1.75vmin);
  margin-left: calc(5px + 0.5vmin);
  margin-right: calc(5px + 0.5vmin);
  width: auto;
  flex-grow: 1;
  outline: none;
}
.signup__consent {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  color: #808080;
  font-size: calc(4px + 1.5vmin);
  font-family: proxima-nova, sans-serif;
}
.signup__checkbox {
  accent-color: #008375;
}
.signup__checkbox:hover {
  cursor: pointer;
}
.signup__links {
  color: #808080;
  text-decoration: underline;
  font-size: calc(4px + 1.5vmin);
  font-family: proxima-nova, sans-serif;
}
.signup__button-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: calc(10px + 4vmin);
  margin-bottom: calc(5px + 2vmin);
}
.signup__button {
  background-color: #004E59;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 2rem;
  padding: 0.75em;
}
.signup__button:disabled {
  background-color: #808080;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signup__button {
    font-size: 1.5rem;
  }
}
@media (max-width: 640px) {
  .signup__button {
    font-size: 1rem;
  }
}
.signup__error {
  color: red;
  font-size: calc(8px + 2vmin);
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}
.signup__signin-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-size: calc(4px + 1.5vmin);
  font-family: proxima-nova, sans-serif;
}
.signup__signin {
  color: #808080;
}
.signup__signin-link {
  color: #008375;
}
.signup__popup {
  background-color: #008375;
  width: 450px;
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  padding-top: 0px;
  padding-bottom: 1em;
}
@media (max-width: 640px) {
  .signup__popup {
    width: 325px;
  }
}
.signup__popupClose {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-size: 1.875rem;
  color: #ffffff;
  border-style: none;
  align-self: flex-end;
  margin-right: -0.125em;
}
@media (max-width: 640px) {
  .signup__popupClose {
    font-size: 1.375rem;
    margin-right: -0.2em;
  }
}
.signup__popupPrimary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 700;
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
@media (max-width: 640px) {
  .signup__popupPrimary {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}/*# sourceMappingURL=SignUp.css.map */
.wipInstructions {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 950px;
  margin-top: 100px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipInstructions {
    width: 600px;
  }
}
@media (max-width: 640px) {
  .wipInstructions {
    width: 325px;
    margin-top: 10px;
  }
}
.wipInstructions__header {
  color: #484090;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  font-size: 46px;
  width: 100%;
  text-align: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipInstructions__header {
    font-size: 36px;
  }
}
@media (max-width: 640px) {
  .wipInstructions__header {
    font-size: 32px;
  }
}
.wipInstructions__subheader {
  background-color: #cdc9e0;
  font-family: garamond-premier-pro, serif;
  font-size: 32px;
  color: #484090;
  font-style: italic;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
  align-self: flex-start;
}
.wipInstructions__sectionheader {
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #4d4d4d;
  margin-top: 25px;
  align-self: flex-start;
}
.wipInstructions__body {
  color: #4d4d4d;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 0px;
  padding-top: 10px;
}
.wipInstructions__body b {
  font-weight: 800;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipInstructions__body {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 640px) {
  .wipInstructions__body {
    font-size: 16px;
    line-height: 18px;
  }
}
.wipInstructions__body2 {
  color: #4d4d4d;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 0px;
  padding-top: 25px;
}
.wipInstructions__body2 b {
  font-weight: 800;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipInstructions__body2 {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 640px) {
  .wipInstructions__body2 {
    font-size: 16px;
    line-height: 18px;
  }
}
.wipInstructions__button {
  align-self: flex-end;
  border: none;
  background-color: #484090;
  border-radius: 5px;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 35px;
  margin-bottom: 100px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipInstructions__button {
    font-size: 20px;
  }
}
@media (max-width: 640px) {
  .wipInstructions__button {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.wipInstructions__bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
  margin-top: 255px;
}
.wipInstructions__bottom-instructions {
  margin-top: 0px;
}
@media (max-width: 640px) {
  .wipInstructions__bottom {
    margin-top: 200px;
  }
}
.wipInstructions__popup {
  background-color: #008375;
  width: 300px;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 0px;
}
.wipInstructions__close {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  color: white;
  border-style: none;
  align-self: flex-end;
  font-size: 20px;
  margin-right: -5px;
}
.wipInstructions__primary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-weight: 700;
  text-align: center;
  padding-bottom: 0px;
  font-size: 32px;
}
.wipInstructions__secondary {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.wipInstructions__ready {
  background-color: transparent;
  border: 1px solid #91b8bb;
  border-radius: 4px;
  font-family: proxima-nova, sans-serif;
  color: #92c9bc;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.wipInstructions__ready span {
  font-family: Depot New, sans-serif;
  height: 15px;
  display: inline-block;
  transform: scale(0.4, 1.4);
}
.wipInstructions__ready:hover {
  color: white;
  border-color: white;
}
.wipInstructions__wait {
  background-color: transparent;
  border: none;
  font-family: proxima-nova, sans-serif;
  color: white;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
}
.wipInstructions__wait:hover {
  color: #d6eae5;
}

.wipVideo {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 0px;
  margin-bottom: 150px;
  width: 900px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipVideo {
    margin-top: 75px;
    width: 600px;
  }
}
@media (max-width: 640px) {
  .wipVideo {
    margin-top: 100px;
    width: 325px;
  }
}
.wipVideo__header {
  color: #004e59;
  font-family: garamond-premier-pro, serif;
  font-weight: 800;
  font-size: 40px;
  width: 100%;
  text-align: flex-start;
  margin-bottom: 20px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipVideo__header {
    font-size: 2.25rem;
  }
}
@media (max-width: 640px) {
  .wipVideo__header {
    font-size: 2rem;
    margin-bottom: 10px;
  }
}
.wipVideo__video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  height: 515px;
  border: 10px solid #484090;
  border-radius: 5px;
  padding: 1px;
}
.wipVideo__video-fullscreen {
  height: 500px;
  width: 100%;
}
@media (max-width: 640px) {
  .wipVideo__video-fullscreen {
    height: 350px;
  }
}
.wipVideo__video-ap {
  border: 10px solid #004e59;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipVideo__video {
    height: 347px;
  }
}
@media (max-width: 640px) {
  .wipVideo__video {
    height: 192px;
  }
}
.wipVideo__bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.wipVideo__text {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  color: #808080;
  padding-right: 10px;
  width: 58%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipVideo__text {
    font-size: 12px;
    width: 75%;
  }
}
@media (max-width: 640px) {
  .wipVideo__text {
    font-size: 12px;
    width: 100%;
  }
}
.wipVideo__button {
  align-self: flex-start;
  border: none;
  background-color: #484090;
  border-radius: 5px;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
}
.wipVideo__button-disabled {
  background-color: gray;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipVideo__button {
    font-size: 20px;
  }
}
@media (max-width: 640px) {
  .wipVideo__button {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.wip-main-box {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  min-height: 10vh;
}

.wip-instructions-header {
  color: #254794;
  font-family: garamond-premier-pro, serif;
  font-weight: 800;
  font-style: normal;
  font-size: calc(18px + 1vw);
}

.wip-instructions-header2 {
  font-size: calc(18px + 1vw);
  color: #254794;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(37, 71, 148, 0.2);
  padding-left: calc(4px + 0.35vw);
  padding-right: calc(4px + 0.35vw);
  padding-top: calc(1px + 0.25vw);
  padding-bottom: calc(1px + 0.25vw);
  margin-top: 5px;
}

.wip-instructions-body {
  color: #585858;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: calc(10px + 1vw);
  line-height: calc(12px + 1vw);
  padding-bottom: calc(5px + 1vw);
  padding-top: calc(5px + 1vw);
}

.wip-instructions-subheader {
  color: #585858;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: calc(10px + 1vw);
  line-height: calc(12px + 1vw);
}

.wip-row-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
}

.wip-row-3 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;
}

.wip-instructions-button {
  background-color: #254794;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: calc(2px + 0.5vw);
  font-size: calc(12px + 1vw);
  padding: 0.75rem;
  padding-left: calc(20px + 1vw);
  padding-right: calc(20px + 1vw);
}

.wip-instructions-popup {
  background-color: #008375;
  width: calc(180px + 30vw);
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: calc(10px + 1vw);
  padding-top: calc(2px + 1vw);
}

.wip-instructions-popup-close {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-size: calc(12px + 1vw);
  color: #74C9C0;
  border-style: none;
  align-self: flex-end;
  margin-right: -5px;
}

.wip-instructions-popup-primary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: calc(16px + 1vw);
  font-weight: 700;
  text-align: center;
  padding-bottom: calc(5px + 1vw);
}

.wip-instructions-popup-sub {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.wip-instructions-popup-button-main {
  background-color: transparent;
  border-style: solid;
  border: 0.5px solid #74C9C0;
  font-family: proxima-nova, sans-serif;
  color: #74C9C0;
  font-size: calc(10px + 1vw);
  padding: 4px;
  margin-bottom: 8px;
}

.wip-instructions-popup-button-submain {
  background-color: transparent;
  border-style: solid;
  border-style: none;
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: calc(6px + 1vw);
  text-decoration: underline;
  letter-spacing: 0.5px;
  font-weight: 200;
}

.wip-video-header {
  color: #254794;
  font-family: garamond-premier-pro, serif;
  font-weight: 800;
  font-style: normal;
  font-size: calc(18px + 1vw);
}/*# sourceMappingURL=WIPInstructions.css.map */
.readyPopup__popup {
  background-color: #008375;
  width: 300px;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 5px;
}
.readyPopup__close {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  color: white;
  border-style: none;
  border-width: 0px;
  border-color: transparent;
  font-size: 20px;
  margin-right: 0px;
  margin-left: auto;
}
.readyPopup__primary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-weight: 700;
  text-align: center;
  padding-bottom: 0px;
  font-size: 32px;
}
.readyPopup__secondary {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.readyPopup__ready {
  background-color: transparent;
  border: 1px solid #91b8bb;
  border-radius: 4px;
  font-family: proxima-nova, sans-serif;
  color: #92c9bc;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.readyPopup__ready span {
  font-family: Depot New, sans-serif;
  height: 15px;
  display: inline-block;
  transform: scale(0.4, 1.4);
}
.readyPopup__ready:hover {
  color: white;
  border-color: white;
}
.readyPopup__wait {
  background-color: transparent;
  border: none;
  font-family: proxima-nova, sans-serif;
  color: white;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
}
.readyPopup__wait:hover {
  color: #d6eae5;
}/*# sourceMappingURL=ReadyPopup.css.map */
.wipmain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wipass {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 45px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  width: 800px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipass {
    margin-top: 20px;
    width: 625px;
  }
}
@media (max-width: 640px) {
  .wipass {
    margin-top: 0px;
    width: 315px;
  }
}
.wipass__mainBox {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.wipass__body {
  font-size: 16px;
  line-height: 1.2rem;
  font-family: proxima-nova, sans-serif;
  color: #AFAFAF;
  text-align: center;
  margin-bottom: 25px;
}
@media (max-width: 640px) {
  .wipass__body {
    font-size: 14px;
    line-height: 1rem;
  }
}
.wipass__header {
  font-size: 24px;
  font-family: proxima-nova, sans-serif;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 25px;
}
@media (max-width: 640px) {
  .wipass__header {
    margin-bottom: 10px;
  }
}
.wipass__itemBox {
  width: 100%;
}
.wipass__dndMaster {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.wipass__number {
  font-size: 22px;
  font-family: proxima-nova, sans-serif;
  color: #808080;
  margin-right: 10px;
  align-self: center;
  text-align: right;
  width: 2%;
}
.wipass__dragdropcontext {
  flex-grow: 5;
}
.wipass__item {
  background-color: #646EAB;
  border-radius: 3px;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: proxima-nova, sans-serif;
  width: 100%;
  color: white;
  padding: 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipass__item {
    font-size: 16px;
  }
}
@media (max-width: 640px) {
  .wipass__item {
    font-size: 12px;
    padding: 8px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}
.wipass__icon {
  font-size: 28px;
}
.wipass__progressBar {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
}
@media (max-width: 640px) {
  .wipass__progressBar {
    margin-top: 45px;
    margin-left: 18px;
  }
}
.wipass__questionCount {
  text-align: center;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  color: #AFAFAF;
  margin-top: 10px;
}
@media (max-width: 640px) {
  .wipass__questionCount {
    margin-left: 18px;
  }
}
.wipass__buttonBox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: -3.375em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipass__buttonBox {
    margin-top: -3.375em;
  }
}
@media (max-width: 640px) {
  .wipass__buttonBox {
    margin-top: -3.375em;
  }
}
.wipass__pageButton {
  text-align: center;
  font-family: proxima-nova, sans-serif;
  font-size: 30px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  border-style: none;
  border-radius: 3px;
  background-color: #646EAB;
  color: white;
  margin-left: 32px;
  margin-right: 10px;
}
.wipass__pageButton-next {
  color: white;
  margin-left: auto;
}
.wipass__pageButton-back {
  color: white;
  margin-right: auto;
}
.wipass__pageButton-disabled {
  background-color: #b1b1b1;
  color: rgb(110, 110, 110);
  margin-left: auto;
}
.wipass__pageButton:hover {
  background-color: #484090;
}
.wipass__pageButton span {
  font-family: Depot New, sans-serif;
  height: 25px;
  display: inline-block;
  transform: scale(0.5, 1.1);
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipass__pageButton {
    font-size: 24px;
    padding-top: 2px;
    padding-bottom: 5px;
  }
}
@media (max-width: 640px) {
  .wipass__pageButton {
    font-size: 20px;
    height: 27px;
    padding-top: 2px;
  }
}
.wipass__questionBoxes {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  width: 100%;
  z-index: -100;
}
@media (max-width: 640px) {
  .wipass__questionBoxes {
    margin-left: 1em;
    margin-bottom: 0.375em;
  }
}
.wipass__questionBoxesRow {
  margin-top: 0.5em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  grid-gap: 0.35em;
  gap: 0.35em;
}
@media (max-width: 640px) {
  .wipass__questionBoxesRow {
    grid-gap: 0.25em;
    gap: 0.25em;
  }
}
.wipass__questionBox {
  height: 0.5em;
  width: 0.5em;
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;
  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;
  /* The background will be the radio dot's color. */
  background: white;
  /* The border will be the spacing between the dot and the outer circle */
  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #b3b3b3;
}
@media (max-width: 640px) {
  .wipass__questionBox {
    height: 0.25em;
    width: 0.25em;
  }
}
.wipass__questionBox:checked {
  background: #b3b3b3;
  box-shadow: 0 0 0 1px #b3b3b3;
}
.wipass__questionBox-answered {
  background: #008375;
  box-shadow: 0 0 0 1px #008375;
}

.wipDragDrop {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  height: 320px;
  margin: 10px;
}
.wipDragDrop__numbers {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  margin-right: 10px;
  font-family: proxima-nova, sans-serif;
  font-size: 22px;
  color: #4d4d4d;
  text-align: right;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipDragDrop__numbers {
    font-size: 20px;
  }
}
@media (max-width: 640px) {
  .wipDragDrop__numbers {
    font-size: 18px;
  }
}
.wipDragDrop__body {
  width: 100%;
}

.wipMulti {
  height: 100px;
}
.wipMulti__title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  color: #4d4d4d;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .wipMulti__title {
    font-size: 1.375rem;
  }
}
@media (max-width: 640px) {
  .wipMulti__title {
    font-size: 1.125rem;
    line-height: 1.2rem;
  }
}
.wipMulti__body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}
.wipMulti__form {
  font-family: proxima-nova, sans-serif;
  font-size: 1.5rem;
  color: black;
  margin-left: 2em;
  margin-right: 2em;
}
.wipMulti__input {
  margin-right: 0.5em;
  accent-color: #163c66; /* IE 9 */ /* Chrome, Safari, Opera */
  transform: scale(1.25);
}
.wip-ass-item {
  background-color: #6570AD;
  border-radius: calc(1px + 0.5vw);
  margin-bottom: 1vw;
  font-size: calc(8px + 0.75vw);
  font-family: proxima-nova, sans-serif;
  color: #ECEBF4;
  width: 100%;
  padding: calc(8px + 0.5vw);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.wip-ass-icon {
  margin-right: 5px;
  text-align: center;
}

.progress-wip-custom {
  width: 100%;
  color: rgba(37, 71, 148, 0.2);
  background-color: white;
  border: 1px solid #9A9A9A;
  border-radius: 0px;
}

.wip-button-box {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.wip-button-box-solo {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.task-col {
  width: 100%;
  margin-left: calc(5px + 0.5vw);
}

.wip-button {
  text-align: right;
  background-color: rgba(37, 71, 148, 0.2);
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: calc(18px + 1vw);
  padding-left: 8px;
  padding-right: 8px;
  border-style: none;
  border-radius: 3px;
}

.wip-button:hover {
  background-color: rgba(37, 70, 148, 0.5882352941);
}/*# sourceMappingURL=WIPAssessment.css.map */
.demo {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 500px;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .demo {
    width: 500px;
  }
}
@media (max-width: 640px) {
  .demo {
    width: 325px;
  }
}
.demo__header1 {
  color: #004e59;
  font-size: 32px;
  font-family: proxima-nova, sans-serif;
  text-align: left;
  margin-bottom: 20px;
}
.demo__header1 b {
  font-weight: 700;
}
@media (max-width: 640px) {
  .demo__header1 {
    font-size: 22px;
  }
}
.demo__line {
  width: 100%;
  color: #4d4d4d;
  border-top: 0.5px solid;
  opacity: 0.35;
  margin-top: 0px;
  margin-bottom: 0px;
}
.demo__header2 {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 10px;
}
@media (max-width: 640px) {
  .demo__header2 {
    font-size: 16px;
  }
}
.demo__header2 span {
  font-style: italic;
  font-weight: 400;
  color: #008375;
  font-size: 12px;
  margin-left: 10px;
}
.demo__header2-extraSpace {
  margin-top: 30px;
}
.demo__header2-lessSpace {
  margin-top: 10px;
}
.demo__description {
  color: #999999;
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-size: 14px;
  margin-top: 10px;
  line-height: 16px;
}
@media (max-width: 640px) {
  .demo__description {
    font-size: 10px;
    line-height: 12px;
  }
}
.demo__yearsWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  grid-gap: 25px;
  gap: 25px;
  font-family: proxima-nova, sans-serif;
  color: #004e59;
  font-weight: 500;
  font-size: 16px;
}
@media (max-width: 640px) {
  .demo__yearsWrapper {
    grid-gap: 12px;
    gap: 12px;
    font-size: 14px;
  }
}
.demo__years {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.demo__radioYears {
  accent-color: #004e59;
  margin-right: 0.25em;
}
.demo__radioYears:hover {
  cursor: pointer;
}
.demo__fillin {
  width: 60px;
  height: 35px;
  border: 1px solid rgba(128, 128, 128, 0.411);
  border-radius: 3px;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  padding: 5px;
}
.demo__fillin::placeholder {
  color: rgba(128, 128, 128, 0.527);
}
@media (max-width: 640px) {
  .demo__fillin {
    font-size: 14px;
  }
}
.demo__races {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
}
.demo__radioRace {
  accent-color: #004e59;
  margin-right: 5px;
}
.demo__radioRace:hover {
  cursor: pointer;
}
.demo__raceName {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 16px;
  width: 250px;
}
.demo__raceName-left {
  width: 200px;
}
@media (max-width: 640px) {
  .demo__raceName {
    font-size: 12px;
    width: 150px;
  }
}
.demo__sexWrapper {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 35px;
  gap: 35px;
  font-family: proxima-nova, sans-serif;
  color: #004e59;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
}
@media (max-width: 640px) {
  .demo__sexWrapper {
    font-size: 12px;
    grid-gap: 20px;
    gap: 20px;
  }
}
.demo__sex {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}
.demo__radioSex {
  accent-color: #004e59;
}
.demo__radioSex:hover {
  cursor: pointer;
}
.demo__button {
  margin-top: 20px;
  align-self: flex-end;
  border: none;
  background-color: #008375;
  border-radius: 3px;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 20px;
}
.demo__button-disabled {
  background-color: gray;
}/*# sourceMappingURL=DemoInfo.css.map */
.ipInstructions {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 950px;
  margin-top: 100px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipInstructions {
    width: 600px;
  }
}
@media (max-width: 640px) {
  .ipInstructions {
    width: 325px;
    margin-top: 10px;
  }
}
.ipInstructions__header {
  color: #006db2;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  font-size: 46px;
  width: 100%;
  text-align: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipInstructions__header {
    font-size: 44px;
  }
}
@media (max-width: 640px) {
  .ipInstructions__header {
    font-size: 38px;
  }
}
.ipInstructions__subheader {
  background-color: #C7D4EB;
  font-family: garamond-premier-pro, serif;
  font-size: 32px;
  color: #006db2;
  font-style: italic;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 10px;
  align-self: flex-start;
}
.ipInstructions__body {
  color: #4d4d4d;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 40px;
  padding-top: 20px;
}
.ipInstructions__body b {
  font-weight: 700;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipInstructions__body {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 640px) {
  .ipInstructions__body {
    font-size: 16px;
    line-height: 18px;
  }
}
.ipInstructions__button {
  align-self: flex-end;
  border: none;
  background-color: #006db2;
  border-radius: 3px;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 100px;
}
.ipInstructions__popup {
  background-color: #008375;
  width: 300px;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 0px;
}
.ipInstructions__close {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  color: white;
  border-style: none;
  align-self: flex-end;
  font-size: 20px;
  margin-right: -5px;
}
.ipInstructions__primary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-weight: 700;
  text-align: center;
  padding-bottom: 0px;
  font-size: 32px;
}
.ipInstructions__secondary {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.ipInstructions__ready {
  background-color: transparent;
  border: 1px solid #91b8bb;
  border-radius: 4px;
  font-family: proxima-nova, sans-serif;
  color: #92c9bc;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ipInstructions__ready span {
  font-family: Depot New, sans-serif;
  height: 15px;
  display: inline-block;
  transform: scale(0.4, 1.4);
}
.ipInstructions__ready:hover {
  color: white;
  border-color: white;
}
.ipInstructions__wait {
  background-color: transparent;
  border: none;
  font-family: proxima-nova, sans-serif;
  color: white;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
}
.ipInstructions__wait:hover {
  color: #d6eae5;
}

.ipVideo {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 0px;
  margin-bottom: 150px;
  width: 900px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipVideo {
    margin-top: 75px;
    width: 600px;
  }
}
@media (max-width: 640px) {
  .ipVideo {
    margin-top: 100px;
    width: 325px;
  }
}
.ipVideo__header {
  color: #004e59;
  font-family: garamond-premier-pro, serif;
  font-weight: 800;
  font-size: 40px;
  width: 100%;
  text-align: flex-start;
  margin-bottom: 20px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipVideo__header {
    font-size: 2.25rem;
  }
}
@media (max-width: 640px) {
  .ipVideo__header {
    font-size: 2rem;
    margin-bottom: 10px;
  }
}
.ipVideo__video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  height: 515px;
  border: 10px solid #3d7cca;
  border-radius: 5px;
  padding: 1px;
}
.ipVideo__video-fullscreen {
  height: 500px;
  width: 100%;
}
@media (max-width: 640px) {
  .ipVideo__video-fullscreen {
    height: 350px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipVideo__video {
    height: 347px;
  }
}
@media (max-width: 640px) {
  .ipVideo__video {
    height: 192px;
  }
}
.ipVideo__bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.ipVideo__text {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  color: #808080;
  padding-right: 10px;
  width: 58%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipVideo__text {
    font-size: 12px;
    width: 75%;
  }
}
@media (max-width: 640px) {
  .ipVideo__text {
    font-size: 12px;
    width: 100%;
  }
}
.ipVideo__button {
  align-self: flex-end;
  border: none;
  background-color: #3d7cca;
  border-radius: 5px;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
}
.ipVideo__button-disabled {
  background-color: gray;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipVideo__button {
    font-size: 20px;
  }
}
@media (max-width: 640px) {
  .ipVideo__button {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.main-box {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.instructions-header2 {
  font-size: calc(18px + 1vw);
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(37, 148, 87, 0.2);
  padding-left: calc(4px + 0.35vw);
  padding-right: calc(4px + 0.35vw);
  padding-top: calc(1px + 0.25vw);
  padding-bottom: calc(1px + 0.25vw);
  margin-top: 5px;
}

.instructions-subheader {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: calc(10px + 1vw);
  line-height: calc(12px + 1vw);
}

.instructions-popup-button-main {
  background-color: transparent;
  border-style: solid;
  border: 0.5px solid #74C9C0;
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: calc(10px + 1vw);
  padding: 4px;
  margin-bottom: 8px;
}

.instructions-popup-button-submain {
  background-color: transparent;
  border-style: solid;
  border-style: none;
  font-family: proxima-nova, sans-serif;
  color: #74C9C0;
  font-size: calc(6px + 1vw);
  text-decoration: underline;
  letter-spacing: 0.5px;
  font-weight: 200;
}/*# sourceMappingURL=IPInstructions.css.map */
.ipmain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ipass {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 0px;
  margin-bottom: 150px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipass {
    margin-top: 25px;
  }
}
@media (max-width: 640px) {
  .ipass {
    margin-top: 25px;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
.ipass__body {
  font-size: 16px;
  line-height: 1.2rem;
  font-family: proxima-nova, sans-serif;
  color: #AFAFAF;
  text-align: center;
  margin-bottom: 25px;
}
@media (max-width: 640px) {
  .ipass__body {
    font-size: 14px;
    line-height: 1rem;
  }
}
.ipass__table {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 10px;
  gap: 10px;
  width: 950px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipass__table {
    width: 625px;
  }
}
@media (max-width: 640px) {
  .ipass__table {
    width: 325px;
    height: 400px;
    grid-gap: 5px;
    gap: 5px;
    align-self: center;
  }
}
.ipass__headerRow {
  display: flex;
  flex-flow: row nowrap;
}
.ipass__numberColumn {
  width: 33px;
  font-family: proxima-nova, sans-serif;
  text-align: right;
  color: #021544;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  font-size: 16px;
  font-weight: 600;
}
.ipass__numberColumn-disabled {
  color: #B3B8C7;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipass__numberColumn {
    font-size: 16px;
    width: 25px;
  }
}
@media (max-width: 640px) {
  .ipass__numberColumn {
    font-size: 12px;
    width: 18px;
  }
}
.ipass__tableHeader {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: proxima-nova, sans-serif;
  color: #006DB2;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
  width: 63px;
  font-size: 14px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipass__tableHeader {
    width: 44px;
    font-size: 12px;
  }
}
@media (max-width: 640px) {
  .ipass__tableHeader {
    margin-left: 0px;
    margin-right: 6.5px;
    width: 26.5px;
    font-size: 8px;
  }
}
.ipass__face {
  width: 45px;
  margin: 9px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipass__face {
    width: 35px;
    margin: 5px;
  }
}
@media (max-width: 640px) {
  .ipass__face {
    width: 24px;
    margin: 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 640px) {
  .ipass__faceText {
    font-size: 8px;
    font-weight: 500;
    margin-top: 2px;
  }
}
.ipass__tableBodyRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ipass__tableBody {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 57px;
  margin-left: 6px;
  margin-right: 6px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipass__tableBody {
    width: 42.5px;
    margin-left: 4px;
    margin-right: 4px;
  }
}
@media (max-width: 640px) {
  .ipass__tableBody {
    width: 32px;
    margin-left: 2.75px;
    margin-right: 2.75px;
  }
}
.ipass__radioButton {
  accent-color: #006db2; /* IE 9 */ /* Chrome, Safari, Opera */
  transform: scale(1.5);
}
.ipass__radioButton:hover {
  cursor: pointer;
}
@media (max-width: 640px) {
  .ipass__radioButton { /* IE 9 */ /* Chrome, Safari, Opera */
    transform: scale(1.25);
  }
}
.ipass__tableBodyText {
  width: 125px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #091D4B;
  width: 575px;
  text-align: left;
}
.ipass__tableBodyText-disabled {
  color: #B3B8C7;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipass__tableBodyText {
    font-size: 14px;
    width: 360px;
  }
}
@media (max-width: 640px) {
  .ipass__tableBodyText {
    font-size: 12px;
    font-weight: 500;
    width: 170px;
  }
}
.ipass__bottom {
  display: flex;
  flex-flow: column nowrap;
}
.ipass__progressBar {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
}
@media (max-width: 640px) {
  .ipass__progressBar {
    margin-top: 60px;
  }
}
.ipass__pageCount {
  text-align: center;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  color: #999999;
  margin-top: 10px;
}
.ipass__questionCount {
  text-align: center;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  color: #999999;
  margin-top: 5px;
}
.ipass__buttonBox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -67px;
}
.ipass__pageButton {
  text-align: right;
  font-family: proxima-nova, sans-serif;
  font-size: 30px;
  font-weight: 700;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-style: none;
  border-radius: 3px;
}
.ipass__pageButton-next {
  background-color: #006DB2;
  color: white;
  margin-left: auto;
}
.ipass__pageButton-back {
  background-color: #006DB2;
  color: white;
  margin-right: auto;
}
.ipass__pageButton-disabled {
  background-color: #b1b1b1;
  color: rgb(110, 110, 110);
  margin-left: auto;
}
.ipass__pageButton span {
  font-family: Depot New, sans-serif;
  height: 25px;
  display: inline-block;
  transform: scale(0.5, 1.1);
}
.ipass__nextButton {
  text-align: right;
  background-color: #006DB2;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: calc(18px + 1vw);
  padding-left: 8px;
  padding-right: 8px;
  border-style: none;
  border-radius: 3px;
  margin-left: auto;
}
.ipass__nextButton-disabled {
  background-color: #b1b1b1;
  color: rgb(110, 110, 110);
}
.ipass__backButton {
  text-align: right;
  background-color: #006DB2;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 30px;
  padding-left: 8px;
  padding-right: 8px;
  border-style: none;
  border-radius: 3px;
  margin-right: auto;
}

.ipcomplete {
  display: flex;
  flex-flow: column nowrap;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  width: 100%;
  max-width: 90%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipcomplete {
    width: 100%;
    margin-top: 150px;
    max-width: 90%;
  }
}
@media (max-width: 640px) {
  .ipcomplete {
    width: 100%;
    margin-top: 100px;
    max-width: 90%;
  }
}
.ipcomplete__header {
  font-family: garamond-premier-pro, serif;
  color: #001343;
  font-weight: 800;
  font-size: 44px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipcomplete__header {
    font-size: 44px;
  }
}
@media (max-width: 640px) {
  .ipcomplete__header {
    font-size: 36px;
  }
}
.ipcomplete__body {
  font-family: proxima-nova, sans-serif;
  color: #808080;
  margin-top: 10px;
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.8rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ipcomplete__body {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
@media (max-width: 640px) {
  .ipcomplete__body {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
.ipcomplete__navlink {
  align-self: flex-end;
}
.ipcomplete__button {
  border: none;
  border-radius: 3px;
  background-color: #006db2;
  font-size: 20px;
  color: white;
  font-family: proxima-nova, sans-serif;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  align-self: flex-end;
}

.col-numbers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  width: 10px;
}

.numbers {
  font-family: proxima-nova, sans-serif;
}

.progress-box {
  display: flex;
  flex-flow: row nowrap;
}/*# sourceMappingURL=IPAssessment.css.map */
.main-box {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.video-header {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 30px;
}

.ip-video-button {
  background-color: #004E59;
  color: white;
  border-style: none;
  padding: 5px;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
}/*# sourceMappingURL=IPExplanationVideo.css.map */
.main-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.header {
  color: #001343;
  font-family: garamond-premier-pro, serif;
  font-size: calc(24px + 2vw);
}

.body {
  color: #717171;
  font-family: proxima-nova, sans-serif;
  font-size: calc(12px + 1vw);
  line-height: calc(12px + 1.1vw);
  padding-top: 10px;
  padding-bottom: 10px;
}

.button {
  color: white;
  background-color: #006db2;
  border-style: none;
  align-self: flex-end;
  font-size: calc(10px + 1vw);
  padding-left: calc(10px + 3vw);
  padding-right: calc(10px + 3vw);
  padding-top: calc(5px + 1vw);
  padding-bottom: calc(5px + 1vw);
  border-radius: 3px;
}/*# sourceMappingURL=IPComplete.css.map */
.apfrontpage {
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 4em;
  margin-bottom: 4em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.apfrontpage__pic {
  width: 4.75em;
  margin-bottom: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apfrontpage__pic {
    width: 4em;
    margin-bottom: 0.25em;
  }
}
@media (max-width: 640px) {
  .apfrontpage__pic {
    width: 4em;
    margin-bottom: 0.25em;
  }
}

.apMultiChoice__bottom {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.apMultiChoice__progressBar {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
}
@media (max-width: 640px) {
  .apMultiChoice__progressBar {
    margin-top: 60px;
  }
}

.ap-main-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.25em;
  margin-top: 1.25em;
  width: 100%;
}

.ap-instructions-header {
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 2.75rem;
  margin-top: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-instructions-header {
    font-size: 2.25rem;
  }
}
@media (max-width: 640px) {
  .ap-instructions-header {
    font-size: 2rem;
  }
}

.ap-instructions-subheader {
  font-size: 2rem;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(0, 131, 117, 0.2);
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-instructions-subheader {
    font-size: 1.75rem;
  }
}
@media (max-width: 640px) {
  .ap-instructions-subheader {
    font-size: 1.375rem;
  }
}

.ap-instructions-body {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding-bottom: 0.675em;
  padding-top: 0.625em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-instructions-body {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
}
@media (max-width: 640px) {
  .ap-instructions-body {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}

.ap-instructions-body b {
  font-weight: 800;
}

.ap-instructions-body i {
  font-style: italic;
}

.ap-row-1 {
  display: flex;
  flex-flow: column nowrap;
}

.ap-row-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: -2.5em;
}
.ap-row-2-practice {
  margin-top: 1em;
}

.ap-row-2_spaced {
  justify-content: space-between;
}

.ap-instructions-button {
  background-color: #008375;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 0.25em;
  font-size: 1.25rem;
  padding: 0.625em;
  padding-left: 2.75em;
  padding-right: 2.75em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-instructions-button {
    font-size: 1.125rem;
  }
}
@media (max-width: 640px) {
  .ap-instructions-button {
    font-size: 1rem;
  }
}

.ap-instructions-popup {
  background-color: #008375;
  width: calc(180px + 30vw);
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: calc(10px + 1vw);
  padding-top: calc(2px + 1vw);
}

.ap-instructions-popup-close {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-size: calc(12px + 1vw);
  color: #74C9C0;
  border-style: none;
  align-self: flex-end;
  margin-right: -5px;
}

.ap-instructions-popup-primary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: calc(16px + 1vw);
  font-weight: 700;
  text-align: center;
  padding-bottom: calc(5px + 1vw);
}

.ap-instructions-popup-sub {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.ap-instructions-popup-button-main {
  background-color: transparent;
  border-style: solid;
  border: 0.5px solid #74C9C0;
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: calc(10px + 1vw);
  padding: 4px;
  margin-bottom: 8px;
}

.ap-instructions-popup-button-submain {
  background-color: transparent;
  border-style: solid;
  border-style: none;
  font-family: proxima-nova, sans-serif;
  color: #74C9C0;
  font-size: calc(6px + 1vw);
  text-decoration: underline;
  letter-spacing: 0.5px;
  font-weight: 200;
}

.timer {
  width: 100%;
  text-align: center;
  font-family: proxima-nova, sans-serif;
  color: #AFAFAF;
  font-size: 1.25rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .timer {
    font-size: 1.25rem;
  }
}
@media (max-width: 640px) {
  .timer {
    font-size: 1rem;
  }
}

.ap-button {
  border-style: none;
  background-color: #008375;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0.2rem;
  font-size: 1.5rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-button {
    font-size: 1.25rem;
  }
}
@media (max-width: 640px) {
  .ap-button {
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.ap-button-submit {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-button-submit {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
@media (max-width: 640px) {
  .ap-button-submit {
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.ap-video-header {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 30px;
}

.apassessments-container {
  width: 90vw;
  height: 90vh;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 5vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: top;
}

.apassessments-header {
  font-family: garamond-premier-pro, serif;
  font-size: 44px;
  font-weight: 600;
  color: #004E59;
  text-align: center;
}
.apassessments-header p {
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.assessment-options {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: middle;
}
@media (max-width: 640px) {
  .assessment-options {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
}

.assessment-options__list-1 {
  list-style-type: number;
  width: 100%;
  padding-right: 1.5em;
}
@media (max-width: 640px) {
  .assessment-options__list-1 {
    padding-right: 0em;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.assessment-options__list-2 {
  list-style-type: number;
  width: 100%;
  padding-left: 1.5em;
}
@media (max-width: 640px) {
  .assessment-options__list-2 {
    padding-left: 0em;
  }
}

.assessment-option-gray {
  color: gray;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
}

.assessment-option-available {
  color: black;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
}

.apassessment-button {
  border: none;
  background-color: transparent;
}

.ap-option-text {
  text-align: left;
  margin-left: 0.75em;
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-option-text {
    font-size: 1.15rem;
  }
}
@media (max-width: 640px) {
  .ap-option-text {
    font-size: 1.125rem;
  }
}

.ap-option-text p {
  color: #008375;
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-bottom: 0.5em;
  font-weight: 400;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-option-text p {
    font-size: 0.9rem;
  }
}
@media (max-width: 640px) {
  .ap-option-text p {
    font-size: 0.75rem;
  }
}

.ap-option-text p b {
  font-weight: 800;
}

.ap-option-text-gray {
  text-align: left;
  margin-left: 0.75em;
  margin-bottom: 0.5em;
  color: #999999;
  font-family: proxima-nova, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-option-text-gray {
    font-size: 1.15rem;
  }
}
@media (max-width: 640px) {
  .ap-option-text-gray {
    font-size: 1.125rem;
  }
}

.ap-option-text-gray p {
  color: #999999;
  font-size: 1rem;
  margin-top: 0.2rem;
  font-weight: 400;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap-option-text-gray p {
    font-size: 0.9rem;
  }
}
@media (max-width: 640px) {
  .ap-option-text-gray p {
    font-size: 0.75rem;
  }
}

.ap-option-text-gray p b {
  font-weight: 800;
}

.ap-option-text-complete {
  color: #006DB2;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: 0.1em;
}
@media (max-width: 640px) {
  .ap-option-text-complete {
    font-size: 0.75rem;
  }
}

.apassessment-frontpage__button {
  background-color: transparent;
  border: none;
  font-family: proxima-nova, sans-serif;
  color: #003F4B;
  font-weight: 600;
  font-size: 1.125rem;
  text-decoration: underline;
}
.apassessment-frontpage__button-left {
  margin-left: 5.375em;
  margin-top: 1em;
  text-align: left;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apassessment-frontpage__button-left {
    margin-left: 5em;
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .apassessment-frontpage__button-left {
    margin-left: 5.625em;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    font-size: 0.875rem;
  }
}
.apassessment-frontpage__button-right {
  margin-left: 5.375em;
  margin-top: 1em;
  text-align: left;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apassessment-frontpage__button-right {
    margin-left: 5em;
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .apassessment-frontpage__button-right {
    margin-left: 5.625em;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    font-size: 0.875rem;
  }
}

.apassessment-frontpage__button-box {
  text-decoration: none;
  text-align: center;
  margin-top: 1em;
}

.ap-lower-section {
  margin: 2em;
  max-width: 900px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.apassessment__column {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
@media (max-width: 640px) {
  .apassessment__column {
    width: 100%;
  }
}

.generalinstructions {
  width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .generalinstructions {
    width: 625px;
  }
}
@media (max-width: 640px) {
  .generalinstructions {
    width: 325px;
  }
}

.ap__question-count {
  text-align: center;
  margin-top: 3em;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: #999999;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ap__question-count {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .ap__question-count {
    font-size: 0.75rem;
  }
}/*# sourceMappingURL=APAssessment.css.map */
.apVideo {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 0px;
  margin-bottom: 150px;
  width: 900px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apVideo {
    margin-top: 75px;
    width: 600px;
  }
}
@media (max-width: 640px) {
  .apVideo {
    margin-top: 100px;
    width: 325px;
  }
}
.apVideo__header {
  color: #004e59;
  font-family: garamond-premier-pro, serif;
  font-weight: 800;
  font-size: 40px;
  width: 100%;
  text-align: flex-start;
  margin-bottom: 20px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apVideo__header {
    font-size: 2.25rem;
  }
}
@media (max-width: 640px) {
  .apVideo__header {
    font-size: 2rem;
    margin-bottom: 10px;
  }
}
.apVideo__video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  height: 515px;
  border: 10px solid #3d7cca;
  border-radius: 5px;
  padding: 1px;
}
.apVideo__video-fullscreen {
  height: 500px;
  width: 100%;
}
@media (max-width: 640px) {
  .apVideo__video-fullscreen {
    height: 350px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apVideo__video {
    height: 347px;
  }
}
@media (max-width: 640px) {
  .apVideo__video {
    height: 192px;
  }
}
.apVideo__bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.apVideo__text {
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  color: #808080;
  padding-right: 10px;
  width: 58%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apVideo__text {
    font-size: 12px;
    width: 75%;
  }
}
@media (max-width: 640px) {
  .apVideo__text {
    font-size: 12px;
    width: 100%;
  }
}
.apVideo__button {
  align-self: flex-end;
  border: none;
  background-color: #3d7cca;
  border-radius: 5px;
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
}
.apVideo__button-disabled {
  background-color: gray;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .apVideo__button {
    font-size: 20px;
  }
}
@media (max-width: 640px) {
  .apVideo__button {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 30px;
    padding-right: 30px;
  }
}/*# sourceMappingURL=APExplanationVideo.css.map */
.arFrontPage {
  margin-bottom: 8em;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3%;
  padding-right: 3%;
}

.arMultiChoice__bottom {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.arMultiChoice__progressBar {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
}
@media (max-width: 640px) {
  .arMultiChoice__progressBar {
    margin-top: 60px;
  }
}

.instructionsB__image {
  width: 100%;
}

.instructionsQ34__questions {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  margin-top: 1em;
}
@media (max-width: 640px) {
  .instructionsQ34__questions {
    flex-flow: column nowrap;
  }
}

.ar-instructions-header2 {
  font-size: 2rem;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(0, 131, 117, 0.2);
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .ar-instructions-header2 {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  .ar-instructions-header2 {
    font-size: 2rem;
  }
}

.ar-instructions-body {
  margin-top: 0.75em;
  padding: 0;
}

.ar-instructions-body b {
  font-weight: 600;
}

.ar-main-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1.25em;
  margin-top: 2em;
  width: 100%;
}
@media (max-width: 640px) {
  .ar-main-box {
    width: 95%;
    margin-left: 0.5rem;
  }
}
.ar-main-box__centered {
  align-items: center;
}

.questions {
  display: flex;
  flex-flow: row wrap;
}

.question-ar {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 0.625em;
  margin-bottom: 2em;
}
.question-ar__answerBody {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
}
.question-ar-34 {
  width: 100%;
}

.question__number-ar {
  color: #AFAFAF;
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin-right: 0.25em;
  align-self: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__number-ar {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__number-ar {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.question__body-ar {
  display: flex;
  flex-flow: column nowrap;
  height: 275px;
}

.question__text-ar {
  color: #000000;
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin-bottom: 0.5em;
  width: 100%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__text-ar {
    width: 100%;
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__text-ar {
    width: 100%;
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.question__answers-ar {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  min-width: 200px;
  border: none;
  margin: 0;
  padding: 0;
}
.question__answers-ar :hover {
  cursor: pointer;
  font-weight: 700;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__answers-ar {
    min-width: 125px;
  }
}
@media (max-width: 640px) {
  .question__answers-ar {
    min-width: 115px;
  }
}

.question__answer-option-ar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 0.25em;
}

.question__answer-text-ar {
  color: #000000;
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  margin-left: 0.5em;
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__answer-text-ar {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__answer-text-ar {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.display-answer {
  color: #008375;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 1.75rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer {
    font-size: 1.5rem;
  }
}
@media (max-width: 640px) {
  .display-answer {
    font-size: 1.25rem;
  }
}

.display-answer_upper {
  top: 70%;
  left: 30%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer_upper {
    top: 62%;
    left: 30%;
  }
}
@media (max-width: 640px) {
  .display-answer_upper {
    top: 62%;
    left: 40%;
  }
}

.display-answer_lower {
  top: 70%;
  left: 70%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer_lower {
    top: 62%;
    left: 70%;
  }
}
@media (max-width: 640px) {
  .display-answer_lower {
    top: 105%;
    left: 40%;
  }
}

.display-answer__body {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-right: 2em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer__body {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .display-answer__body {
    font-size: 0.75rem;
    line-height: 0.9rem;
  }
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.blank-button {
  background-color: transparent;
  border-style: none;
}

.ar-nav-button {
  background-color: #008375;
  color: white;
  font-size: 30px;
  border-radius: 50%;
}

.question__radio-button {
  accent-color: #008375; /* IE 9 */ /* Chrome, Safari, Opera */
  transform: scale(1.25);
  cursor: pointer;
}
@media (max-width: 640px) {
  .question__radio-button { /* IE 9 */ /* Chrome, Safari, Opera */
    transform: scale(1);
  }
}

.armulti__checkbox {
  accent-color: #008375;
  cursor: pointer;
  height: 2em;
  width: 1em;
}

.arass__questionBoxes {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: -1.9em;
  width: 100%;
  z-index: -100;
}
.arass__questionBoxesRow {
  margin-top: 0.5em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  grid-gap: 0.35em;
  gap: 0.35em;
}
@media (max-width: 640px) {
  .arass__questionBoxesRow {
    grid-gap: 0.25em;
    gap: 0.25em;
  }
}
.arass__questionBox {
  height: 0.5em;
  width: 0.5em;
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;
  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;
  /* The background will be the radio dot's color. */
  background: white;
  /* The border will be the spacing between the dot and the outer circle */
  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #b3b3b3;
}
@media (max-width: 640px) {
  .arass__questionBox {
    height: 0.25em;
    width: 0.25em;
  }
}
.arass__questionBox:checked {
  background: #b3b3b3;
  box-shadow: 0 0 0 1px #b3b3b3;
}
.arass__questionBox-answered {
  background: #008375;
  box-shadow: 0 0 0 1px #008375;
}/*# sourceMappingURL=AR.css.map */
.vaFrontPage {
  margin-bottom: 8em;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3%;
  padding-right: 3%;
}

.vaInstructionsQ345__questions {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 2em;
}
@media (max-width: 640px) {
  .vaInstructionsQ345__questions {
    flex-flow: column nowrap;
  }
}

.va-main-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25em;
  margin-top: 3em;
  width: 100%;
}
.va-main-box-practice {
  align-items: flex-start;
}

.va-instructions-header2 {
  font-size: 2rem;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(0, 131, 117, 0.2);
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .va-instructions-header2 {
    font-size: 1.75rem;
  }
}
@media (max-width: 640px) {
  .va-instructions-header2 {
    font-size: 1.375rem;
  }
}

.va-instructions-body {
  margin-top: 0.75em;
  padding: 0;
}

.va-instructions-body b {
  font-weight: 600;
}

.question-va {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0.625em;
  margin-bottom: 2em;
}
.question__number-va {
  color: #AFAFAF;
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin-right: 0.25em;
  align-self: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__number-va {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__number-va {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.question__body-va {
  display: flex;
  flex-flow: column nowrap;
}

.question__text-va {
  color: #000000;
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin-bottom: 0.5em;
  width: 75%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__text-va {
    width: 100%;
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__text-va {
    width: 100%;
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.question__answers-horizontal-va {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1.25rem;
}
.question__answers-horizontal-va :hover {
  cursor: pointer;
  font-weight: 700;
}

.question__radio-button-va {
  margin-top: 3px;
  transform: scale(1.25);
}

.question__answer-option-horizontal-va {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-right: 1em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__answer-option-horizontal-va {
    margin-right: 0.75em;
  }
}
@media (max-width: 640px) {
  .question__answer-option-horizontal-va {
    margin-right: 1em;
  }
}

.question__answer-text-va {
  color: #000000;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__answer-text-va {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__answer-text-va {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.va-row-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: -2.25em;
}
.va-row-2-spaced {
  justify-content: space-between;
}
.va-row-2-nomargin {
  margin-top: -2.25em;
}
.va-row-2-practice {
  margin-top: 1em;
}

.display-answer-va {
  position: absolute;
  left: 40%;
  top: 46%;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 2rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer-va {
    left: 35%;
    font-size: 1.625rem;
  }
}
@media (max-width: 640px) {
  .display-answer-va {
    left: 30%;
    font-size: 1.5rem;
  }
}

.display-answer-va-flex {
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 2rem;
  width: 500px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer-va-flex {
    font-size: 1.625rem;
    width: 400px;
  }
}
@media (max-width: 640px) {
  .display-answer-va-flex {
    font-size: 1.5rem;
    margin-left: -5em;
    width: 200px;
  }
}

.display-answer-va-higher {
  top: 35%;
}

.display-answer-va-q3 {
  position: absolute;
  left: 45%;
  top: 20%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer-va-q3 {
    left: 45%;
  }
}
@media (max-width: 640px) {
  .display-answer-va-q3 {
    left: 40%;
  }
}

.display-answer-va-q4 {
  position: absolute;
  left: 45%;
  top: 20%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer-va-q4 {
    left: 45%;
  }
}
@media (max-width: 640px) {
  .display-answer-va-q4 {
    left: 40%;
  }
}

.display-answer-va-q5 {
  position: absolute;
  left: 45%;
  top: 20%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer-va-q5 {
    left: 45%;
  }
}
@media (max-width: 640px) {
  .display-answer-va-q5 {
    left: 40%;
  }
}

.display-answer_inner {
  position: absolute;
  left: 55%;
  top: 40%;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: calc(12px + 0.9vw);
}

.vaass__questionBoxes {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: -2em;
  width: 100%;
  z-index: -100;
}
@media (max-width: 640px) {
  .vaass__questionBoxes {
    margin-top: -1.75em;
  }
}
.vaass__questionBoxesRow {
  margin-top: 0.5em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  grid-gap: 0.35em;
  gap: 0.35em;
}
@media (max-width: 640px) {
  .vaass__questionBoxesRow {
    grid-gap: 0.25em;
    gap: 0.25em;
  }
}
.vaass__questionBox {
  height: 0.5em;
  width: 0.5em;
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;
  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;
  /* The background will be the radio dot's color. */
  background: white;
  /* The border will be the spacing between the dot and the outer circle */
  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #b3b3b3;
}
@media (max-width: 640px) {
  .vaass__questionBox {
    height: 0.25em;
    width: 0.25em;
  }
}
.vaass__questionBox:checked {
  background: #b3b3b3;
  box-shadow: 0 0 0 1px #b3b3b3;
}
.vaass__questionBox-answered {
  background: #008375;
  box-shadow: 0 0 0 1px #008375;
}

.vaMultiChoice__question-count {
  text-align: center;
  margin-top: 3.5em;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: #999999;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .vaMultiChoice__question-count {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .vaMultiChoice__question-count {
    font-size: 0.75rem;
  }
}/*# sourceMappingURL=VA.css.map */
.saFrontPage {
  margin-bottom: 8em;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
}

.saInsctructions__question {
  margin-bottom: 3em;
}

.sa-main-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25em;
  margin-top: 3em;
  width: 100%;
}
.sa-main-box-practice {
  align-items: flex-start;
}
@media (max-width: 640px) {
  .sa-main-box {
    margin-top: 0em;
  }
}

.sa-instructions-header2 {
  font-size: 2rem;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(0, 131, 117, 0.2);
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .sa-instructions-header2 {
    font-size: 1.75rem;
  }
}
@media (max-width: 640px) {
  .sa-instructions-header2 {
    font-size: 1.375rem;
  }
}

.sa-instructions-body {
  margin-top: 0.75em;
  padding: 0;
}

.sa-instructions-body b {
  font-weight: 600;
}

.question-sa {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.question__2d-box-sa {
  display: flex;
  flex-flow: row nowrap;
  border-style: solid;
  border-width: 2px;
  padding: 10px;
  padding-left: 5px;
  padding-right: 15px;
}

.question_2d-sa {
  max-width: 100%;
}

.question__number-sa {
  color: #AFAFAF;
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin-right: 0.25em;
  align-self: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__number-sa {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__number-sa {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.question_2d-picturebox-sa {
  align-self: center;
}
@media (max-width: 640px) {
  .question_2d-picturebox-sa {
    min-width: 75px;
  }
}

.question__answers-sa {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1em;
  margin-left: 0.5em;
  border-style: solid;
  border-width: 2px;
}

.question__answer-sa {
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.question__picture-sa {
  align-self: center;
}

.question__answer-text-sa {
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  margin-top: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__answer-text-sa {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__answer-text-sa {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.question__radio-button-sa {
  margin-top: 0.25em;
}

.display-answer-sa {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 2rem;
  padding: 0.25em;
  height: 100%;
  width: 100%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer-sa {
    font-size: 1.625rem;
  }
}
@media (max-width: 640px) {
  .display-answer-sa {
    font-size: 1.5rem;
  }
}
.display-answer-sa__content {
  text-align: center;
}

.sa-row-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: -2.2em;
}
.sa-row-2-spaced {
  justify-content: space-between;
}
.sa-row-2-nomargin {
  margin-top: -2.2em;
}
.sa-row-2-practice {
  margin-top: 1em;
}

.sa__content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 0.625em;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 1em;
  width: 100%;
}
@media (max-width: 640px) {
  .sa__content {
    width: 250px;
    flex-flow: row nowrap;
    height: 600px;
    margin-top: 0.25em;
  }
}
.sa__content-fixed {
  width: 100%;
}
.sa__span {
  order: 10;
  width: 15%;
}
.sa__choices {
  width: 100%;
}
@media (max-width: 640px) {
  .sa__choices {
    order: 2;
    max-width: 400px;
    width: auto;
    height: 100%;
  }
}
.sa__options {
  width: 65%;
  align-self: flex-end;
  margin-right: 1.9%;
  margin-top: 0.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 640px) {
  .sa__options {
    order: 1;
    flex-flow: column nowrap;
    width: auto;
    height: 69.75%;
    margin-bottom: 3.5%;
  }
}
.sa__option {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.sa__optionButton {
  transform: scale(1.25);
  accent-color: #008375;
  width: 20px;
  height: 20px;
}
@media (max-width: 640px) {
  .sa__optionButton { /* IE 9 */ /* Chrome, Safari, Opera */
    transform: scale(1);
  }
}
.sa__optionButton:hover {
  cursor: pointer;
}

.saass__questionBoxes {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: -2em;
  width: 100%;
  z-index: -100;
}
@media (max-width: 640px) {
  .saass__questionBoxes {
    margin-top: -1.75em;
  }
}
.saass__questionBoxesRow {
  margin-top: 0.5em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  grid-gap: 0.35em;
  gap: 0.35em;
}
@media (max-width: 640px) {
  .saass__questionBoxesRow {
    grid-gap: 0.25em;
    gap: 0.25em;
  }
}
.saass__questionBox {
  height: 0.5em;
  width: 0.5em;
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;
  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;
  /* The background will be the radio dot's color. */
  background: white;
  /* The border will be the spacing between the dot and the outer circle */
  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #b3b3b3;
}
@media (max-width: 640px) {
  .saass__questionBox {
    height: 0.25em;
    width: 0.25em;
  }
}
.saass__questionBox:checked {
  background: #b3b3b3;
  box-shadow: 0 0 0 1px #b3b3b3;
}
.saass__questionBox-answered {
  background: #008375;
  box-shadow: 0 0 0 1px #008375;
}/*# sourceMappingURL=SA.css.map */


.cmFrontPage {
  margin-bottom: 8em;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3%;
  padding-right: 3%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cmFrontPage {
    max-width: 625px;
  }
}
@media (max-width: 640px) {
  .cmFrontPage {
    max-width: 95%;
  }
}

.cm-main-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25em;
  margin-top: 1em;
  width: 100%;
  position: relative;
}
@media (max-width: 640px) {
  .cm-main-box {
    width: 95%;
    margin-left: 0.5rem;
  }
}
.cm-main-box-practice {
  align-items: flex-start;
}

.cm-main-box__header1 {
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-weight: 800;
  font-style: normal;
  font-size: calc(18px + 1vw);
}

.cm-main-box__header2 {
  font-size: 2rem;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(0, 131, 117, 0.2);
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-main-box__header2 {
    font-size: 1.75rem;
  }
}
@media (max-width: 640px) {
  .cm-main-box__header2 {
    font-size: 1.375rem;
  }
}

.cm-instructions-body {
  margin-top: 0.75em;
  padding: 0;
}

.cm-instructions-body b {
  font-weight: 600;
}

.cm-main-box__body {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: calc(12px + 1vw);
  line-height: calc(14px + 1.1vw);
  padding-bottom: calc(5px + 1vw);
  padding-top: calc(5px + 1vw);
}

.cm-main-box__body b {
  font-weight: 800;
}

.cm-main-box__body i {
  font-style: italic;
}

.cm-question {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 2em;
  height: 300px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-question {
    height: 250px;
  }
}
@media (max-width: 640px) {
  .cm-question {
    height: 200px;
  }
}
.cm-question-center {
  justify-content: center;
  padding-bottom: 2em;
}
.cm-question-q34 {
  flex-flow: column nowrap;
  width: 48%;
  margin-left: 0em;
  margin-right: 1em;
}
@media (max-width: 640px) {
  .cm-question-q34 {
    width: 100%;
    margin-right: 0em;
  }
}
.cm-question-main {
  height: 350px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-question-main {
    height: 300px;
  }
}
@media (max-width: 640px) {
  .cm-question-main {
    height: 250px;
  }
}
.cm-question__top {
  display: flex;
  flex-flow: row nowrap;
}

.cm-questions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
@media (max-width: 640px) {
  .cm-questions {
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }
}

.cm-question__number {
  color: #AFAFAF;
  font-size: 1rem;
  line-height: 1.125rem;
  margin-right: 0.25em;
  font-family: proxima-nova, sans-serif;
  align-self: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-question__number {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .cm-question__number {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.cm-question__content {
  width: 150px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-question__content {
    width: 100px;
  }
}
@media (max-width: 640px) {
  .cm-question__content {
    width: 85px;
  }
}

.cm-question__answers {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-left: 2.5em;
}
.cm-question__answers :hover {
  cursor: pointer;
  font-weight: 700;
}
@media (max-width: 640px) {
  .cm-question__answers {
    margin-left: 0em;
  }
}

.cm-question__answer-option {
  margin-bottom: 0.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.cm-question__answer-text {
  color: #000000;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  margin-left: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-question__answer-text {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .cm-question__answer-text {
    font-size: 0.875rem;
  }
}

.cm-math-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  margin-left: 0.25em;
  width: 100%;
}

.cm-math-box__header {
  color: #000000;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-math-box__header {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .cm-math-box__header {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.cm-calc-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 0.625em;
  margin-bottom: -0.625em;
  width: 100%;
}

.cm-calc-box-regular-margins {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  margin-top: 0.625em;
}

.cm-calc-box__item {
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #000000;
  padding-top: 2px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-calc-box__item {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .cm-calc-box__item {
    font-size: 0.82rem;
    line-height: 1rem;
  }
}

.cm-display-answer {
  position: absolute;
  left: calc(10px + 40%);
  top: 55%;
  color: #008375;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 1.75rem;
}
.cm-display-answer-q1 {
  left: calc(50px + 40%);
  top: 55%;
}
.cm-display-answer-q2 {
  left: calc(10px + 40%);
}
.cm-display-answer-q3 {
  left: calc(50px + 40%);
  top: 42%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-display-answer-q3 {
    top: 43%;
  }
}
@media (max-width: 640px) {
  .cm-display-answer-q3 {
    top: 47%;
  }
}
.cm-display-answer-q4 {
  left: calc(50px + 40%);
  top: 75%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-display-answer-q4 {
    top: 75%;
  }
}
@media (max-width: 640px) {
  .cm-display-answer-q4 {
    top: 77%;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-display-answer {
    font-size: 1.5rem;
  }
}
@media (max-width: 640px) {
  .cm-display-answer {
    font-size: 1.25rem;
  }
}

.cm-display-answer-flex {
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 2rem;
  width: 500px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-display-answer-flex {
    font-size: 1.625rem;
    width: 400px;
  }
}
@media (max-width: 640px) {
  .cm-display-answer-flex {
    font-size: 1.5rem;
    margin-left: -1em;
    margin-top: -0.5em;
    width: 175px;
  }
}
.cm-display-answer-flex-bottom {
  max-width: 50%;
  margin-left: 5em;
  margin-top: 1em;
}
@media (max-width: 640px) {
  .cm-display-answer-flex-bottom {
    font-size: 1.5rem;
    margin: 0em;
    width: 175px;
    margin-left: 5em;
    margin-top: 1em;
  }
}
.cm-display-answer-flex-highlight {
  font-size: 1.125rem;
  color: #901db4;
  padding-top: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-display-answer-flex-highlight {
    font-size: 1.25rem;
  }
}
@media (max-width: 640px) {
  .cm-display-answer-flex-highlight {
    font-size: 1rem;
  }
}

.cm-display-answer__body {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-right: 2em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-display-answer__body {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .cm-display-answer__body {
    font-size: 0.75rem;
    line-height: 0.9rem;
  }
}

.cm-display-answer__upper {
  position: absolute;
  left: calc(60px + 40%);
  top: 37%;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: calc(12px + 0.9vw);
}

.cm-display-answer__lower {
  position: absolute;
  left: calc(60px + 40%);
  top: 70%;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: calc(12px + 0.9vw);
}

.cm-display-answer__right {
  position: absolute;
  left: calc(60px + 40%);
  top: 47%;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: calc(12px + 0.9vw);
}

.cm-calc-box__divide {
  color: black;
  font-family: proxima-nova, sans-serif;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.375rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cm-calc-box__divide {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .cm-calc-box__divide {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.cm-calc-box__divide-curve {
  border-right: 2px black solid;
  border-radius: 0px 0px 10px 0px;
}

.cm-calc-box__divide-line {
  border-top: 2px black solid;
}

.cm-row-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: -2.5em;
  z-index: 1;
}
.cm-row-2-spaced {
  justify-content: space-between;
}
.cm-row-2-nomargin {
  margin-top: -2.5em;
}
.cm-row-2-practice {
  margin-top: 1em;
}

.cmass__questionBoxes {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: -2em;
  width: 100%;
  z-index: -100;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cmass__questionBoxes {
    margin-top: -2.3em;
  }
}
@media (max-width: 640px) {
  .cmass__questionBoxes {
    margin-top: -2.1em;
  }
}
.cmass__questionBoxesRow {
  margin-top: 0.5em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 0.35em;
  gap: 0.35em;
  width: 600px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cmass__questionBoxesRow {
    width: 275px;
  }
}
@media (max-width: 640px) {
  .cmass__questionBoxesRow {
    grid-gap: 0.25em;
    gap: 0.25em;
    width: 160px;
  }
}
.cmass__questionBox {
  height: 0.5em;
  width: 0.5em;
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;
  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;
  /* The background will be the radio dot's color. */
  background: white;
  /* The border will be the spacing between the dot and the outer circle */
  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #b3b3b3;
}
@media (max-width: 640px) {
  .cmass__questionBox {
    height: 0.25em;
    width: 0.25em;
  }
}
.cmass__questionBox:checked {
  background: #b3b3b3;
  box-shadow: 0 0 0 1px #b3b3b3;
}
.cmass__questionBox-answered {
  background: #008375;
  box-shadow: 0 0 0 1px #008375;
}

.cmMultiChoice__question-count {
  text-align: center;
  margin-top: 4em;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: #999999;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cmMultiChoice__question-count {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .cmMultiChoice__question-count {
    font-size: 0.75rem;
  }
}/*# sourceMappingURL=CM.css.map */
.cpFrontPage {
  margin-bottom: 8em;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3%;
  padding-right: 3%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cpFrontPage {
    padding-left: 5%;
    padding-right: 5;
  }
}
@media (max-width: 640px) {
  .cpFrontPage {
    padding-left: 5%;
    padding-right: 5;
  }
}

.cp-main-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  position: relative;
  height: 400px;
}
@media (max-width: 640px) {
  .cp-main-box {
    width: 95%;
    margin-left: 0.5rem;
  }
}
.cp-main-box-practice {
  align-items: flex-start;
  min-height: auto;
  height: auto;
}

.cp-main-box-assessment {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  position: relative;
  min-height: 50vh;
}

.cp-main-box__header1 {
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-weight: 800;
  font-style: normal;
  font-size: calc(18px + 1vw);
}

.cp-main-box__header2 {
  font-size: 2rem;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(0, 131, 117, 0.2);
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-main-box__header2 {
    font-size: 1.75rem;
  }
}
@media (max-width: 640px) {
  .cp-main-box__header2 {
    font-size: 1.375rem;
  }
}

.cp-instructions-body {
  margin-top: 0.75em;
  padding: 0;
}

.cp-instructions-body b {
  font-weight: 600;
}

.cp-main-box__body {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: calc(12px + 0.7vw);
  line-height: calc(14px + 1.1vw);
  padding-bottom: calc(5px + 1vw);
  padding-top: calc(5px + 1vw);
}

.cp-main-box__body b {
  font-weight: 800;
}

.cp-main-box__body i {
  font-style: italic;
}

.cp-question {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  width: 100%;
  margin-top: 2em;
  padding-bottom: 1em;
  position: relative;
}

.cp-question__number {
  color: #AFAFAF;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin-right: 0.25em;
  font-family: proxima-nova, sans-serif;
  align-self: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-question__number {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .cp-question__number {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.cp-question__content {
  color: #000000;
  font-size: 1.25rem;
  font-family: proxima-nova, sans-serif;
  align-self: center;
  padding-left: 1em;
  width: 350px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-question__content {
    font-size: 1rem;
    width: 300px;
  }
}
@media (max-width: 640px) {
  .cp-question__content {
    font-size: 0.875rem;
    width: 250px;
  }
}

.cp-question__answers {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.cp-question__answers :hover {
  cursor: pointer;
  font-weight: 700;
}

.cp-question__answer-option {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.125em;
  margin-left: 2em;
  margin-right: 0em;
  margin-bottom: 1.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-question__answer-option {
    margin-left: 1.5em;
    margin-right: 0em;
    margin-bottom: 1.25em;
  }
}
@media (max-width: 640px) {
  .cp-question__answer-option {
    margin-left: 0.25em;
    margin-right: 0em;
    margin-bottom: 0.875em;
  }
}

.cp-question__answer-text {
  color: #000000;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  padding-bottom: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-question__answer-text {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .cp-question__answer-text {
    font-size: 0.75rem;
  }
}

.cp-display-answer__body {
  position: absolute;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 1.25rem;
  padding-left: 2em;
}

.cp-display-answer {
  position: absolute;
  left: calc(10px + 20%);
  top: 40%;
  color: #008375;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 1.75rem;
}
.cp-display-answer-q3 {
  left: 60%;
  top: 18%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-display-answer-q3 {
    font-size: 1.25rem;
    left: 65%;
    top: 25%;
  }
}
@media (max-width: 640px) {
  .cp-display-answer-q3 {
    font-size: 1rem;
    left: 30%;
    top: 60%;
  }
}
.cp-display-answer-q9 {
  left: 60%;
  top: 60%;
}

.cp-q9 {
  color: #008375;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 1.75rem;
  margin-left: 1.5em;
  align-self: flex-start;
}
.cp-q9-highlight {
  font-size: 1.125rem;
  color: #901db4;
  padding-top: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-q9-highlight {
    font-size: 1.25rem;
  }
}
@media (max-width: 640px) {
  .cp-q9-highlight {
    font-size: 1rem;
  }
}

.table-responsive-sm {
  width: 75%;
}

.cp-table-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 2em;
  height: 475px;
}

.cp-table {
  width: 700px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-table {
    width: 500px;
  }
}
@media (max-width: 640px) {
  .cp-table {
    width: 350px;
  }
}

.cp-table__row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-family: proxima-nova, sans-serif;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-table__row {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 640px) {
  .cp-table__row {
    width: 100%;
    margin: 0;
  }
}

.cp-table__number {
  color: #AFAFAF;
}
@media (max-width: 640px) {
  .cp-table__number {
    font-size: 0.75rem;
  }
}

.cp-table__item {
  font-size: 1rem;
}
.cp-table__item-1 {
  text-align: right;
  width: 100%;
}
.cp-table__item-2 {
  width: 100%;
}
.cp-table__item-3 {
  width: 5em;
  position: relative;
  margin-left: 1em;
  margin-right: 1em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-table__item-3 {
    width: 5em;
  }
}
@media (max-width: 640px) {
  .cp-table__item-3 {
    width: 2em;
    margin-left: 0.375em;
    margin-right: 0.375em;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cp-table__item {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .cp-table__item {
    font-size: 0.75rem;
  }
}

.cp-table__options {
  margin-bottom: 0em;
}

.cp-row-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: -2.18em;
  z-index: 1;
}
.cp-row-2-spaced {
  justify-content: space-between;
}
.cp-row-2-nomargin {
  margin-top: -2.18em;
}
.cp-row-2-practice {
  margin-top: 1em;
}
.cp-row-2-practice-margin {
  margin-top: 2em;
}

.question__radio-button-cp {
  accent-color: #008375; /* IE 9 */ /* Chrome, Safari, Opera */
  transform: scale(1.1);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media (max-width: 640px) {
  .question__radio-button-cp { /* IE 9 */ /* Chrome, Safari, Opera */
    transform: scale(0.9);
  }
}

.hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid black;
  margin: 1em 0;
  padding: 0;
}

.cpass__questionBoxes {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: -2em;
  width: 100%;
  z-index: -100;
}
@media (max-width: 640px) {
  .cpass__questionBoxes {
    margin-top: -1.75em;
  }
}
.cpass__questionBoxesRow {
  margin-top: 0.5em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 0.35em;
  gap: 0.35em;
  width: 600px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .cpass__questionBoxesRow {
    width: 275px;
  }
}
@media (max-width: 640px) {
  .cpass__questionBoxesRow {
    grid-gap: 0.25em;
    gap: 0.25em;
    width: 160px;
  }
}
.cpass__questionBox {
  height: 0.5em;
  width: 0.5em;
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;
  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;
  /* The background will be the radio dot's color. */
  background: white;
  /* The border will be the spacing between the dot and the outer circle */
  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #b3b3b3;
}
@media (max-width: 640px) {
  .cpass__questionBox {
    height: 0.25em;
    width: 0.25em;
  }
}
.cpass__questionBox:checked {
  background: #b3b3b3;
  box-shadow: 0 0 0 1px #b3b3b3;
}
.cpass__questionBox-answered {
  background: #008375;
  box-shadow: 0 0 0 1px #008375;
}/*# sourceMappingURL=CP.css.map */
.fpFrontPage {
  margin-bottom: 8em;
  max-width: 950px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3%;
  padding-right: 3%;
}

.fp-main-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1.25em;
  margin-top: 1.25em;
  width: 100%;
  position: relative;
  height: auto;
}
@media (max-width: 640px) {
  .fp-main-box {
    width: 95%;
    margin-left: 0.5rem;
    margin-top: 0em;
  }
}
.fp-main-box-practice {
  align-items: flex-start;
  min-height: auto;
}

.fp-main-box-assessment {
  min-height: 400px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .fp-main-box-assessment {
    min-height: 400px;
  }
}
@media (max-width: 640px) {
  .fp-main-box-assessment {
    min-height: 300px;
  }
}

.fp-main-box__header2 {
  font-size: 2rem;
  color: #004E59;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  background-color: rgba(0, 131, 117, 0.2);
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  margin-top: 0.25em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .fp-main-box__header2 {
    font-size: 1.75rem;
  }
}
@media (max-width: 640px) {
  .fp-main-box__header2 {
    font-size: 1.375rem;
  }
}

.fp-instructions-body {
  margin-top: 0.75em;
  padding: 0;
}

.fp-instructions-body b {
  font-weight: 600;
}

.fp-question {
  display: flex;
  flex-flow: row nowrap;
  padding: 0.625em;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 1em;
  width: 100%;
}

.question__2d-box-fp {
  display: flex;
  flex-flow: row nowrap;
  border-style: solid;
  border-width: 0.125em;
  padding: 0.625em;
  padding-left: 0.25em;
  padding-right: 1em;
}

.question__number-fp {
  color: #AFAFAF;
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin-right: 0.25em;
  align-self: flex-start;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__number-fp {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__number-fp {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.fp-question__assessment {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.question__answers-fp {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1em;
  margin-left: 0.5em;
  border-style: solid;
  border-width: 2px;
  width: 100%;
}

.question__answer-fp {
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.question__answer-text-fp {
  font-family: proxima-nova, sans-serif;
  font-size: 1.25rem;
  margin-top: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .question__answer-text-fp {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 640px) {
  .question__answer-text-fp {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.fp-question__answer {
  display: flex;
  flex-flow: column nowrap;
  border-style: solid;
  border-width: 2px;
  padding: 10px;
}

.fp-question__image {
  max-width: 100%;
}

.fp-question__number {
  font-family: proxima-nova, sans-serif;
  color: black;
  padding-top: 5px;
  font-size: calc(8px + 1vw);
}

.fp-question__options {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px;
  margin-left: 0.35rem;
  border-style: solid;
  border-width: 2px;
}

.fp-question__option {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.fp-row-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  margin-top: -2.2em;
}
@media (max-width: 640px) {
  .fp-row-2 {
    margin-top: -2.07em;
  }
}
.fp-row-2-spaced {
  justify-content: space-between;
}
.fp-row-2-nomargin {
  margin-top: -3.5em;
}
@media (max-width: 640px) {
  .fp-row-2-nomargin {
    margin-top: -2.25em;
  }
}
.fp-row-2-practice {
  margin-top: 1em;
}

.question__radio-button-fp {
  margin-top: 0.25em;
}

.display-answer-fp {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: #008375;
  font-family: garamond-premier-pro, serif;
  font-style: italic;
  font-size: 1.75rem;
  padding: 0.25em;
  height: 100%;
  width: 100%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer-fp {
    font-size: 1.625rem;
  }
}
@media (max-width: 640px) {
  .display-answer-fp {
    font-size: 1.5rem;
  }
}
.display-answer-fp__content {
  text-align: center;
}
.display-answer-fp__highlight {
  font-size: 1.125rem;
  color: #901db4;
  padding-top: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .display-answer-fp__highlight {
    font-size: 1.25rem;
  }
}
@media (max-width: 640px) {
  .display-answer-fp__highlight {
    font-size: 1rem;
  }
}

.fp__content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 0.625em;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 1em;
  width: 100%;
  height: 250px;
}
@media (max-width: 640px) {
  .fp__content {
    width: 250px;
    flex-flow: row nowrap;
    height: 600px;
  }
}
.fp__span {
  order: 10;
  width: 15%;
}
.fp__choices {
  width: 100%;
}
@media (max-width: 640px) {
  .fp__choices {
    order: 2;
    max-width: 400px;
    width: auto;
    height: 100%;
  }
}
.fp__options {
  width: 71%;
  align-self: flex-end;
  margin-right: 2%;
  margin-top: 0.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 640px) {
  .fp__options {
    order: 1;
    flex-flow: column nowrap;
    width: auto;
    height: 69.25%;
    margin-bottom: 5.25%;
  }
}
.fp__option {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.fp__optionButton {
  transform: scale(1.25);
  accent-color: #008375;
  width: 20px;
  height: 20px;
}
@media (max-width: 640px) {
  .fp__optionButton { /* IE 9 */ /* Chrome, Safari, Opera */
    transform: scale(1);
  }
}
.fp__optionButton:hover {
  cursor: pointer;
}

.fpass__questionBoxes {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: -2.5em;
  width: 100%;
  z-index: -100;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .fpass__questionBoxes {
    margin-top: -2.8em;
  }
}
@media (max-width: 640px) {
  .fpass__questionBoxes {
    margin-top: -2.1em;
  }
}
.fpass__questionBoxesRow {
  margin-top: 0.5em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 0.35em;
  gap: 0.35em;
  width: 600px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .fpass__questionBoxesRow {
    width: 290px;
  }
}
@media (max-width: 640px) {
  .fpass__questionBoxesRow {
    grid-gap: 0.25em;
    gap: 0.25em;
    width: 170px;
  }
}
.fpass__questionBox {
  height: 0.5em;
  width: 0.5em;
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;
  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;
  /* The background will be the radio dot's color. */
  background: white;
  /* The border will be the spacing between the dot and the outer circle */
  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #b3b3b3;
}
@media (max-width: 640px) {
  .fpass__questionBox {
    height: 0.25em;
    width: 0.25em;
  }
}
.fpass__questionBox:checked {
  background: #b3b3b3;
  box-shadow: 0 0 0 1px #b3b3b3;
}
.fpass__questionBox-answered {
  background: #008375;
  box-shadow: 0 0 0 1px #008375;
}

.fpMultiChoice__question-count {
  text-align: center;
  margin-top: 4em;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: #999999;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .fpMultiChoice__question-count {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .fpMultiChoice__question-count {
    font-size: 0.75rem;
  }
}/*# sourceMappingURL=FP.css.map */
.paymentFrontPage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  padding: 0 0;
  max-width: 1920px;
  overflow: hidden;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .paymentFrontPage {
    padding: 0px 5%;
  }
}
.payment {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.payment__details {
  font-size: 24px;
  font-family: proxima-nova, sans-serif;
  color: #004E59;
  font-weight: 700;
}
@media (max-width: 640px) {
  .payment__details {
    font-size: 16px;
  }
}
.payment__form {
  width: 500px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .payment__form {
    width: 500;
  }
}
@media (max-width: 640px) {
  .payment__form {
    width: 90%;
  }
}
.payment__input {
  border-style: none;
  border-bottom: 1px solid #AFAFAF;
  font-size: 22px;
  font-family: proxima-nova, sans-serif;
  color: #616161;
  padding: 5px;
  background-color: transparent;
  margin-bottom: 10px;
  margin-left: 35px;
  width: 100%;
  flex-grow: 1;
}
@media (max-width: 640px) {
  .payment__input {
    font-size: 16px;
  }
}
.payment__box {
  padding-top: 20px;
  padding-bottom: 15px;
  display: flex;
  flex: row 1 nowrap;
  justify-content: center;
}
.payment__submit {
  background-color: #008375;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 24px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.payment__submit:hover {
  background-color: #004e59;
}
.payment__submit-load {
  background-color: #b3b3b3;
  color: white;
}
.payment__submit-load:hover {
  background-color: #b3b3b3;
}
@media (max-width: 640px) {
  .payment__submit {
    font-size: 16px;
  }
}

.group {
  display: flex;
  flex-flow: column nowrap;
}

.card {
  border-style: none;
  border-bottom: 1px solid #AFAFAF;
  font-size: calc(6px + 1.5vmin);
  font-family: proxima-nova, sans-serif;
  color: #616161;
  padding: calc(5px + 0.5vmin);
  background-color: transparent;
}

.collapsible__button {
  display: block;
  width: 100%;
  color: #004e59;
  background-color: transparent;
  border: none;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 15px;
}
@media (max-width: 640px) {
  .collapsible__button {
    font-size: 18px;
  }
}
.collapsible__content {
  align-items: left;
  overflow: hidden;
}
.collapsible__content.collapsed {
  display: none;
}
.collapsible__content.expanded {
  display: block;
}
.collapsible__line {
  width: 25%;
  color: #004e59;
  border-top: 1px solid;
  opacity: 1;
}
.collapsible__promo {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 30px;
  margin-bottom: 8px;
  margin-left: 45px;
}
.collapsible__promoButton {
  border: none;
  background-color: transparent;
  color: #004e59;
  text-decoration: underline;
  font-style: italic;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
}
.collapsible__promoOpen {
  display: flex;
  flex-flow: row nowrap;
}
.collapsible__text {
  font-family: proxima-nova, sans-serif;
  color: #004e59;
  font-weight: 600;
  font-size: 18px;
  align-self: center;
  margin-right: 10px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .collapsible__text {
    font-size: 14px;
  }
}
@media (max-width: 640px) {
  .collapsible__text {
    font-size: 12px;
  }
}
.collapsible__code {
  border: 1px solid grey;
  font-size: 18px;
  font-family: proxima-nova, sans-serif;
  color: #008375;
  height: 30px;
  width: 200px;
  padding-left: 5px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .collapsible__code {
    font-size: 14px;
    width: 150px;
  }
}
@media (max-width: 640px) {
  .collapsible__code {
    font-size: 12px;
    width: 100px;
  }
}
.collapsible__apply {
  font-family: proxima-nova, sans-serif;
  background-color: #008375;
  border: none;
  border-radius: 5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  margin-left: 20px;
}
.collapsible__message {
  color: #006db2;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  text-align: center;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .collapsible__message {
    font-size: 14px;
  }
}
@media (max-width: 640px) {
  .collapsible__message {
    font-size: 12px;
  }
}
.collapsible__card {
  border-style: none;
  border-bottom: 1px solid #AFAFAF;
  font-size: 18px;
  font-family: proxima-nova, sans-serif;
  color: #616161;
  padding: 10px;
  background-color: transparent;
  margin-left: 40px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .collapsible__card {
    font-size: 14px;
    padding: 8px;
  }
}
@media (max-width: 640px) {
  .collapsible__card {
    font-size: 12px;
    padding: 6px;
  }
}
.collapsible__review {
  margin-top: 25px;
  margin-left: 45px;
}
.collapsible__package {
  font-family: proxima-nova, sans-serif;
  display: flex;
  flex-flow: row nowrap;
}
.collapsible__packageTitle {
  font-size: 22px;
  color: #004e59;
}
.collapsible__packageTitle b {
  font-weight: 800;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .collapsible__packageTitle {
    font-size: 18px;
  }
}
@media (max-width: 640px) {
  .collapsible__packageTitle {
    font-size: 16px;
  }
}
.collapsible__italics {
  color: #999999;
  font-size: 14px;
  width: 65%;
  font-style: italic;
  margin-top: 3px;
  margin-bottom: 10px;
}
.collapsible__quantity {
  margin-left: 1em;
  margin-right: 1em;
}
.collapsible__dropdown {
  color: #808080;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  background-color: #e6e6e6;
  border: solid 1px black;
  border-radius: 5px;
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  min-width: 105px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}
.collapsible__dropdown:hover {
  cursor: pointer;
}
.collapsible__down {
  margin-left: 0.25em;
}
.collapsible__list {
  color: #808080;
  opacity: 0;
  position: absolute;
  right: 0%;
  top: 100%;
}
.collapsible__list-open {
  opacity: 1;
  background-color: #e6e6e6;
  overflow: auto;
  height: 150px;
  width: 50px;
  border: solid 1px black;
  border-radius: 5px;
}
.collapsible__listOption {
  text-align: right;
  padding-right: 0.25em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  font-size: 1.125rem;
}
.collapsible__listOption:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
@media (max-width: 640px) {
  .collapsible__listOption {
    font-size: 1rem;
    padding-right: 0.75em;
  }
}
.collapsible__delete {
  background-color: transparent;
  border: none;
  color: #009B8C;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  margin-bottom: 0.5em;
}
.collapsible__delete:hover {
  color: #003f4b;
}
.collapsible__giftBody {
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  line-height: 1.25em;
  color: #008375;
  margin-bottom: 0.25em;
}
.collapsible__giftBody-note {
  color: #999999;
  font-style: italic;
  font-size: 0.875rem;
}
.collapsible__emailList {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
}
.collapsible__giftEmail {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 100%;
}
.collapsible__emailText {
  font-family: proxima-nova, sans-serif;
  color: #004e59;
  font-size: 1rem;
  min-width: 75px;
}
.collapsible__emailInput {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  border-radius: 5px;
  border: 1px solid #cccccc;
  width: 100%;
  font-family: proxima-nova, sans-serif;
}
.collapsible__price {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-size: 22px;
}
.collapsible__price-out {
  text-decoration: line-through;
}
.collapsible__price-deal {
  color: red;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .collapsible__price {
    font-size: 18px;
  }
}
@media (max-width: 640px) {
  .collapsible__price {
    font-size: 16px;
  }
}
.collapsible__longLine {
  border-top: 2px solid #e6e6e6;
  margin-top: 1em;
  margin-bottom: 1.125em;
}
.collapsible__total {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: proxima-nova, sans-serif;
  color: #004e59;
  font-size: 1.5rem;
  font-weight: 600;
}
.collapsible__savings {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: proxima-nova, sans-serif;
  color: red;
  font-size: 1.125rem;
  margin-top: 0.5em;
}
.collapsible__savingsValue {
  font-weight: 600;
}

.options {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-self: center;
  grid-gap: 0.25em;
  gap: 0.25em;
  padding: 10px;
  min-width: 925px;
  max-width: 98vw;
  margin-bottom: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options {
    min-width: 600px;
  }
}
@media (max-width: 640px) {
  .options {
    flex-flow: column nowrap;
    min-width: auto;
  }
}
.options__image {
  max-width: 500px;
  margin-left: -2em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__image {
    width: 400px;
    margin-bottom: 0em;
  }
}
@media (max-width: 640px) {
  .options__image {
    width: 300px;
    margin-bottom: 0em;
  }
}
.options__main {
  display: flex;
  flex-flow: column wrap;
  margin-left: 2em;
  margin-right: 0em;
}
@media (max-width: 640px) {
  .options__main {
    margin-left: 0em;
    margin-right: 0em;
  }
}
.options__options {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: 1em;
  position: relative;
}
.options__singlePay {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}
.options__personalHover {
  position: absolute;
  top: -15%;
  color: white;
  font-size: 1rem;
  background-color: #004e59;
  font-weight: 400;
  padding: 0.5em;
  border: transparent;
  border-radius: 5px;
}
.options__select {
  background-color: white;
  border: none;
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-size: 1.25rem;
  color: #004e59;
  padding: 0.675em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-right: 0.05em;
  border: 2px solid #004e59;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 0.375em;
  border-top-right-radius: 0.375em;
  z-index: 100;
  position: relative;
}
.options__select:hover {
  cursor: default;
}
.options__select-off {
  color: #808080;
  border: 1px solid #808080;
  border-bottom: 1px solid transparent;
  z-index: 0;
}
.options__select-off:hover {
  cursor: pointer;
}
.options__select-disabled:hover {
  cursor: default;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__select {
    font-size: 1.5rem;
  }
}
@media (max-width: 640px) {
  .options__select {
    font-size: 0.95rem;
  }
}
.options__select::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: white;
}
.options__body {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  padding-bottom: 0.9em;
  text-align: left;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__body {
    font-size: 0.875rem;
    line-height: 1rem;
    padding-bottom: 1em;
  }
}
@media (max-width: 640px) {
  .options__body {
    font-size: 0.875rem;
    line-height: 1rem;
    padding-bottom: 0.75em;
  }
}
.options__body-bold {
  font-weight: 800;
  margin-left: 2em;
  padding-bottom: 0.2em;
}
.options__body-bold2 {
  font-weight: 800;
}
.options__body-flex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.options__body span {
  font-weight: 800;
  font-style: italic;
}
.options__body i {
  font-style: italic;
}
.options__hr {
  position: absolute;
  width: 25px;
  border: 0;
  border-top: 1px solid #004e59;
  top: calc(100% - 1px);
  left: -24px;
}
@media (max-width: 640px) {
  .options__hr {
    width: 15px;
    left: -14px;
  }
}
.options__hr2 {
  position: absolute;
  width: 50px;
  border: 0;
  border-top: 1px solid #004e59;
  top: calc(100% - 1px);
  left: 379px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__hr2 {
    width: 40px;
    left: 325px;
  }
}
@media (max-width: 640px) {
  .options__hr2 {
    width: 25px;
    left: 271px;
  }
}
.options__hr3 {
  position: absolute;
  width: 3px;
  border: 0;
  border-top: 1px solid #004e59;
  top: calc(100% - 1px);
  left: 216px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__hr3 {
    width: 3px;
    left: 185px;
  }
}
@media (max-width: 640px) {
  .options__hr3 {
    width: 3px;
    left: 155px;
  }
}
.options__hr3-wide {
  width: 215px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__hr3-wide {
    width: 315px;
    left: 185px;
  }
}
@media (max-width: 640px) {
  .options__hr3-wide {
    width: 165px;
    left: 155px;
  }
}
.options__hr4 {
  width: 100%;
  border-top: 2px solid #004e59;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 10;
}
.options__personal {
  display: flex;
  flex-flow: column nowrap;
  height: 375px;
  width: 430px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__personal {
    font-size: 0.875rem;
    line-height: 1rem;
    padding-bottom: 1em;
    width: 500px;
  }
}
@media (max-width: 640px) {
  .options__personal {
    font-size: 0.875rem;
    line-height: 1rem;
    padding-bottom: 0.75em;
    width: 80vw;
    height: auto;
  }
}
.options__gift {
  display: flex;
  flex-flow: column nowrap;
  height: 375px;
  width: 430px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__gift {
    font-size: 0.875rem;
    line-height: 1rem;
    padding-bottom: 1em;
    width: 500px;
  }
}
@media (max-width: 640px) {
  .options__gift {
    font-size: 0.875rem;
    line-height: 1rem;
    padding-bottom: 0.75em;
    width: 80vw;
    height: auto;
  }
}
.options__institute {
  display: flex;
  flex-flow: column nowrap;
  height: 375px;
  width: 430px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .options__institute {
    font-size: 0.875rem;
    line-height: 1rem;
    padding-bottom: 1em;
    width: 500px;
  }
}
@media (max-width: 640px) {
  .options__institute {
    font-size: 0.875rem;
    line-height: 1rem;
    padding-bottom: 0.75em;
    width: 80vw;
    height: auto;
  }
}
.options__giftOptions {
  display: flex;
  flex-flow: row nowrap;
}
.options__button {
  background-color: #008375;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: calc(12px + 1vmin);
  padding: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.options__button:hover {
  background-color: #901db4;
}
.options__buttonClicked {
  background-color: #008375;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: calc(12px + 1vmin);
  padding: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.options__buttonClicked-gray {
  background-color: gray;
  cursor: default;
}
.options__spinner {
  animation: spin infinite 2s linear;
  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
  font-size: 1em;
  color: white;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.options__testOptions {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin: 1em;
  position: relative;
}
.options__testHR {
  width: 100%;
  border-top: 1px solid #004e59;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -100;
}
.options__testButton {
  background-color: white;
  border: none;
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-size: 1.75rem;
  color: #004e59;
  padding: 0.675em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-right: 0.05em;
  border: 1px solid #004e59;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 0.375em;
  border-top-right-radius: 0.375em;
  z-index: 100;
}
.options__testButton:hover {
  cursor: default;
}

.paymentUnverified {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 1em;
  gap: 1em;
  max-width: 600px;
  margin: auto;
  text-align: left;
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  color: #004e59;
  margin-top: 10em;
  padding: 0% 10%;
}
.paymentUnverified__body {
  width: 100%;
  color: gray;
}
.paymentUnverified__body b {
  font-weight: 700;
}
.paymentUnverified__bodyButton {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.paymentUnverified__button {
  border: none;
  border-radius: 0.25em;
  color: white;
  background-color: #008375;
  padding: 0.5em 1em;
  margin: 0;
}
.paymentUnverified__image {
  width: 100px;
  align-self: center;
}

.noCode__main-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=PaymentDetails.css.map */
.studentPage__bodyText {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  margin-bottom: 1em;
  text-align: left;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .studentPage__bodyText {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
@media (max-width: 640px) {
  .studentPage__bodyText {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
.studentPage__bodyText b {
  font-weight: 800;
}
.studentPage__body {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 1em;
}
.studentPage__button {
  background-color: #008375;
  font-family: proxima-nova, sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  padding: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (max-width: 640px) {
  .studentPage__button {
    font-size: 1rem;
  }
}
.studentPage__button:hover {
  background-color: #901db4;
}
.studentPage__button-disabled {
  background-color: grey;
  color: black;
}
.studentPage__button-disabled:hover {
  background-color: grey;
}
.studentPage__button-success:hover {
  background-color: #008375;
}
.studentPage__input {
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: black;
  padding-left: 0.25em;
  width: 60%;
  border: 0.125em solid black;
  border-radius: 5px;
}
@media (max-width: 640px) {
  .studentPage__input {
    font-size: 0.75rem;
  }
}
.studentPage__errorMessage {
  width: 100%;
  text-align: center;
  margin-top: 0.5em;
  color: red;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
}/*# sourceMappingURL=StudentPage.css.map */
.paymentBottom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 0em;
  margin-top: 5em;
}
.paymentBottom__pic {
  width: 1920px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .paymentBottom__pic {
    width: 1650px;
  }
}
@media (max-width: 640px) {
  .paymentBottom__pic {
    width: 100%;
  }
}
.paymentBottom__button {
  border: none;
  background-color: transparent;
  position: absolute;
  border-radius: 3px;
}
.paymentBottom__button:hover {
  background-color: #92c9bc;
  opacity: 25%;
}
.paymentBottom__button-button1 {
  top: 42.85%;
  right: 40.6%;
  height: 43px;
  width: 204px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .paymentBottom__button-button1 {
    top: 42.85%;
    right: 40.9%;
    height: 2.1%;
    width: 18.2%;
    border-radius: 1px;
  }
}
@media (max-width: 640px) {
  .paymentBottom__button-button1 {
    top: 93%;
    right: 27.4%;
    height: 3.6%;
    width: 46.8%;
  }
}/*# sourceMappingURL=PaymentBottom.css.map */
.signedInMultiGift__topText {
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  color: #004E59;
  font-weight: 700;
}
.signedInMultiGift__row {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 1em;
  gap: 1em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedInMultiGift__row {
    grid-gap: 1em;
    gap: 1em;
  }
}
@media (max-width: 640px) {
  .signedInMultiGift__row {
    grid-gap: 0em;
    gap: 0em;
    flex-flow: row wrap;
  }
}
@media (max-width: 640px) {
  .signedInMultiGift__row-outer {
    flex-flow: column nowrap;
  }
}
.signedInMultiGift__col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
}
.signedInMultiGift__col-bottom {
  justify-content: flex-end;
}
.signedInMultiGift__imageBox {
  position: absolute;
  margin-left: -1em;
  z-index: -100;
}
@media (max-width: 640px) {
  .signedInMultiGift__imageBox {
    position: static;
    margin-left: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.signedInMultiGift__image {
  width: 200px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedInMultiGift__image {
    width: 200px;
  }
}
@media (max-width: 640px) {
  .signedInMultiGift__image {
    width: 250px;
  }
}
.signedInMultiGift__table {
  border-collapse: collapse;
  width: 100%;
  font-family: proxima-nova, sans-serif;
  color: #999999;
  font-size: 0.875rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .signedInMultiGift__table {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .signedInMultiGift__table {
    font-size: 0.75rem;
    min-width: 300px;
    max-width: 350px;
  }
}
.signedInMultiGift__table th, .signedInMultiGift__table td {
  padding: 8px;
  text-align: left;
}
.signedInMultiGift__table th {
  color: #808080;
  font-weight: 800;
}
.signedInMultiGift__table tr:last-child {
  border-bottom: none;
}
.signedInMultiGift__tableHeader {
  font-size: 1.25rem;
}
.signedInMultiGift__tableBody {
  font-size: 1.25rem;
  font-weight: 700;
  border-top: 3px solid;
  border-top-color: #e6e6e6;
}
.signedInMultiGift__tableBody-single {
  color: #4CBCB1;
}
.signedInMultiGift__tableBody-two {
  color: #00A99B;
}
.signedInMultiGift__tableBody-five {
  color: #009688;
}
.signedInMultiGift__tableBody-ten {
  color: #008375;
}
.signedInMultiGift__button {
  background-color: #008375;
  padding-top: 0.6em;
  padding-bottom: 0.5em;
  padding-right: 1em;
  padding-left: 0.5em;
  color: white;
  border: none;
  border-radius: 3px;
  text-align: left;
  width: 100%;
}
.signedInMultiGift__button:hover {
  background-color: #901db4;
}
.signedInMultiGift__button > span {
  font-weight: 700;
}
.signedInMultiGift__button-single {
  background-color: #4CBCB1;
}
.signedInMultiGift__button-two {
  background-color: #00A99B;
}
.signedInMultiGift__button-five {
  background-color: #009688;
}
.signedInMultiGift__button-ten {
  background-color: #008375;
}/*# sourceMappingURL=SignedInMultiGift.css.map */
.multiGift {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 10px;
  gap: 10px;
}
@media (max-width: 640px) {
  .multiGift {
    flex-flow: column wrap;
  }
}
.multiGift__topText {
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  color: #004E59;
  font-weight: 700;
}
.multiGift__row {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 1em;
  gap: 1em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .multiGift__row {
    grid-gap: 1em;
    gap: 1em;
  }
}
@media (max-width: 640px) {
  .multiGift__row {
    grid-gap: 0em;
    gap: 0em;
    flex-flow: row wrap;
  }
}
@media (max-width: 640px) {
  .multiGift__row-outer {
    flex-flow: column nowrap;
  }
}
.multiGift__col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
}
.multiGift__col-bottom {
  justify-content: flex-end;
}
.multiGift__imageBox {
  position: absolute;
  margin-left: -1em;
  z-index: -100;
}
@media (max-width: 640px) {
  .multiGift__imageBox {
    position: static;
    margin-left: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.multiGift__image {
  width: 200px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .multiGift__image {
    width: 200px;
  }
}
@media (max-width: 640px) {
  .multiGift__image {
    width: 250px;
  }
}
.multiGift__table {
  border-collapse: collapse;
  width: 100%;
  font-family: proxima-nova, sans-serif;
  color: #999999;
  font-size: 0.875rem;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .multiGift__table {
    font-size: 0.875rem;
  }
}
@media (max-width: 640px) {
  .multiGift__table {
    font-size: 0.75rem;
    min-width: 300px;
    max-width: 350px;
  }
}
.multiGift__table th, .multiGift__table td {
  padding: 8px;
  text-align: left;
}
.multiGift__table th {
  color: #808080;
  font-weight: 800;
}
.multiGift__table tr:last-child {
  border-bottom: none;
}
.multiGift__tableHeader {
  font-size: 1.25rem;
}
.multiGift__tableBody {
  font-size: 1.25rem;
  font-weight: 700;
  border-top: 3px solid;
  border-top-color: #e6e6e6;
}
.multiGift__tableBody-single {
  color: #4CBCB1;
}
.multiGift__tableBody-two {
  color: #00A99B;
}
.multiGift__tableBody-five {
  color: #009688;
}
.multiGift__tableBody-ten {
  color: #008375;
}
.multiGift__button {
  background-color: #008375;
  padding-top: 0.6em;
  padding-bottom: 0.5em;
  padding-right: 1em;
  padding-left: 0.5em;
  color: white;
  border: none;
  border-radius: 3px;
  text-align: left;
  width: 100%;
}
.multiGift__button:hover {
  background-color: #901db4;
}
.multiGift__button > span {
  font-weight: 700;
}
.multiGift__button-single {
  background-color: #4CBCB1;
}
.multiGift__button-two {
  background-color: #00A99B;
}
.multiGift__button-five {
  background-color: #009688;
}
.multiGift__button-ten {
  background-color: #008375;
}/*# sourceMappingURL=MultiGift.css.map */
.paymentSignedOut__mainBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .paymentSignedOut__mainBox {
    padding-top: 5em;
  }
}
@media (max-width: 640px) {
  .paymentSignedOut__mainBox {
    padding-top: 0em;
  }
}/*# sourceMappingURL=PaymentSignedOut.css.map */
.studentSignedOut {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 10px;
  gap: 10px;
  font-family: proxima-nova, sans-serif;
  color: #004e59;
}
@media (max-width: 640px) {
  .studentSignedOut {
    flex-flow: column wrap;
  }
}
.studentSignedOut__button {
  border: none;
  background-color: transparent;
  color: #004e59;
  font-weight: 800;
}
.studentSignedOut__button:hover {
  color: #901db4;
  text-decoration: underline;
}/*# sourceMappingURL=StudentSignedOut.css.map */
.footer {
  text-align: left;
  background-color: #004E59;
  color: white;
  padding: 1em;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2em;
  font-family: proxima-nova, sans-serif;
}
.footer-dark {
  background-color: #163c66;
  color: #bbe1f5;
}
@media (max-width: 640px) {
  .footer {
    padding-left: 5%;
    padding-right: 5%;
  }
}
.footer__top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: flex-start;
}
.footer__column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 640px) {
  .footer__column {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.footer__h1 {
  font-size: 0.625rem;
  font-weight: 600;
  color: #A4C1C4;
  margin-bottom: 1em;
}
@media (max-width: 640px) {
  .footer__h1 {
    font-size: 0.5rem;
  }
}
.footer__item {
  font-size: 0.625rem;
  color: #8AABB0;
  margin-bottom: 1em;
  text-decoration: none;
}
.footer__item:hover {
  color: white;
}
.footer__item-nolink:hover {
  color: #8AABB0;
}
@media (max-width: 640px) {
  .footer__item {
    font-size: 0.5rem;
  }
}
.footer__bottom {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.footer__hr {
  border-top: 1px solid #8AABB0;
  width: 90%;
  height: 1px;
  margin-top: 2em;
  margin-bottom: 1em;
}/*# sourceMappingURL=Footer.css.map */
.error {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.error__title {
  font-family: garamond-premier-pro, serif;
  color: #004e59;
  font-size: 40px;
  font-weight: 800;
}
.error__body {
  font-family: proxima-nova, sans-serif;
  color: #808080;
  margin-top: 10px;
}
.error__image {
  width: 400px;
  margin-top: 5px;
}/*# sourceMappingURL=Error.css.map */
.profile {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 10em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .profile {
    margin-top: 3em;
  }
}
@media (max-width: 640px) {
  .profile {
    margin-top: 2em;
  }
}
.profile__main {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.profile__account {
  width: 600px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .profile__account {
    width: 500px;
  }
}
@media (max-width: 640px) {
  .profile__account {
    width: 275px;
  }
}
.profile__heading1 {
  border-style: none;
  border-bottom: 1px solid #AFAFAF;
  font-size: 1.75rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  color: #004e59;
  padding: 0.25em;
  padding-bottom: 0.5em;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .profile__heading1 {
    font-size: 1.75rem;
  }
}
@media (max-width: 640px) {
  .profile__heading1 {
    font-size: 1.25rem;
  }
}
.profile__heading2 {
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  color: #004e59;
  width: 150px;
}
@media (max-width: 640px) {
  .profile__heading2 {
    font-size: 0.75rem;
    width: 75px;
  }
}
.profile__row {
  border-style: none;
  border-bottom: 1px solid #AFAFAF;
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  color: #004e59;
  padding: 0.25em;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
}
.profile__data {
  font-weight: 400;
  color: #999999;
}
@media (max-width: 640px) {
  .profile__data {
    font-size: 0.75rem;
  }
}
.profile__data-input {
  margin: 0;
  padding: 0;
  line-height: 0;
  font-size: 1rem;
  margin-top: calc(-0.5em - 1px);
  margin-bottom: calc(-0.5em - 1px);
  padding: 0.25em;
  padding-left: 0.5em;
  margin-left: calc(-0.5em - 1px);
  border: 1px solid #e6e6e6;
  border-radius: 0.375em;
}
@media (max-width: 640px) {
  .profile__data-input {
    font-size: 0.75rem;
  }
}
.profile__data-dropdown {
  margin: 0;
  padding: 0;
  line-height: 0;
  font-size: 1rem;
  margin-top: calc(-0.5em - 1px);
  margin-bottom: calc(-0.5em - 1px);
  padding: 0.25em;
  padding-left: 0.5em;
  margin-left: calc(-0.5em - 1px);
  margin-right: 1em;
  border: 1px solid #e6e6e6;
  border-radius: 0.375em;
}
@media (max-width: 640px) {
  .profile__data-dropdown {
    font-size: 0.75rem;
  }
}
.profile__edit {
  position: absolute;
  left: 102%;
  color: #008375;
  font-weight: 500;
  font-size: 0.875rem;
  background-color: transparent;
  border: none;
  text-decoration: underline;
}
.profile__edit:hover {
  color: #003f4b;
}
@media (max-width: 640px) {
  .profile__edit {
    font-size: 0.75rem;
  }
}
.profile__edit-on {
  text-decoration: none;
  background-color: #008375;
  color: white;
  font-size: 1.75rem;
  padding: 0.5em;
  padding-bottom: 0em;
  padding-top: 0em;
  margin-bottom: 1em;
  margin-top: -0.2em;
  margin-left: -0.45em;
  border: none;
  border-radius: 0.125em;
  display: inline-block;
  transform: scale(0.6, 1.1);
}
@media (max-width: 640px) {
  .profile__edit-on {
    font-size: 1.25rem;
  }
}
.profile__edit-on:hover {
  background-color: #003f4b;
  color: white;
}
.profile__contact {
  color: #999999;
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
  padding-top: 2em;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
}
.profile__contact span {
  color: #008375;
  font-weight: 500;
}

.profile__popup {
  background-color: #008375;
  width: 450px;
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  padding-top: 0px;
  padding-bottom: 1em;
}
@media (max-width: 640px) {
  .profile__popup {
    width: 325px;
  }
}

.profile__popupClose {
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-size: 1.875rem;
  color: #ffffff;
  border-style: none;
  align-self: flex-end;
  margin-right: -0.125em;
}
@media (max-width: 640px) {
  .profile__popupClose {
    font-size: 1.375rem;
    margin-right: -0.2em;
  }
}

.profile__popupPrimary {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 640px) {
  .profile__popupPrimary {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

.profile__security {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
  color: #999999;
  width: 190px;
  margin-top: 25em;
  margin-bottom: 2em;
}

.profile__image {
  width: 30px;
  height: 30px;
}

.profile__secureText {
  font-size: 0.625rem;
  margin-left: 0.5em;
}/*# sourceMappingURL=Profile.css.map */
.table th {
  background: #fff;
  padding: 8px 10px;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  text-align: left;
}

.table__button {
  width: 250px;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em 0.5em;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  color: white;
  font-weight: 500;
  background-color: #004e59;
}
.table__button:hover {
  background-color: #006db2;
}

.tableHead__th {
  font-family: proxima-nova, sans-serif;
}
.tableHead__th:hover {
  cursor: pointer;
}
.tableHead__icon {
  vertical-align: bottom;
  padding-left: 0.25em;
}/*# sourceMappingURL=TableHead.css.map */
.tableBody__button {
  background-color: transparent;
  border: none;
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  text-decoration: none;
}
.tableBody__button:hover {
  color: #006db2;
  text-decoration: underline;
}/*# sourceMappingURL=TableBody.css.map */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-family: proxima-nova, sans-serif;
}
.popup-content h2 {
  color: black;
  font-size: 1.25rem;
}
.popup-content h2 b {
  font-weight: 800;
  color: black;
}

.popup-buttons {
  display: flex;
  grid-gap: 5rem;
  gap: 5rem;
}

.popup-button {
  margin-top: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  background-color: #004e59;
  color: white;
  border-radius: 4px;
}
.popup-button-close {
  background-color: gray;
  color: white;
}

.close-button {
  margin-top: 10px;
  padding: 5px;
  cursor: pointer;
}/*# sourceMappingURL=Popup.css.map */
.table th {
  background: #fff;
  padding: 8px 10px;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  text-align: left;
}

.codeTableHead__th {
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
}
.codeTableHead__th:hover {
  cursor: pointer;
}
.codeTableHead__icon {
  vertical-align: bottom;
  padding-left: 0.25em;
}/*# sourceMappingURL=TableHead.css.map */
.tableBody__button {
  background-color: transparent;
  border: none;
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  text-decoration: none;
}
.tableBody__button:hover {
  color: #006db2;
  text-decoration: underline;
}

.codeTableBody__cell {
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
}/*# sourceMappingURL=TableBody.css.map */
.dataPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}
.dataPopup__content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%; /* Set the maximum width you desire */
  max-height: 80vh; /* Set the maximum height you desire */
  display: flex;
  flex-direction: column;
}
.dataPopup__closeButton {
  align-self: flex-end;
  background-color: transparent;
  border: none;
  color: gray;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  font-family: proxima-nova, sans-serif;
}
.dataPopup__closeButton:hover {
  color: black;
}
.dataPopup__children {
  flex: 1 1;
  overflow: auto;
}
.dataPopup__header {
  font-family: garamond-premier-pro, serif;
  font-size: 1.5rem;
  font-weight: 800;
  color: #004e59;
  margin: 0em 0em 1em;
}
.dataPopup__form {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 1em;
  gap: 1em;
}
.dataPopup__row {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 2em;
  gap: 2em;
  align-self: center;
  width: 100%;
}
.dataPopup__row-error {
  height: 1rem;
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
  color: red;
  font-weight: 700;
}
.dataPopup__col {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 1em;
  gap: 1em;
}
.dataPopup__inputLabel {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 0.25em;
  gap: 0.25em;
  color: black;
  font-family: proxima-nova, sans-serif;
}
.dataPopup__input {
  padding-left: 0.25em;
}
.dataPopup__input-readOnly {
  color: #808080;
}
.dataPopup__input-date {
  height: 1.68em;
}
.dataPopup__button {
  padding: 0.375em 0.75em 0.375em;
  border: none;
  border-radius: 5px;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
}
.dataPopup__button:hover {
  background-color: #663190;
  color: white;
}
.dataPopup__button-submit {
  background-color: #008375;
  color: white;
}
.dataPopup__button-close {
  background-color: #e6e6e6;
}
.dataPopup__button-disabled {
  background-color: #e6e6e6;
  color: #999999;
}
.dataPopup__button-disabled:hover {
  background-color: #e6e6e6;
  color: #999999;
}/*# sourceMappingURL=DataPopup.css.map */
.table th {
  background: #fff;
  padding: 8px 10px;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  text-align: left;
}

.codeTableHead__th {
  font-family: proxima-nova, sans-serif;
  font-size: 0.875rem;
}
.codeTableHead__th:hover {
  cursor: pointer;
}
.codeTableHead__icon {
  vertical-align: bottom;
  padding-left: 0.25em;
}/*# sourceMappingURL=TableHead.css.map */
.tableBody__button {
  background-color: transparent;
  border: none;
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  text-decoration: none;
}
.tableBody__button:hover {
  color: #006db2;
  text-decoration: underline;
}

.codeTableBody__cell {
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
}/*# sourceMappingURL=TableBody.css.map */
.codeTable__header {
  margin-bottom: 1em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.codeTable__button {
  padding: 0.375em 0.75em 0.375em;
  border: none;
  border-radius: 5px;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
}
.codeTable__button:hover {
  background-color: #663190;
  color: white;
}
.codeTable__button-on {
  background-color: #008375;
  color: white;
}
.codeTable__button-off {
  background-color: #e6e6e6;
}
.codeTable__archived {
  margin-left: 1em;
  font-size: 1rem;
  font-family: proxima-nova, sans-serif;
}
.codeTable__checkbox {
  accent-color: #008375; /* IE 9 */ /* Chrome, Safari, Opera */
  transform: scale(1.25);
}
.codeTable__checkbox:hover {
  cursor: pointer;
}/*# sourceMappingURL=CodeTable.css.map */
.codePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}
.codePopup__content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%; /* Set the maximum width you desire */
  max-height: 80vh; /* Set the maximum height you desire */
  display: flex;
  flex-direction: column;
}
.codePopup__closeButton {
  align-self: flex-end;
  background-color: transparent;
  border: none;
  color: gray;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  font-family: proxima-nova, sans-serif;
}
.codePopup__closeButton:hover {
  color: black;
}
.codePopup__children {
  flex: 1 1;
  overflow: auto;
}
.codePopup__header {
  font-family: garamond-premier-pro, serif;
  font-size: 1.5rem;
  font-weight: 800;
  color: #004e59;
  margin: 0em 0em 1em;
}
.codePopup__form {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 1em;
  gap: 1em;
}
.codePopup__row {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 2em;
  gap: 2em;
  align-self: center;
  width: 100%;
}
.codePopup__row-error {
  height: 1rem;
  font-size: 0.75rem;
  font-family: proxima-nova, sans-serif;
  color: red;
  font-weight: 700;
}
.codePopup__col {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 1em;
  gap: 1em;
}
.codePopup__inputLabel {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 0.25em;
  gap: 0.25em;
  color: black;
  font-family: proxima-nova, sans-serif;
}
.codePopup__input {
  padding-left: 0.25em;
}
.codePopup__input-readOnly {
  color: #808080;
}
.codePopup__input-date {
  height: 1.68em;
}
.codePopup__button {
  padding: 0.375em 0.75em 0.375em;
  border: none;
  border-radius: 5px;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
}
.codePopup__button:hover {
  background-color: #663190;
  color: white;
}
.codePopup__button-submit {
  background-color: #008375;
  color: white;
}
.codePopup__button-close {
  background-color: #e6e6e6;
}
.codePopup__button-disabled {
  background-color: #e6e6e6;
  color: #999999;
}
.codePopup__button-disabled:hover {
  background-color: #e6e6e6;
  color: #999999;
}/*# sourceMappingURL=CodePopup.css.map */
.adminFrontPage {
  width: 950px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .adminFrontPage {
    width: 625px;
  }
}
@media (max-width: 640px) {
  .adminFrontPage {
    width: 325px;
  }
}

.admin {
  width: 100%;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
}
.admin__topRow {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 2em;
}
.admin__button {
  padding: 0.375em 0.75em 0.375em;
  border: none;
  border-radius: 5px;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
}
.admin__button:hover {
  background-color: #663190;
  color: white;
}
.admin__button-on {
  background-color: #008375;
  color: white;
}
.admin__button-off {
  background-color: #e6e6e6;
}
.admin__header h1 {
  font-size: 2rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  color: #004e59;
}
.admin__header h2 {
  font-size: 1.5rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  color: #006db2;
  text-align: center;
}
.admin__summary {
  margin-top: 1em;
  margin-bottom: 1em;
}
.admin__summary p {
  font-size: 1rem;
  color: black;
  font-family: proxima-nova, sans-serif;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.admin__table {
  border: 1px solid black;
  margin-top: 1em;
  margin-bottom: 2em;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  text-align: center;
  width: 1200px;
  align-self: center;
}
.admin__table thead {
  background-color: #006db2;
  color: white;
  font-size: 1.25rem;
  font-weight: 800;
}
.admin__table th {
  padding: 0.5em 0.25em;
}
.admin__table td {
  padding: 0.5em 0.25em;
}/*# sourceMappingURL=Admin.css.map */
.privacy {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 25px;
  padding-bottom: 50px;
}
.privacy h1 {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.privacy h2 {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-size: 1rem;
  width: 100%;
  text-align: left;
  margin-top: 0.5em;
}
.privacy strong {
  font-weight: 800;
}
.privacy p {
  font-family: proxima-nova, sans-serif;
  color: #808080;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.privacy li {
  font-family: proxima-nova, sans-serif;
  color: #808080;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  list-style-type: circle;
  margin-left: 2em;
}/*# sourceMappingURL=Privacy.css.map */
.terms {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 25px;
  padding-bottom: 50px;
}
.terms h2 {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.terms h3 {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  width: 100%;
  text-align: left;
  margin-top: 0.5em;
}
.terms strong {
  font-weight: 800;
}
.terms p {
  font-family: proxima-nova, sans-serif;
  color: #808080;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.terms li {
  font-family: proxima-nova, sans-serif;
  color: #808080;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  list-style-type: circle;
  margin-left: 2em;
}/*# sourceMappingURL=Terms.css.map */
.app {
  text-decoration: none;
}

.app__main {
  background-image: linear-gradient(to bottom, rgba(253, 246, 234, 0.9921568627) 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(to bottom, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 100%);
  background-size: 100% 325px, 100% auto;
  background-position: 0 0, 0 auto;
  background-repeat: no-repeat;
  min-height: 100vh;
}/*# sourceMappingURL=App.css.map */
.navbar {
  padding: 15px 2%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .navbar {
    padding-top: 8px;
  }
}
@media (max-width: 640px) {
  .navbar {
    padding-top: 8px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .navbar__dark {
    display: flex;
    flex-flow: column nowrap;
  }
}
@media (max-width: 640px) {
  .navbar__dark {
    display: flex;
    flex-flow: column nowrap;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .navbar__dark-topRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
@media (max-width: 640px) {
  .navbar__dark-topRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
.navbar__wrapper {
  display: flex;
  flex-flow: column nowrap;
}
.navbar__logo {
  width: 125px;
  margin-left: 20px;
  margin-right: 60px;
  padding-bottom: 10px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .navbar__logo {
    padding-bottom: 0px;
  }
}
@media (max-width: 640px) {
  .navbar__logo {
    padding-bottom: 0px;
  }
}
.navbar__left {
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 1007px) {
  .navbar__left {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: center;
  }
}
.navbar__link {
  color: #004e59;
  font-family: proxima-nova, sans-serif;
  margin-right: 10px;
  margin-left: 10px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.navbar__link-signedOut {
  width: 87px;
}
.navbar__link-signedOutBold {
  width: 92px;
}
@media (max-width: 640px) {
  .navbar__link {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .navbar__link {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.navbar__link:hover {
  color: #3A7AC9;
  text-decoration: none;
  cursor: pointer;
}
.navbar__link-dark {
  color: #3d7cca;
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar__link-dark:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
@media (min-width: 1007px) {
  .navbar__link-right {
    margin-right: 0px;
    margin-left: 0px;
  }
}
.navbar__right {
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 1007px) {
  .navbar__right {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    align-items: center;
  }
}
.navbar__button {
  background-color: #004E59;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  font-family: proxima-nova, sans-serif;
}
.navbar__button:hover {
  background-color: #008375;
  text-decoration: none;
}
.navbar__button-dark {
  background-color: #3d7cca;
}
.navbar__button-dark:hover {
  background: white;
  color: #3d7cca;
}
.navbar__name {
  color: #3d7cca;
  font-family: proxima-nova, sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .navbar__name {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
  }
}
@media (max-width: 640px) {
  .navbar__name {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
  }
}
.navbar__dropdown {
  width: 104%;
  text-align: center;
  max-height: 0px;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
}
.navbar__dropdown-open {
  max-height: 200px;
  background-color: #001343;
  transition: max-height 2s ease 0.1s;
}
.navbar__dropdown-close {
  max-height: 0px;
  transition: max-height 1s ease 0s;
  -webkit-transition: all 1s ease 0s;
}
.navbar__dropdownItem {
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #006db2;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  min-width: 100px;
  height: 100%;
}
.navbar__image {
  background-color: transparent;
  max-height: 35px;
  width: auto;
}
.navbar__dropdownText {
  padding-top: 0.5em;
  font-size: 0.875rem;
}
.navbar__dropdownText-white {
  color: white;
}
.navbar__user {
  position: relative;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .navbar__user {
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    align-self: center;
  }
}
@media (max-width: 640px) {
  .navbar__user {
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    align-self: center;
  }
}
.navbar__userDropdown {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  right: 0%;
  width: 100%;
  font-size: 0.875rem;
  margin-right: 28px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .navbar__userDropdown {
    margin-right: 17px;
  }
}
@media (max-width: 640px) {
  .navbar__userDropdown {
    margin-right: 17px;
  }
}
.navbar__userElement {
  padding-top: 0em;
  padding-bottom: 0.25em;
  font-family: proxima-nova, sans-serif;
  color: #004e59;
  text-decoration: none;
}
.navbar__userElement:hover {
  color: #3d7cca;
}
.navbar__userElement-dark {
  color: #3d7cca;
}
.navbar__userElement-dark:hover {
  color: white;
}

.navbar-dark {
  background-image: linear-gradient(to bottom, #20427F 0%, #102960 100%), linear-gradient(to bottom, #102960 100%, #102960 100%);
  background-size: 100% auto, 100% auto;
  background-position: 0 0, 0 auto;
  background-repeat: no-repeat;
}

.navbar-container {
  margin-left: 10px;
  margin-left: 10px;
}

.navbar-contents {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.navbar-main__box {
  margin-top: 15px;
}

.navbar-main__dark {
  color: #3D7CCA;
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  margin-right: 20px;
  margin-left: 20px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-main__dark:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.navbar-main__dropdown {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  margin-right: 20px;
  margin-left: 20px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-main__dropdown:hover {
  color: #004E59;
  text-decoration: none;
  cursor: default;
}

.navbar-main__dropdown + div {
  background-image: linear-gradient(to bottom, rgba(253, 246, 234, 0.9921568627) 10%, #FFFFFF 120%), linear-gradient(to bottom, #ffffff 25%, #FFFFFF 100%);
  border: none;
  border-radius: 10px;
  align-items: left;
}

.navbar-main__dropdown:after {
  display: none !important;
}

.navbar-main__dropdown-item {
  color: #008375;
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-main__dropdown-item:hover {
  color: #3A7AC9;
  text-decoration: none;
  cursor: pointer;
}

.navbar-main__dropdown-dark {
  color: #3D7CCA;
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  margin-right: 20px;
  margin-left: 20px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-main__dropdown-dark:hover {
  color: #3D7CCA;
  text-decoration: none;
  cursor: default;
}

.navbar-main__dropdown-dark + div {
  background-image: linear-gradient(to bottom, #153069 0%, #102960 100%), linear-gradient(to bottom, #102960 100%, #102960 100%);
  border: none;
  align-items: left;
}

.navbar-main__dropdown-dark:after {
  display: none !important;
}

.navbar-main__dropdown-dark-item {
  color: #67AAFC;
  background-color: transparent;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: left;
  align-items: left;
}
.navbar-main__dropdown-dark-item:hover {
  color: white;
  text-decoration: none;
}

.navbar-main-off {
  color: gray;
  font-family: proxima-nova, sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
}

.navbar-main-2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.me-auto {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1007px) {
  .me-auto {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

.navbar__scroll {
  max-width: 100vw;
}

.navbar__scrollMenu {
  display: flex;
  flex-flow: column nowrap;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.navbar__arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 1%;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  color: #b3b3b3;
  border: none;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.25rem;
}
.navbar__arrow-left {
  border-right: 1px solid #cccccc;
}
.navbar__arrow-right {
  border-left: 1px solid #b3b3b3;
}/*# sourceMappingURL=Navbar.css.map */
.forgotpassword {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 125px;
}
@media (max-width: 640px) {
  .forgotpassword {
    margin-top: 75px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .forgotpassword {
    margin-top: 75px;
  }
}
.forgotpassword__title {
  color: #004E59;
  font-family: proxima-nova, sans-serif;
  font-weight: 650;
  font-size: 30px;
  margin-bottom: 10px;
}
@media (max-width: 640px) {
  .forgotpassword__title {
    font-size: 22px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .forgotpassword__title {
    font-size: 22px;
  }
}
.forgotpassword__subtitle {
  color: #999999;
  font-family: proxima-nova;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5rem;
  margin-bottom: 30px;
  text-align: left;
}
@media (max-width: 640px) {
  .forgotpassword__subtitle {
    font-size: 12px;
    line-height: 1rem;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .forgotpassword__subtitle {
    font-size: 14px;
    line-height: 1rem;
  }
}
.forgotpassword__form {
  display: flex;
  flex-flow: column;
  width: 275px;
}
.forgotpassword__message {
  color: red;
  font-family: proxima-nova;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-bottom: 5px;
  position: absolute;
  left: 10%;
  right: 10%;
}
@media (max-width: 640px) {
  .forgotpassword__message {
    font-size: 12px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .forgotpassword__message {
    font-size: 14px;
  }
}
.forgotpassword__message-good {
  color: #74c8be;
}
.forgotpassword__input {
  background-color: #E8E8E8;
  border-style: none;
  font-size: 20px;
  font-family: proxima-nova, sans-serif;
  color: #616161;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 1em;
  margin-top: 1em;
  align-self: center;
  width: 100%;
}
.forgotpassword__input::placeholder {
  color: #a0a0a0;
}
@media (max-width: 640px) {
  .forgotpassword__input {
    font-size: 14px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .forgotpassword__input {
    font-size: 18px;
  }
}
.forgotpassword__button {
  background-color: #008375;
  border: none;
  border-radius: 4px;
  color: white;
  font-family: proxima-nova;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 640px) {
  .forgotpassword__button {
    font-size: 12px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .forgotpassword__button {
    font-size: 14px;
  }
}
.forgotpassword__signup {
  color: #999999;
  font-size: 12px;
  font-family: proxima-nova;
  margin-top: 15px;
}
@media (max-width: 640px) {
  .forgotpassword__signup {
    font-size: 10px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .forgotpassword__signup {
    font-size: 10px;
  }
}
.forgotpassword__signup-link {
  color: #008375;
}
.forgotpassword__signup-link::placeholder {
  color: #009ade;
}
.forgotpassword__signin {
  margin-top: 25px;
  color: #999999;
  font-family: proxima-nova;
  font-size: 16px;
  font-weight: 500;
}
.forgotpassword::placeholder {
  color: #009ade;
}
@media (max-width: 640px) {
  .forgotpassword {
    font-size: 12px;
  }
}
@media (min-width: 640px) and (max-width: 1007px) {
  .forgotpassword {
    font-size: 16px;
  }
}/*# sourceMappingURL=ForgotPassword.css.map */
.landingPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 0em;
}
.landingPage__top {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.landingPage__bottom {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.landingPage__topPic {
  width: 1007px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .landingPage__topPic {
    width: 90%;
  }
}
@media (max-width: 640px) {
  .landingPage__topPic {
    width: 100%;
  }
}
.landingPage__botPic {
  width: 1007px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .landingPage__botPic {
    width: 90%;
  }
}
@media (max-width: 640px) {
  .landingPage__botPic {
    width: 100%;
  }
}
.landingPage__button {
  border: none;
  background-color: transparent;
  position: absolute;
  border-radius: 3px;
}
.landingPage__button:hover {
  background-color: #92c9bc;
  opacity: 25%;
}
.landingPage__button-button1 {
  top: 57.7%;
  right: 20.2%;
  height: 36px;
  width: 196px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .landingPage__button-button1 {
    top: 57.7%;
    right: 23%;
    height: 8.75%;
    width: 17.68%;
  }
}
@media (max-width: 640px) {
  .landingPage__button-button1 {
    top: 66.5%;
    right: 8%;
    height: 12.6%;
    width: 26.6%;
  }
}
.landingPage__button-button2 {
  top: 35.58%;
  right: 39.9%;
  height: 42px;
  width: 204px;
}
.landingPage__button-button2:hover {
  background-color: #92c9bc;
  opacity: 45%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .landingPage__button-button2 {
    top: 35.58%;
    right: 40.9%;
    height: 1.72%;
    width: 18.15%;
  }
}
@media (max-width: 640px) {
  .landingPage__button-button2 {
    top: 38.42%;
    right: 26.6%;
    height: 0.927%;
    width: 46.9%;
  }
}
.landingPage__button-button3 {
  top: 61%;
  right: 14.7%;
  height: 43px;
  width: 204px;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .landingPage__button-button3 {
    top: 61%;
    right: 18.2%;
    height: 1.72%;
    width: 18.15%;
  }
}
@media (max-width: 640px) {
  .landingPage__button-button3 {
    top: 57.4%;
    right: 26.4%;
    height: 0.927%;
    width: 46.9%;
  }
}
.landingPage__button-button4 {
  top: 96.9%;
  right: 39.9%;
  width: 203px;
  height: 43px;
}
.landingPage__button-button4:hover {
  background-color: #655dc6;
  opacity: 25%;
}
@media (min-width: 640px) and (max-width: 1007px) {
  .landingPage__button-button4 {
    top: 96.9%;
    right: 40.95%;
    height: 1.73%;
    width: 18.15%;
  }
}
@media (max-width: 640px) {
  .landingPage__button-button4 {
    top: 98.17%;
    right: 27.45%;
    height: 0.93%;
    width: 46.7%;
  }
}/*# sourceMappingURL=LandingPage.css.map */
:root {
  --allports: #006db2;
  --black: #000000;
  --black-2: #000000bf;
  --cerulean: #009ade1a;
  --downy: #74c9c0;
  --granny-apple: #d6eae5;
  --gray: #919191;
  --gray-nurse: #e8e8e8;
  --magic-potion: #001343;
  --neptune: #72bcb5;
  --observatory: #008375;
  --opal: #a5cbc1;
  --quick-silver: #a5a5a5;
  --quill-gray: #d6d6d6;
  --sherpa-blue: #004e59;
  --silver: #bcbcbc;
  --silver-chalice: #afafaf;
  --silver-chalice-2: #acacac;
  --sonic-silver: #717171;
  --star-dust: #9d9d9d;
  --star-dust-2: #a0a0a0;
  --tropical-rain-forest: #00796027;
  --violet-eggplant: #901db4;
  --white: #ffffff;
  --x11-2: #f5ea61;
  --x13-2: #f4a81d;
  --x15-2: #ff6b00;
  --x17-2: #ffb4ab;
  --x19-2: #c39b82;
  --x21-2: #ff647d;
  --x23-2: #db0032;
  --x25-2: #fca0c9;
  --x27-2: #df1683;
  --x29-2: #e95ebe;
  --x31-2: #a05cbf;
  --x33-2: #c2a6e1;
  --x35-2: #655dc6;
  --x37-2: #8cb7c9;
  --x39-2: #3d7cca;
  --x41-2: #0047ba;
  --x43-2: #009ade;
  --x45-2: #0099a8;
  --x47-2: #007960;
  --x49-2: #00ae42;
  --x51-2: #93d500;
  --x53-2: #fcd199;

  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 24px;
  --font-size-xxl: 30px;
  --font-size-xxxl: 36px;
  --font-size-xxxxl: 48px;

  --font-family-arial-regular: "Arial-Regular", Helvetica;
  --font-family-garamondpremier_pro-semibold: "GaramondPremier Pro-Semibold", Helvetica;
  --font-family-proxima_nova: "Proxima Nova", Helvetica;
  --font-family-proximanova-black: "ProximaNova-Black", Helvetica;
  --font-family-proximanova-bold: "ProximaNova-Bold", Helvetica;
  --font-family-proximanova-italic: "ProximaNova-Italic", Helvetica;
  --font-family-proximanova-light: "ProximaNova-Light", Helvetica;
  --font-family-proximanova-medium: "ProximaNova-Medium", Helvetica;
  --font-family-proximanova-regular: "ProximaNova-Regular", Helvetica;
  --font-family-proximanova-semibold: "ProximaNova-Semibold", Helvetica;
}
.proximanova-medium-observatory-18px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}
.proximanova-medium-sherpa-blue-20px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-medium);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-medium-steel-blue-20px {
  color: var(--x39-2);
  font-family: var(--font-family-proximanova-medium);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-black-14px {
  color: var(--black);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-medium-silver-chalice-24px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-black-black-16px {
  color: var(--black);
  font-family: var(--font-family-proximanova-black);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 900;
}
.proximanova-medium-silver-chalice-20px {
  color: var(--silver-chalice-2);
  font-family: var(--font-family-proximanova-medium);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-white-15px {
  color: var(--white);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-sherpa-blue-48px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-black-sherpa-blue-48px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxxxl);
}
.proximanova-black-sherpa-blue-48px-2 {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-black);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 900;
}
.proximanova-regular-normal-star-dust-14px {
  color: var(--star-dust-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-medium-white-24px {
  color: var(--white);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-white-12px {
  color: var(--white);
  font-family: var(--font-family-proximanova-bold);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.garamondpremierpro-semi-bold-sherpa-blue-60px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-garamondpremier_pro-semibold);
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
}
.proximanova-bold-silver-chalice-16px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-silver-chalice-16px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-sherpa-blue-24px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-observatory-30px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-observatory-30px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-silver-chalice-14px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-sherpa-blue-36px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-silver-chalice-19px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-bold);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-silver-chalice-22px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-silver-200px {
  color: var(--silver);
  font-family: var(--font-family-proximanova-bold);
  font-size: 200px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-regular-normal-quicksand-14px {
  color: var(--x19-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-carnation-pink-14px {
  color: var(--x25-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.arial-regular-normal-white-10px {
  color: var(--white);
  font-family: var(--font-family-arial-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-observatory-29px {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: 29px;
}
.proximanova-medium-observatory-29px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-observatory-29px-2 {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-bold);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-white-48px {
  color: var(--white);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-magic-potion-30px {
  color: var(--magic-potion);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-regular-normal-sonic-silver-36px {
  color: var(--sonic-silver);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}
.proximanova-medium-allports-16px {
  color: var(--allports);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-sherpa-blue-36px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-blue-violet-21px {
  color: var(--x35-2);
  font-family: var(--font-family-proximanova-medium);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-medium-silver-chalice-36px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-observatory-30px-2 {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxl);
}
.proximanova-normal-observatory-30px {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxl);
}
.proximanova-regular-normal-observatory-18px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-observatory-32px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.garamondpremierpro-semi-bold-magic-potion-60px {
  color: var(--magic-potion);
  font-family: var(--font-family-garamondpremier_pro-semibold);
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
}
.proximanova-medium-violet-eggplant-24px {
  color: var(--violet-eggplant);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-magic-potion-19px {
  color: var(--magic-potion);
  font-family: var(--font-family-proximanova-bold);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-proximanova-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
.proximanova-bold-white-65px {
  color: var(--white);
  font-family: var(--font-family-proximanova-bold);
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-medium-silver-chalice-18px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}
.proximanova-medium-black-16px {
  color: var(--black);
  font-family: var(--font-family-proximanova-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-brink-pink-14px {
  color: var(--x21-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.proximanova-bold-observatory-31px {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: 31px;
}
.proximanova-medium-observatory-31px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-bold-observatory-31px-2 {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-bold);
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
}
.proximanova-normal-observatory-32px {
  color: var(--observatory);
  font-family: var(--font-family-proxima_nova);
  font-size: 32px;
}
.proximanova-medium-observatory-32px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-medium);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.proximanova-regular-normal-observatory-19px {
  color: var(--observatory);
  font-family: var(--font-family-proximanova-regular);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-normal-sherpa-blue-36px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxxl);
}
.proximanova-light-white-36px {
  color: var(--white);
  font-family: var(--font-family-proximanova-light);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 300;
}
.proximanova-normal-star-dust-16px {
  color: var(--star-dust-2);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-m);
}
.proximanova-medium-silver-chalice-30px {
  color: var(--silver-chalice);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxl);
}
.proximanova-regular-normal-portica-15px {
  color: var(--x11-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-wisteria-15px {
  color: var(--x33-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-bright-yellow-15px {
  color: var(--x13-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-blue-violet-15px {
  color: var(--x35-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-blaze-orange-15px {
  color: var(--x15-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-polo-blue-15px {
  color: var(--x37-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-cornflower-lilac-15px {
  color: var(--x17-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-steel-blue-15px {
  color: var(--x39-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-quicksand-15px {
  color: var(--x19-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-absolute-zero-15px {
  color: var(--x41-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-brink-pink-15px {
  color: var(--x21-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-cerulean-15px {
  color: var(--x43-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-crimson-15px {
  color: var(--x23-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-bondi-blue-15px {
  color: var(--x45-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-carnation-pink-15px {
  color: var(--x25-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-tropical-rain-forest-15px {
  color: var(--x47-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-red-violet-15px {
  color: var(--x27-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-jade-15px {
  color: var(--x49-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-magenta-15px {
  color: var(--x29-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-sheen-green-15px {
  color: var(--x51-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-purple-plum-15px {
  color: var(--x31-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-regular-normal-peach-orange-15px {
  color: var(--x53-2);
  font-family: var(--font-family-proximanova-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.proximanova-medium-silver-chalice-22px-2 {
  color: var(--silver-chalice);
  font-family: var(--font-family-proxima_nova);
  font-size: 22px;
}
.proximanova-bold-sherpa-blue-48px-2 {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proxima_nova);
  font-size: var(--font-size-xxxxl);
}
.proximanova-regular-normal-sherpa-blue-48px {
  color: var(--sherpa-blue);
  font-family: var(--font-family-proximanova-regular);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}
.border-1px-x39-2 {
  border: 1px solid var(--x39-2);
}

@font-face {
  font-family: "ProximaNova-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("https://anima-uploads.s3.amazonaws.com/5b92aac3e833790009188215/.25136.otf") format("opentype");
}

* {
  box-sizing: border-box;
}
.container-center-horizontal {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}
.screen a {
  text-decoration: none;
  display: contents;
}
.smart-layers-pointers,
.smart-layers-pointers * {
  pointer-events: auto;
  visibility: visible;
}
