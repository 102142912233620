@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@font-face {
  font-family: "ProximaNova-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("https://anima-uploads.s3.amazonaws.com/5b92aac3e833790009188215/.25136.otf") format("opentype");
}

* {
  box-sizing: border-box;
}
.container-center-horizontal {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}
.screen a {
  text-decoration: none;
  display: contents;
}
.smart-layers-pointers,
.smart-layers-pointers * {
  pointer-events: auto;
  visibility: visible;
}